.pagination {
  height: $gutter-xxl;
  display: flex;
  align-items: center;
  &__rows-per-page {
    margin-left: auto;
    margin-right: $default-gutter;
    white-space: nowrap;
  }
  &__currently-showing {
    margin: 0 $gutter-lg;
  }
  &__navigation-arrow {
    @include clickable-icon-no-circle();

    &:last-child {
      margin: 0 $default-gutter;
    }
  }

  &--data-grid {
    border-left: $data-grid-border;
    border-bottom: $data-grid-border;
    border-right: $data-grid-border;
  }
}
