$warning-banner-height: 42px;
$warning-banner-background-color: #fffbeb;
$warning-banner-border-color: #ffcd00;
$warning-banner-icon-size: 18px;
$footer-height: 72px;
$footer-padding:8px;
$project-list-header-min-height:215px;
// This will allow us to use sass variable inside react component
:export {
    footerHeight : $footer-height
}
