.batch-panel-confirm-modal {
    &__master-and-count,&__verify-toggle,&__copy-annotation-toggle {
        display: flex;
        align-items: center;
        width: 100%;
    }
    &__heading {
        margin: 10px $gutter-md 2px $gutter-md;
        font-weight: 600;
    }
    &__count {
        color: $grey-5;
        white-space: nowrap;
    }
    &__non-empty{
        overflow-y: auto;
        max-height: 500px;
      }
    &__tickmark-single-checkboxes{
        display:flex
    }
    &__verify-toggle, &__copy-annotation-toggle{
        margin-bottom: $gutter-md ;

        &-heading {
            margin: 2px $gutter-md 2px $gutter-md;
            width: 200px;
            font-weight: 600;
        }
    }
    &__master-checkboxes{
        width: 230px !important;
    }
}