.batch-select-control {
  color: $black;
  position: static;
  z-index: $z-index-overlay;
  right: 0;
  top: 0;

  &__container {
    display: flex;
    align-items: center;
    border-radius: $border-radius-default;
    border: solid 1px $border-grey;
    background-color: $white;
    height: $gutter-ml-1;
    border-right: none;

    &--plus_minus_container {
      margin-right: $gutter-xxs;
    }

    &[disabled] {
      background-color: $disabled-button-background-grey;
    }
  }

  &__fixed-container {
    display: flex;
    align-items: center;
    width: fit-content;
    position: static;
    margin-left: 0px;
    cursor: pointer;

    &:focus,
    &:hover {
      background-color: $hover-grey;
    }

    &:active {
      background-color: $active-grey;
    }

    &[disabled] {
      background-color: $disabled-button-background-grey;
      cursor: not-allowed;

      &:hover {
        cursor: not-allowed;
      }

      & svg {
        fill: $disabled-buttton-text-grey;
      }

      & path {
        fill: $disabled-buttton-text-grey;
      }
    }
  }

  &__batch-select-button {
    background: none;
    border: none;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    outline: none;
    padding: $gutter-xxs;
    cursor: pointer;

    &[disabled] {
      &:hover {
        cursor: not-allowed;
      }
    }
  }
}
