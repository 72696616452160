.round-number-modal {
  display: flex;
  flex-direction: column;

  &__section {
    display: flex;
    justify-content: space-between;
  }
  &__subtitle {
    color: $black;
    font-size: $font-size-md;
    font-weight: $font-w-semi-bold;
  }
  &__subtitle-with-text {
    display: flex;
    flex-direction: column;
  }
  &__text {
    font-weight: $font-w-semi-bold;
    font-size: calc(#{$font-size-md} - 0.075rem);
    width: 31.25rem;
    margin-top: $gutter-xxs;
    color: $grey-4;
  }
  &__input {
    & input {
      border-color: $grey-3;

      &:focus,
      &:hover {
        border: $hover-focus-border;
      }
    }
    &--error {
      & input {
        border-color: $red-2;

        &:focus,
        &:hover {
          border: $hover-focus-border;
        }
      }
    }
  }
  &__current-number-value {
    color: $black-1;
    font-size: $font-size-ml;
    font-weight: $font-w-regular;
  }
  &__new-rounded-value {
    color: $black-1;
    font-size: $font-size-ml;
    font-weight: $font-w-semi-bold;
  }
  &__current-number {
    margin-bottom: $default-gutter;
  }
  &__new-rounded {
    border-top: 1px solid $border-grey;
    padding-top: $gutter-md;
  }
  &__change-digit {
    margin-bottom: $gutter-md;
  }
  &__change-digit-input {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
  }
  &__arrow {
    background-color: $grey-8;
    &--rotate {
      transform: rotate(180deg);
    }
  }
  &__buttons {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    margin-right: $gutter-xxs;
  }
  &__guidance-text-list {
    list-style-position: inside;
  }
}
