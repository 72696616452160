.carry-forward {
  display: flex;
  flex-wrap: nowrap;

  &__step {
    width: $progressBarStepWidth;
  }

  &__card {
    width: $progressBarCardWidth;
  }

  &__title {
    font-size: $font-size-xl;
  }

  &__banner {
    margin-top: $gutter-md;
  }

  &__annotations-copy {
    margin: $gutter-xs 0 $gutter-lg 0;
    font-size: $font-size-md;
  }

  &__drop-down {
    margin-top: $gutter-md;
  }

  &__statement-list {
    margin-top: $gutter-md;
  }

  &__selected-statement {
    margin-top: $gutter-md;

    &--row {
      height: 54px;
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      background-color: $hover-grey;
      padding: 0 $gutter-md 0 $gutter-md;
    }

    &--label {
      font-size: $font-size-sm;
      font-weight: $font-w-semi-bold;
      margin-right: $gutter-xxs;
    }

    &--name {
      font-size: $font-size-sm;
    }
  }

  &__remove-button {
    padding-left: $gutter-md;
  }

  &__info {
    margin-top: $gutter-md;
  }

  &__annotations-title {
    margin: $gutter-sm 0;
    font-weight: $font-w-bold;
    font-style: normal;
    font-size: $font-size-md;
    line-height: $line-height-6;
  }

  &__annotations-subtitle {
    font-style: normal;
    font-weight: normal;
    font-size: $font-size-md;
    line-height: $line-height-6;
    color: $grey-6;
  }

  &__workpaper-reference-checkbox,
  &__tickmarks-checkbox,
  &__formulas-checkbox,
  &__internalreferences-checkbox {
    margin: $gutter-sm 0;
  }

  &__autotieout-title,
  &__betaversion-title {
    margin: $gutter-sm 0;
    font-weight: $font-w-bold;
    font-style: normal;
    font-size: $font-size-md;
    line-height: $line-height-6;
  }

  &__autotieout-subtitle,
  &__betaversion-subtitle {
    font-style: normal;
    font-weight: normal;
    font-size: $font-size-md;
    line-height: $line-height-6;
    color: $grey-6;
  }

  &__autotieout-checkbox,
  &__betaversion-checkbox {
    margin: $gutter-sm 0;
  }

  &__warning-banner ul {
    list-style-type: disc;
    margin-left: 2rem;
  }
}