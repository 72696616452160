.statement-content-page {
  display: flex;
  flex-direction: column;
  height: calc(100vh - #{$disclosure-analytics-header-height});

  &__container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    background-color: $page-background-grey;
    flex: 1 1 auto;
    overflow: hidden;
  }

  &__left-panel {
    width: $side-panel-width;
    height: 100%;
    display: flex;
    &--full-mode {
      width: 0;
    }
    & .flyoutPanel.left {
      padding-left: $toolkit-width; // allows flyout to fit behind toolkit and maintain same dimensions as right side
    }
  }
  &__center-panel {
    background-color: $white;
    width: 50%;
    margin: 0 auto 0 auto;
    position: relative;
    &--full-mode {
      margin-left: $toolkit-width;
      width: 100%;
    }
    &--scroll-bar {
      overflow-x: scroll;
      overflow-y: scroll;
      @include scroll-bar();
    }
  }
  &__center-panel-banner {
    background-color: $read-only-banner-background-color;
    border-bottom: $read-only-banner-border-color 2px solid;
    font-size: $font-size-sm;
    padding: $gutter-sm $gutter-xl;
    position: sticky;
    top: 0;
    z-index: $z-index-overlay;
    display: flex;
    justify-content: space-between;
  }

  &__selection-mode-banner {
    background-color: $blue-4;
    border-bottom: $blue-border;
    font-size: $font-size-sm;
    position: sticky;
    top: 0;
    z-index: $z-index-sticky-table-header;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    left: 0;
  }

  &__selection-mode-banner-header {
    padding: $gutter-sm $gutter-xl;
    display: flex;
    flex-direction: row;
    column-gap: 150px;
  }

  &__selection-mode-banner-body {
    padding: 0px $default-gutter 15px 15px;
    display: flex;
    direction: column;
  }

  &__selection-mode-banner-icon {
    left: calc($gutter-xl / 2) - calc($selection-mode-banner-icon-size / 2);
    width: 18px;
    height: 18px;
    position: absolute;
  }

  &__selection-mode-exit-button {
    cursor: pointer;
    padding: 0px 10px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    text-transform: uppercase;
    color: #007cb0;
  }
  &__selection-mode-batch-update-info {
    margin-right: auto;
  }
  &__top-fix {
    top: 42px;
  }
  &__readonly-top-fix {
    top: 76px;
  }
  &__blackline-top-fix {
    top: 119px;
  }
  &__center-panel-banner-icon {
    left: calc($gutter-xl / 2) - calc($read-only-banner-icon-size / 2);
    position: absolute;
  }
  &__right-panel {
    width: $side-panel-width;
    height: 100%;
    &--full-mode {
      width: 0;
    }
  }

  &__close {
    @include close-button();
  }

  table {
    table-layout: auto !important;
    width: 100% !important;
  }

  hr {
    margin: revert;
  }
}

#statement-content-page-id__table-controls {
  align-items: center;
  background-color: $white;
  border: $blue-border;
  border-radius: 50%;
  display: none;
  justify-content: center;
  position: absolute;
  z-index: $z-index-tooltip;

  &:hover {
    background-color: $primary-blue;

    & svg {
      fill: $white;
    }
  }

  & svg {
    fill: $primary-blue;
  }
}

table[data-fixed-width='false'] {
  width: 100% !important;
}

table[data-fixed-width='true'] {
  width: inherit !important;
}
