.statement-wrap-up-approval-modal-2 {
  &__warning-list {
    list-style: inside;
  }

  &__instructions {
    font-size: $font-size-md;
    margin-bottom: $gutter-md;
    font-weight: $font-w-semi-bold;
  }

  &__row {
    display: flex;
    align-items: center;
    font-size: $font-size-md;
  }

  &__sign-details {
    margin-left: $gutter-md;
  }
}