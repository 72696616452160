.tabs-group {
  display: flex;
  height: 100%;
  &__tab {
    background-color: $white;
    display: block;
    height: 100%;
    border: none;
    padding: $gutter-sm;
    font-size: $font-size-default;
    font-weight: $font-w-bold;
    cursor: pointer;
    outline: none;

    &:hover,
    &:focus {
      background-color: $hover-grey;
    }
    &:active {
      background-color: $active-grey;
    }

    &--active {
      color: $primary-blue;
      padding-bottom: $gutter-sm - $tab-active-border-size;
      border-bottom: $tab-active-border-size solid $primary-blue;
    }
    &--full-width {
      flex: 1;
    }
    &__right-icon{
      display: flex;
    }
  }
  &__right-icon-container{
    position: relative;
    top:-25%;
  }
}
