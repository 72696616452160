.summary-display-options-dropdown {
  position: relative;
  z-index: $z-index-statement-summary-display-options;
  margin-right: 6px;

  &__kebab {
    border-radius: 50%;
    vertical-align: bottom;
    padding: $gutter-xxs;
    cursor: pointer;

    &:focus,
    &:hover {
      background-color: $hover-blue;

      .kebab-icon {
        fill: $white;
      }
    }

    &:active {
      background-color: $active-blue;

      .kebab-icon {
        fill: $white;
      }
    }

    &[disabled] {
      cursor: not-allowed;
      background-color: transparent;

      .kebab-icon {
        fill: $disabled-text;
      }
    }
  }

  &__menu {
    height: auto;
    max-height: calc(100vh - 160px);
    overflow: auto;
    padding: $gutter-md;
    border-radius: $border-radius-default;
    box-shadow: 0 1px 4px 0 $border-grey;
    outline: solid 1px $border-grey;
    background-color: $white;
    z-index: $z-index-dropdown;
    position: absolute;
    left: 0;
    font-size: $font-size-md;
    color: $black;
    width: $statement-summary-display-options-width;
    margin-top: $gutter-xs;
  }

  &__menu-options {
    display: flex;
    justify-content: space-between;
    margin-top: 8px;
    padding-top: 8px;
    border-top: 1px solid #e0e0e0;
  }

  &__menu-options-2 {
    margin-top: $gutter-xs;
    padding-top: $gutter-xs;
    border-top: 1px solid $grey-2;
  }

  &__label {
    margin: 0 0 $gutter-xs $gutter-xs;
    color: $grey-4;
  }

  &__category-label {
    margin: $gutter-xs;
    font-weight: $font-w-semi-bold;
  }

  &__header {
    font-weight: $font-w-semi-bold;
    margin-bottom: $gutter-md;
  }

  .checkbox--checked {
    border: none;
  }
}