.tooltip {
  font-family: $font-family-default;

  // a global max width should prevent most tooltips from overflowing the page regardless of their position
  // specifically problematic tooltips should be handled on a case by case basis
  max-width: $tooltip-max-width;

  &--break-word {
    word-break: break-all;
  }
}

.__react_component_tooltip {
  z-index: $z-index-modal + 1 !important; //the z-index of the tooltip needs to be greater than the modal z-index.
}

.__react_component_tooltip.show {
  opacity: 1;
  background-color: $black;
}

.__react_component_tooltip.type-dark {
  background-color: $black;
}

.__react_component_tooltip.type-dark.place-top:after {
  border-top-color: $black;
}

.__react_component_tooltip.type-dark.place-bottom:after {
  border-bottom-color: $black;
}

.__react_component_tooltip.type-dark.place-left:after {
  border-left-color: $black;
}

.__react_component_tooltip.type-dark.place-right:after {
  border-right-color: $black;
}

.__react_component_tooltip.type-dark.place-top:before {
  height: 0.4rem;
}
