@mixin message($border-radius, $border-color, $background-color) {
  border-radius: $border-radius;
  border: solid 1px $border-color;
  background-color: $background-color;
  padding: $gutter-md;
  color: $black;
}

@mixin banner($border-radius, $border-bottom-color, $background-color) {
  border-radius: $border-radius;
  border-bottom: solid 1px $border-bottom-color;
  background-color: $background-color;
  padding: $gutter-md;
  color: $black;

  &--no-border {
    border: none;
  }
}
