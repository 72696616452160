.summary-of-key-changes{
    border-radius: $border-radius-default;
    border-top: $data-grid-border;
    &-button-wrapper{
        margin: $gutter-md $gutter-md $gutter-md 0px;
    }
    &__text--disabled{
        pointer-events: none;
 }
 &__rich-text--disabled{
     cursor: not-allowed;
         opacity: 0.4;
 }
 &-switch{
    padding: $gutter-md;
    border-right: $data-grid-border;
    border-left: $data-grid-border;
}
&-switch-disabled{
    padding: $gutter-md;
    border-right: $data-grid-border;
    border-left: $data-grid-border;
    cursor:not-allowed;
    opacity: 0.4;
}
&-switch-banner{
    margin-right : $gutter-md;
}
}