.note-anchor {
  &__content {
    font-family: inherit;
  }

  &__closed-priority {
    background-color: #757575;
    border-radius: 0px 5px 5px 0px;
  }

  &--selected {
    background-color: $blue-8;
  }

  &--cursor-alias {
    cursor: alias;
  }

  &--disabled {
    cursor: not-allowed;
  }

  &__icon {
    display: inline-flex;
    vertical-align: bottom;
  }

  &__icon:hover {
    cursor: pointer;
  }
}

.pseudo-element {
  &.highlight {
    background-color: $grey-2;
  }

  &.panel-selected {
    background-color: $yellow-3;
  }

  &.hover-highlight {
    background-color: $yellow;
  }
}

.created-note {
  background-color: $green-6 !important;
}