.left-statement-panel {
    &__statement-header-wrap{
        height: 48px;
        width: 100%;
        background-color: $white;
        box-shadow: 0 0 4px 0 $border-grey;
        border-bottom: $expandable-row-border;
        font-weight: $font-w-semi-bold;
        color: #212121;
        font-size: $font-size-lg;   
        display: flex;
        align-items: center;
    }

    &__statement-header{
      display: flex;
      width: calc(100% - 75px);
      align-items: center;
      &__title{
        @include overflow-ellipsis;
      }
      &__swap{
        &__statement-nav-button{
          margin-left: calc($gutter-xxl/2);
        }
        width: calc(100% - 45px);
      }
    }

    &__statement-nav-button {
      @include remove-default-button-styling;
      width: $toolkit-width;
      cursor: pointer;
      display: flex;
      
      justify-content: center;
      align-items: center;
      margin-right: $gutter-xs;
      height: $gutter-xl;
  
       &:hover,
       &:active,
      &--selected {
        svg path {
          fill: $white;
        }
      }
  
      &:hover {
        background-color: $hover-blue;
      }
  
      &:active {
        background: $active-blue;
      }
  
      &--selected {
        background-color: $primary-blue;
      }
    }

    &__nav-icon{
      vertical-align: unset;
      path {
        fill: $primary-blue;
      } 
    }

    &__cancelIcon {
        position: relative;
        top: 36px;
        left: 10px;
    }

    &__cancelBtn {
      @include close-button();
    cursor: pointer;
    background: none;
    border: none;
    outline: none;
    position: absolute;
    bottom: 21px;  
    }

    &__container{
        width: 100%;
        padding: 7px 0 0 7px;
        position: relative;
        &__left{
          background-color: #f7f7f7;
          z-index: $z-index-overlay + 1;
        }
    }

    &__data{
      display: flex;
      flex-direction: column;
    }

    &__left-page-data{
      display: flex;
      flex-wrap: nowrap;
      height: calc(100vh - 247px);
      min-height: calc(100vh - 247px);
    }

    table {
        table-layout: auto !important;
        width: 100% !important;
    }

    hr{
        margin: revert;
    }

    &__content-section-ocr{
      overflow: hidden !important;
    }
    
    &__content-section{
        background-color: $white;
        width: 100%;
        overflow-x: scroll;
        overflow-y: scroll;
        position: relative;
        @include scroll-bar();
    }
  }

 