@mixin side-by-side-view-selected-element() {
    .selected-element-for-side-by-side-view{
      display: inline-block;
      position: relative;
      padding: 5px;
      background-color: #A3CEE0;
      border: 1px solid $side-by-side-element-border;
      border-radius: 5px;
      text-indent: 0%;
      cursor: alias;
      &__count {
        position: absolute;
        border-radius: 50%;
        background-color: $side-by-side-identifier;
        color: $white;
        text-align: center;
        max-width: max-content;
        cursor: alias;
        top: -10px;
        right: -12px;
        line-height: 14px;
        font-size: 7px;
        min-width: 15px;
        width: fit-content;            
      }
    }
  }
  