.section-anchor {
  @include hyperlink-button;
  font-weight: inherit;
  font-style: inherit;
  display: flex;

  &:hover {
    background-color: $hover-grey;
  }

  &--selected {
    background-color: $grey-2;

    & .content-search-highlight {
      background-color: $yellow;
    }
  }

  &--created-nav {
    background-color: $green-6;
  }

  &--panel-selected {
    background-color: $yellow-3;
  }

  &--cursor-alias {
    cursor: alias;
  }

  &--disabled {
    cursor: not-allowed;
  }

  &__icon {
    margin-right: $gutter-xxs;
    margin-top: $gutter-xxxs;
  }

  &__section-reviewers {
    display: inline;
    margin-left: $gutter-xxxs;
    white-space: nowrap;
  }

  &__section-assignments {
    display: inline;
    white-space: nowrap;
    margin-right: $gutter-xs;
  }

  &__assigned-reviewer {
    font-weight: $font-w-semi-bold;
    font-size: $gutter-md;
    color: $grey-4;
    margin-left: $gutter-xxs;
    display: inline-block;

    &--with-comma-prefix:first-of-type::before {
      content: ', ';
      margin-left: -$gutter-xxs;
    }
  }

  &__assigned-reviewer::after {
    content: ',';
  }

  &__assigned-reviewer:last-of-type::after {
    content: '';
  }

  &__reviewer {
    font-weight: $font-w-semi-bold;
    font-size: $gutter-md;
    color: $green-4;
    margin-left: $gutter-xxs;
    display: inline-block;
  }

  &__reviewer::after {
    content: ',';
  }

  &__reviewer:last-of-type::after {
    content: '';
  }
}

td .section-anchor {
  white-space: nowrap;
}

.statement-content-panel--add-element-cursor {
  & .section-anchor {
    cursor: $add-element-cursor;
  }
}

.statement-content-panel--edit-section {
  &__edit-section {
    display: 'inlineBlock';
    left: 0;
  }

  &__on-select {
    display: 'inlineBlock';
    position: 'absolute';
    right: 5;
  }

  &__delete-section {
    @include hyperlink-button;
    font-weight: bold;
    padding-top: 15px;
  }

  &__entity-list {
    display: table;
    color: #757575;
    padding-bottom: 8px;
    font-weight: 700;
    font-size: 12px;
    padding-top: 15px;
  }

  &__pencil-icon {
    width: 20px;
    height: 20px;
    margin-right: 25px;
    margin-bottom: -5px;
  }
}