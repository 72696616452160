.select-statement {
  display: flex;
  flex-wrap: nowrap;

  &__step {
    width: $progressBarStepWidth;
  }

  &__card {
    width: $progressBarCardWidth;
  }

  &__title {
    font-size: $font-size-xl;
  }

  &__banner {
    margin-top: $gutter-ml;

    &--error {
      margin-top: $gutter-md;
    }
  }

  &__upload {
    width: 60%;
    border-radius: $border-radius-default;
    border: dashed 1px $border-grey;
    margin-top: $gutter-md;
    padding: $gutter-xxl $gutter-xl;
    display: flex;
    justify-content: center;

    &__full-width{
      width: 100%;
    }

    &--active {
      border: $hover-focus-border;
      background-color: $drag-drop-blue-overlay;
      padding: $gutter-ml - 1px 0 $gutter-ml - 1px 0;
    }
    &--rejected {
      border: dashed 1px $error-red;
      background-color: $white;
    }
  }
  &__upload-section {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
  }

  &__esc-information-section {
    width: 40%;
    background-color: $grey-8;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: $border-radius-default;
    margin: $gutter-md 0 0 $gutter-md;
    padding: $gutter-xxl $gutter-xl;
  }

  &__drop-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  &__button {
    margin-top: $gutter-md;
    display: flex;
    justify-content: center;
  }

  &__subtitle {
    font-weight: $font-w-semi-bold;
    margin-top: $gutter-md;
  }

  &__text {
    font-size: $font-size-md;
    margin-top: $gutter-xs;
    text-align: center;
  }

  &__accepted-files-list {
    margin: $gutter-xs 0 $gutter-xs 0;
  }

  &__accepted-files-row {
    padding: $gutter-xs 0 $gutter-xs 0;
    display: flex;
    flex-wrap: nowrap;
  }

  &__accepted-file-name {
    color: $green-2;
    font-size: $font-size-md;
    font-weight: $font-w-semi-bold;
    margin-right: $gutter-xs;
    &--error {
      color: $error-red;
    }
  }

  &__clear-icon {
    border-radius: 50%;
    padding: $statement-creation-clear-icon-padding;
    .icon-path {
      fill: $primary-blue;
    }
    &:focus,
    &:hover {
      background-color: $hover-blue;
      .icon-path-inner {
        fill: $white;
      }
    }
    &:active {
      background-color: $active-blue;
      .icon-path-inner {
        fill: $white;
      }
    }
  }

  &__beta-statement-guide-hyperlink {
    @include hyperlink-button;
  }

  &__evaluation-statement-guide-hyperlink {
    @include hyperlink-button;
    text-decoration: none;
  }
}
