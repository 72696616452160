.statement-nav {
  & .panelHeader {
    padding: $flyout-header-padding 0 0 0;
  }

  & .panelContent {
    overflow: auto;
  }

  &__title {
    font-size: $font-size-xl;
    font-weight: $font-w-semi-bold;
    margin-left: $flyout-header-padding;
    margin-bottom: $gutter-md;
  }

  &__search {
    padding: 0 $flyout-header-padding $gutter-xs $flyout-header-padding;
  }

  &__row {
    display: flex;
    width: 100%;
    padding: 0 $gutter-xs;

    @include breakpoint(desktop) {
      padding: 0 $gutter-md;
    }

    padding-bottom: 0;

    &--with-top-border {
      border-top: $flyout-border;
    }
  }

  &__search-count {
    font-size: $font-size-sm;
    font-weight: $font-w-semi-bold;
    display: flex;
    align-items: center;

    @include breakpoint(desktop) {
      font-size: $font-size-sm;
    }
  }

  &__search-navigation {
    align-items: center;
    display: flex;
    flex: 1;
    padding-left: $gutter-xxs;

    & .icon-button {
      border-radius: 50%;
      padding: $gutter-xxs;

      &:hover {
        background-color: $grey-1;
      }
    }
  }

  &__filter-button {
    font-size: $font-size-md !important;
    padding: $gutter-xxs;
    white-space: nowrap;

    & svg {
      width: $statement-nav-filter-icon-size;
      height: $statement-nav-filter-icon-size;
    }
  }

  &__filter-button-container {
    padding: $gutter-xs 0;
  }

  &__chip-container {
    @include scroll-bar;
    overflow-y: auto;
    margin: 0 $gutter-xs $gutter-xs $gutter-xs;
    max-height: $statement-nav-chips-container-max-height;

    @include breakpoint(desktop) {
      margin: 0 $gutter-md $gutter-xs $gutter-md;
    }

    & .chip {
      display: inline-flex;
      font-size: $font-size-sm;
      margin-right: $gutter-xxs;
      margin-top: $gutter-xxs;

      @include breakpoint(desktop) {
        font-size: $font-size-md;
      }
    }
  }

  &__tabs {
    height: $statement-nav-tabs-height-tablet;

    @include breakpoint(desktop) {
      height: $statement-nav-tabs-height-desktop;
    }

    & .tabs-group__tab {
      font-size: $font-size-sm;
      width: 25%;
      padding-right: $gutter-xxs;
      padding-left: $gutter-xxs;
    }
  }
  &--create {
    z-index: $z-index-modal-overload;
    width: calc(25vw - 54px);
    //this a class being added directly to a ref, it need !important to overwrite current styles that comes from other css files
    left: 3rem!important;
    padding-left: 0!important;
  }
}
