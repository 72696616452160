.notes-advanced-filter-modal {
  width: $modal-max-width;

  &__default-text {
    font-size: $font-size-md;
    margin-bottom: $gutter-md;
  }

  &__filters-container {
    width: 100%;
    height: $statement-nav-modal-element-filter-height; // make variable

    display: flex;

    border: $border-gray;

    & .form-item {
      font-size: $font-size-md;
    }
  }

  &__section-title {
    font-size: $font-size-md;
    font-weight: $font-w-semi-bold;
    margin: $gutter-sm $statement-nav-filter-left-spacing;
    &:first-of-type {
      margin-top: 0px;
    }
  }

  &__col-1,
  &__col-2,
  &__col-3 {
    padding: $gutter-sm 0;
    overflow: auto;
  }

  &__col-1 {
    width: 30%;
    border-right: $border-gray;
  }
  &__col-2 {
    border-right: $border-gray;
    width: 37%;
  }
  &__col-3 {
    width: 33%;
  }

  &__filter-category {
    @include remove-default-button-styling;
    cursor: pointer;
    width: 100%;
    padding: $gutter-xxs $statement-nav-filter-left-spacing;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &:focus {
      background-color: $active-grey;
    }

    &:hover {
      cursor: pointer;
      text-decoration: underline;
      background-color: $hover-grey;
    }

    &:active {
      background-color: $active-grey;
    }

    & span {
      display: inline-block;
    }

    &--selected {
      background-color: $grey-2;
    }
  }

  &__chips-container {
    margin-bottom: $gutter-md;
    & .chip {
      display: inline-flex;
      margin-right: $gutter-xxs;
      margin-top: $gutter-xxs;
    }
  }

  &__chevron {
    fill: $black;
    transform: rotate(-90deg);
  }

  &__radio {
    display: flex;
    flex-direction: column;
    margin-left: $statement-nav-filter-left-spacing;
    & .radio {
      margin-bottom: $gutter-xxs;
      width: fit-content;
    }
  }

  &__checkbox-group {
    display: flex;
    flex-direction: column;
    margin-left: $statement-nav-filter-left-spacing;
    & .checkbox {
      margin-bottom: $gutter-xxs;
    }
  }

  &__statement-segment {
    margin: $gutter-md;
    & .checkbox {
      margin-bottom: $gutter-xxs;
    }
  }

  &__statement-segment-search {
    margin-bottom: $gutter-md;
  }
}
