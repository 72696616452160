.element-details { 
  @include comfort-assign-element();
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: $gutter-sm;
  &:hover {
    background-color: $hover-grey;
    cursor: pointer;
  }
  &__active {
    background-color: $active-grey;
  }
  &__element-details-wrapper {
    overflow: hidden;
  }
  &__section-name {
    display: block;
    font-size: $font-size-sm;
    color: $grey-5;
    @include overflow-ellipsis;
    &--active {
      white-space: normal;
    }
  }
  &__label {
    display: block;
    font-size: $font-size-md;
    color: $black;
    @include overflow-ellipsis;
  }
  &__element-value {
    @include hyperlink-button-for-element;    
    margin-right: $gutter-xxs;
    font-size: $font-size-md;
    font-weight: $font-w-semi-bold;
    display: flex;
    text-align: left;
    outline: none;

    &--selected {
      background-color: $blue-8;
    }
  }
  &__annotations {
    font-size: $font-size-sm;
    color: $black;
    @include overflow-ellipsis;
    &--active {
      white-space: normal;
      overflow: visible;
    }
  }
  & .element-annotation-internal-reference-group,
  & .element-annotation-workpaper-reference,
  & .element-annotation-tickmark,
  & .element-annotation-formula {
    padding: 0 $gutter-xxxs;
    
  }
  & .element-annotation-internal-reference-group{
    color:$blue-11;
  }
  & .element-annotation-internal-reference-group {
    &--flagged {
      color: $orange;
    }
  }
  & .element-annotation-formula {
    color:#046A38;
  }

  & .element-annotation-formula {
    &--flagged {
      color: $orange;
    }
  }
  
  & .element-annotation-tickmark{
  color: #62B5E5;
  }

  & .element-annotation-comfort-letter-label{
    color: red;
  }

  

  & .element-annotation-formula {
    &--flagged {
      color: $orange;
    }
  }
  

  & .element-annotation-workpaper-reference {
    display: inline-block;
    color:#DA291C;
  }

  &__element-value-wrapper {
    display: inline-grid;
    grid-template-columns: auto auto auto;

    & svg {
      width: $gutter-md;
      margin-left: $gutter-xxs;
    }
  }

  &__clear-icon {
    border-radius: 50%;
    padding: $gutter-xxs;
    cursor: pointer;
    .icon-path {
      fill: $primary-blue;
    }
    &:focus,
    &:hover {
      background-color: $primary-blue;
      .icon-path-inner {
        fill: $white;
      }
    }
    &:active {
      background-color: $active-blue;
      .icon-path-inner {
        fill: $white;
      }
    }
  }
}
