.project-edit-section-menu {
  display: flex;
  flex-direction: column;
  margin-right: $gutter-xs;
  border-radius: $border-radius-default;
  border: $search-border-grey;
  background-color: $white;

  &__section {
    padding: $gutter-md;
    border: none;
    background-color: transparent;
    text-align: left;
    font-weight: $font-w-regular;
    font-size: $font-size-default;
    outline: none;

    &:hover {
      background-color: $hover-grey;
    }

    &--active {
      background-color: $transparent-blue;
      color: $blue-1;
      font-weight: $font-w-semi-bold;
    }
  }
}
