@mixin close-button() {
  margin-left: auto;
  border-radius: 50%;
  border: none;

  &:focus,
  &:hover {
    cursor: pointer;
    background-color: $hover-grey;
  }

  &:active {
    cursor: pointer;
    background-color: $active-grey;
  }
}
