.form-item {
  width: 100%;
  &--invalid {
    .form-item__label {
      color: $error-red;
    }
  }

  &__container{
    display: flex;
  }

  &__label {
    //dispay is table so the width fits content and tool tip stays centered.
    display: table;
    color: $label-grey;
    padding-bottom: $gutter-xs;
    font-weight: $font-w-bold;
    font-size: $gutter-sm;
    padding-right: 10px;
  }

  &__error-text {
    color: $error-red;
    font-size: $gutter-sm;
  }
}
