@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  src: url('../fonts/OpenSans-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 300;
  src: url('../fonts/OpenSans-LightItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/OpenSans-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  src: url('../fonts/OpenSans-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 600;
  src: url('../fonts/OpenSans-SemiBoldItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 900;
  src: url('../fonts/OpenSans-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 900;
  src: url('../fonts/OpenSans-BoldItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 400;
  src: url('../fonts/OpenSans-Italic.ttf') format('truetype');
}
