.statement-summary {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
    .loading{
      .lds-ellipsis{
        height: 43px;
        div{
          top: 16px;
        }
      }
    }
}