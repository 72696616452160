.copy-formula-banner {
        background-color: $information-background-blue ;
        border-bottom: $information-border-blue 2px solid;
        font-size: $font-size-sm;
        padding: $gutter-sm $gutter-xl;
        position: sticky;
        top: 0;
        z-index: $z-index-sticky-table-header;
        display: flex;
        justify-content: space-between;

        &__info_icon{
            left: calc($gutter-xl / 2) - calc($read-only-banner-icon-size / 2);
            position: absolute;
          }
          &__formula-paste-button {
            margin: 5px -25px 5px 5px;
         }
         &__formula-cancel-button {
            margin: 5px;
         }
}