.left-side-view-select-statement{
    &_radio{
        width: max-content;
        display: block;
    }
    &_radio_wrap-statement-list{
        overflow-x: auto;
        overflow-y: auto;
        max-height: 300px;  
        margin-top: $gutter-md;
        @include scroll-bar();
    }
    &__project_list-dropdown{
        overflow-x: auto;
        overflow-y: auto;
        max-height: 300px;
        @include scroll-bar();
    }
    &__no-data-msg{
        padding: 20px 0;
        color: #A3A3A3;
    }
}