.statement-copy-modal {
  &__field {
    flex: 1;
    margin-bottom: $gutter-md;
  }
  &__label {
    color: $label-grey;
    padding-bottom: $gutter-xs;
    font-weight: $font-w-bold;
    font-size: $gutter-sm;
  }
  &__value {
    font-size: $font-size-md;
  }
  &__row {
    display: flex;
    flex-direction: row;
  }
  .dropdown__header {
    &[disabled] {
      .dropdown__title--selected {
        color: $grey-4;
      }
    }
  }
  .menu {
    max-height: $copy-statement-dropdown-menu-height !important;
  }
}
