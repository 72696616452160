.statement-creation {
  background-color: $page-background-grey;
  padding: $gutter-ml;
  width: 100%;

  &__back-button {
    @include back-button();
    padding: 0;
  }

  &__header {
    font-size: $font-size-xl;
    padding-left: $default-gutter;
  }

  &__back-title {
    font-size: $font-size-md;
    color: $primary-blue;
  }

  &__page-title {
    font-size: $font-size-xl;
    margin-top: $gutter-xs;
  }

  &__body {
    margin-top: $gutter-ml;
    width: 100%;
  }

  &__progress-bar {
    width: 20%;
  }

  &__select-statement {
    width: 100%;
    padding-bottom: $gutter-md;
  }

  &__statement-info {
    width: 100%;
    padding-bottom: $gutter-md;
  }

  &__carryforward {
    width: 100%;
  }

  &__button-container {
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-end;
    margin-top: $gutter-ml;
  }

  &__cancel-button {
    padding-left: $default-gutter;
    padding-right: $default-gutter;
    margin-right: $gutter-md;
  }
}
