.statement-thumbnail{
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(40%,1fr));
    gap: 0%;
    height: inherit;
    overflow: auto;
    @include scroll-bar();

    &__individual{
        display: inline-grid;
        padding:.5em;
        box-sizing: border-box;
        margin: 5%;
        height: 50%;
    }

    &--individual--image{
        display: flex;
        justify-content: center;        
    }

    &__individual--active{
        border: 1.5px solid $primary-blue;
    }

    &__individual--image{
        box-shadow: .2px .2px 3px .2px;
        border-radius: 2px 2px;
        &:hover {
            border: 1.5px solid #ebebeb;
        }
    }

    &__individual--caption{
        text-align: center;
        font-size: 12px;
        margin-top: 4px;
    }
}