.date-time-picker-wrap{
    display: flex;
    width: fit-content;
}
.SingleDatePickerInput__withBorder{
    margin-right: $gutter-sm;
}
.SingleDatePickerInput__disabled {
    background-color: #f2f2f2;
}
.CalendarMonth_1{
    z-index: 6;
}
.react-time-picker__clock {
    z-index: 6 !important; 
}

.react-time-picker{
    &__clock-button{
        pointer-events: none;
    }
}