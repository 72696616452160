.manage-users {
  display: flex;
  flex-wrap: nowrap;

  &__step {
    width: $progressBarStepWidth;
  }
  &__card {
    width: $progressBarCardWidth;
  }

  &__title {
    margin-bottom: $gutter-xs;
  }

  &__info {
    font-size: $font-size-md;
    display: flex;
    align-items: center;
  }

  &__search {
    margin-top: $gutter-md;
    width: 75%;
  }

  &__banner {
    margin-top: $gutter-md;
  }

  &__added-users {
    margin-top: $gutter-md;
    .data-grid__scrollable-wrapper {
      overflow: visible;
      overflow-y: visible;
    }
  }

  &__checkbox {
    margin-top: $gutter-ml;
  }

  &__disclaimer-button {
    @include hyperlink-button;
    font-weight: $font-w-semi-bold;
    margin-left: $gutter-ml + 3;
  }
  &__learn-more-button {
    @include hyperlink-button;
    font-weight: $font-w-semi-bold;
    padding: $gutter-sm $gutter-xxs;
  }
}
