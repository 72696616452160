.section-history-element {
  font-size: $font-size-sm;
  border: $border-gray-light;
  margin-bottom: $gutter-sm;
  padding: $gutter-sm;

  &__first-line {
    font-weight: $font-w-semi-bold;
    margin-bottom: $gutter-xxs;
  }
}
