.statement-summary-annotations {
  display: flex;
  justify-content: center;
  height: calc($gutter-ml + 1px);

  &__element {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    padding: 5px;
    font-weight: 500;
    cursor: pointer;
    min-width: 35px;

    &--icon {
      border: none;
      padding: 0px;
      min-width: 0px;
    }

    &--annotation-icon {
      padding: 1px;

      &--enabled {
        background-color: $blue-9;
      }

      &--disabled {
        background-color: $disabled-button-background-grey;

        svg path {
          stroke: $disabled-text;
        }

        &:hover {
          background-color: $disabled-button-background-grey;
          cursor: not-allowed;

          svg path {
            fill: $disabled-text;
          }
        }
      }
    }
  }

  &__element-selected {
    background-color: $blue-9;
    color: #ffffff;

    &--disabled {
      color: $disabled-text;
      background-color: $disabled-button-background-grey;

      &:hover {
        background-color: $disabled-button-background-grey;
        cursor: not-allowed;
      }
    }
  }

  &__element-unselected {
    background-color: #ffffff;
    color: #000000;

    &--disabled {
      color: $disabled-text;
      background-color: $disabled-button-background-grey;

      &:hover {
        background-color: $disabled-button-background-grey;
        cursor: not-allowed;
      }
    }
  }

  &__item-count {
    display: flex;
    width: fit-content;
    height: fit-content;
    padding: 2px 3px 2px 3px;
    align-items: center;
    justify-content: center;
    position: relative;
    top: -4px;
    right: -3px;
    border-radius: 100px;
    text-align: center;
    font-size: 8px;
    font-weight: 700;
  }

  &__item-count-selected {
    background-color: #ffffff;
    color: $blue-9;

    &--disabled {
      color: $disabled-text;
      background-color: $disabled-button-background-grey;

      &:hover {
        background-color: $disabled-button-background-grey;
        cursor: not-allowed;
      }
    }
  }

  &__item-count-unselected {
    background-color: $blue-9;
    color: #ffffff;

    &--disabled {
      color: $disabled-text;
      background-color: $disabled-button-background-grey;

      &:hover {
        background-color: $disabled-button-background-grey;
        cursor: not-allowed;
      }
    }
  }

  &__element-annotation-icon{
    margin-top: 2px;
  }
}