.omnia-engagement-list {
  margin-top: $gutter-xxl;

  &__project-name {
    @include hyperlink-button;
    margin-right: $gutter-md;
  }

  &__engagement-name {
    font-weight: $font-w-semi-bold;
  }

  &__om-engagement-name {
    font-weight: $font-w-semi-bold;
  }

  &__not-linked {
    color: $grey-5;
  }

  &__list {
    .data-grid__header-col {
      font-size: $font-size-md;
      font-weight: $font-w-semi-bold;
      color: $secondary-label-grey;
    }

    .data-grid__header-col {
      font-size: $font-size-md;
      font-weight: $font-w-semi-bold;
      color: $secondary-label-grey;
      &:last-child {
        padding: 0 $gutter-md;
      }
    }

    .data-grid__col > .button {
      font-size: $font-size-md;
    }
  }
}
.button__icon {
  font-size: $gutter-md;
}
