@mixin back-button() {
  .button__icon__icon {
    border-radius: 50%;
    cursor: pointer;
    border: solid 1px $primary-blue;
  }

  &:focus,
  &:hover {
    background-color: transparent;
    .button__icon__icon {
      background-color: $hover-blue;
      border: solid 1px $hover-blue;
      .back-icon {
        fill: $white;
        stroke: $white;
      }
    }
  }

  &:active {
    .button__icon__icon {
      background-color: $active-blue;
      border: solid 1px $active-blue;
      .back-icon {
        fill: $white;
        stroke: $white;
      }
    }
  }
}
