.report-preview-page-options-dropdown {
    position: relative;
    z-index: $z-index-statement-summary-display-options;
    margin-right: 0.375rem;

    &__line-break {
        border-top: 1px solid #e0e0e0;
        position: relative;
        margin: 10px 0px;
        width: 100%
    }

    &__remove-icon {
        cursor: pointer;
    }

    &__button-container {
        width: 120px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        &--item {
            background-color: $grey-0;
            width: 5.625rem;
            cursor: pointer;

            &__reverse {
                margin: 0 1rem 0 0;
            }
        }

        &--item:hover {
            background-color: $grey-2;
        }

        &--text {
            font-size: $font-size-md;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0.25rem;
        }
    }



    &__kebab {
        border-radius: 50%;
        vertical-align: bottom;
        padding: $gutter-xxs;
        cursor: pointer;

        &:focus,
        &:hover {
            background-color: $hover-blue;

            .kebab-icon {
                fill: $white;
            }
        }

        &:active {
            background-color: $active-blue;

            .kebab-icon {
                fill: $white;
            }
        }

        &[disabled] {
            cursor: not-allowed;
            background-color: transparent;

            .kebab-icon {
                fill: $disabled-text;
            }
        }
    }

    &__menu {
        font-size: $font-size-md;
        color: $black;
    }

    &__menu-options {
        margin-top: 8px;
        padding: 8px;
    }

    &__menu-options-2 {
        margin-top: $gutter-xs;
        padding-top: $gutter-xs;
        border-top: 1px solid $grey-2;
    }

    &__label {
        margin: 0 0 $gutter-xs $gutter-xs;
        color: $grey-4;
    }

    &__category-label {
        margin: $gutter-xs;
        font-weight: $font-w-semi-bold;
    }

    &__header {
        font-weight: $font-w-semi-bold;
        margin-bottom: $gutter-md;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .checkbox--checked {
        border: none;
    }
}