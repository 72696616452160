@mixin breakpoint($point) {
  @if map-has-key($map: $breakpoints, $key: $point){
    $width: "min-width";
    @if ($point == mobile) {
      $width: "max-width"
    }
    @media ($width: map-get($breakpoints, $point)) {
      @content;
    }
  } 
}