.left-side-select-revision{
    &__no-data-msg{
        padding: 20px 0;
        color: #A3A3A3;
    }
    &__radio_wrap{
        padding: 15px 0;
    }
    &__radio{
        border: none;
        &:hover{
            border: none;
        }
    }
}