.batch-selected-elements {
  display: flex;
  align-items: center;
  top: 0;
  position: sticky;
  background: $white;

  font-weight: $font-w-semi-bold;
  margin: 0 $batch-panel-left-spacing;

  &__caret-icon {
    transform: rotate(90deg);
    &--expand {
      transform: rotate(180deg);
    }
  }

  &__element-list {
    margin: 0 $batch-panel-left-spacing;
  }
  &__pagination-button-container {
    display: flex;
    margin: 0 $gutter-md;
  }

  &__pagination-button {
    width: 90px;
    height: 28px;
    border-radius: $border-radius-default;
    font-weight: $font-w-semi-bold;
    outline: none;
    cursor: pointer;
    border: none;

    &--next {
      margin-bottom: $gutter-sm;
      background-color: transparent;
      color: $primary-blue;
      border: none;
      &:focus,
      &:hover {
        background-color: $hover-grey;
      }
      &:active {
        background-color: $active-grey;
      }
      &[disabled] {
        background-color: $disabled-button-background-grey;
        color: $disabled-buttton-text-grey;
        cursor: not-allowed;
      }
    }
  }
}
