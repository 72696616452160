.table-of-contents {
  height: 100%;
  width: 100%;
  display: flex;
  flex-flow: column;

  &__no-results {
    margin: $gutter-md;
    font-size: $font-size-sm;
  }
  &_right-menu-option-button-title-wrapper {
    display: flex;
    align-items: center;
    cursor: pointer;
    &__disabled {
      cursor: not-allowed;
    }
    svg {
      fill: $blue-1;
    }
    &:hover {
      background-color: $grey-1;
    }
  }
  &_right-menu-option-button {
    display: flex;
    align-items: center;
    &__disabled {
      color: $grey-3;
      cursor: not-allowed;
      border: 0.25rem;

      &:hover {
        cursor: not-allowed;
      }

      & svg {
        fill: $disabled-buttton-text-grey;
      }

      & path {
        fill: $disabled-buttton-text-grey;
      }
    }
  }

  &_right-menu-option-button-menu-list {
    border-radius: $border-radius-default;
    box-shadow: 0 1px 4px 0 $border-grey;
    outline: solid 1px $border-grey;
    background-color: $white;
    z-index: $z-index-dropdown;
    position: absolute;
    right: 0;
    font-size: $font-size-md;
    color: $black;
    width: 50%;
    margin-top: $gutter-xs;
  }

  &__buttons-container {
    width: 100%;
    font-size: $font-size-md;
    padding: $gutter-xxs;
    color: $blue-1;
    box-shadow: $box-shadow-2;
    margin-bottom: $gutter-xxs;
    user-select: none;
    display: flex;
    justify-content: space-between;

    & svg {
      margin: $gutter-xxs;
    }
  }

  &__refresh-button {
    position: absolute;
    bottom: 0;
    right: 0;
    margin-right: $gutter-md;
    border: none;
    background-color: transparent;
    outline: none;
    :hover {
      cursor: pointer;
    }
  }

  &__refresh-button-tooltip {
    width: 325px;
  }

  &__dismiss-button {
    background-color: transparent;
    border: none;
    outline: none;
    color: $blue-1;
    font-size: $font-size-md;
    cursor: pointer;
  }

  &__left-buttons {
    display: flex;
  }

  &__assigned-to-me {
    background-color: $white;
    padding-left: $gutter-xs;
    padding-right: $gutter-xs;
    border-radius: 1rem;
    border: 1px solid $blue-1;
    display: flex;
    justify-content: center;
    align-items: center;

    &--active {
      background-color: $blue-9;
      color: $white;
      padding-right: 0;
    }

    &:hover {
      background-color: $blue-9;
      color: $white;
    }
  }

  &__assigned-to-me-button {
    cursor: pointer;
    background-color: transparent;
    border: none;
    outline: 0;
    color: inherit;

    &[disabled] {
      cursor: not-allowed;
    }
  }

  &__assigned-to-me-close-button {
    & svg {
      width: 18px;
      height: 18px;
      & path {
        fill: $white;
      }
    }
  }

  &__edit-button {
    cursor: pointer;
    display: flex;
    align-items: center;
    &--disabled {
      &[disabled] {
        pointer-events: none;
      }
      color: $disabled-font-grey;
    }

    &__navigation {
      margin-left: $gutter-sm;
    }
  }

  &__tree-view-container {
    // top level sections styles
    height: 100%;
    overflow: auto;

    display: grid; // Defect: 851103 | fixing a UI issue with highlights not taking the whoe width when horizontal scroll is used
    grid-auto-rows: min-content;
    @include scroll-bar();

    & > .tree-view {
      & > .tree-view__item {
        font-weight: 600;
        line-height: $line-height-2;
        font-size: $font-size-md;
      }
      & > .tree-view__children > .tree-view > .tree-view__item {
        font-weight: 600;
      }
    }
  }
}
