.tech-support-access-disclaimer-modal {
    
  &__tech-support-statementlist{
    margin: 10px 0;
  }
  &__para-1{
    margin: 10px 0;
  }
  &__para-2{
    margin: 10px 0;
  }
}

#tech-support-access-disclaimer-modal-id-key{
  width: max-content;
  display: inline-flex;
  margin: 10px 4px 0 4px;
}