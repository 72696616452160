.notes-details {
  display: flex;
  color: $grey-4;
  margin-top: $gutter-xxs;

  &__item {
    display: inline-block;
    padding-right: $gutter-xs;
    margin-right: $gutter-xs;
    font-size: $font-size-sm;
    line-height: 1;
    flex-shrink: 0;
    &:not(:last-of-type) {
      border-right: 1px solid $grey-4;
    }
    &:last-of-type {
      margin-right: 0;
    }
  }
}
