.statement-wrap-up-approval-modal-1 {
  &__row {
    display: flex;
    width:100%;
    margin-bottom: $gutter-md;
  }
  &__col {
    display:flex;
    flex-direction: column;
    margin-right: $gutter-ml;
  }
  &__label {
    display:block;
    font-weight: $font-w-semi-bold;
    font-size: $font-size-sm;
  }
  &__value {
    display:block;
    font-size: $font-size-sm;
  }
}