.element-verified {
    background-color: $element-status-verified;
    font-size: $font-size-md;
    font-weight: $font-w-semi-bold;
    text-align: left;
    padding: 0;
    border: none;
    border-bottom: $border-dashed-blue;
  }
.element-reviewed {
    background-color: $element-status-reviewed;
    font-size: $font-size-md;
    font-weight: $font-w-semi-bold;
    text-align: left;
    padding: 0;
    border: none;
    border-bottom: $border-solid-green;
  }
.element-unverified {
    background-color: $element-status-unverified;
    font-size: $font-size-md;
    font-weight: $font-w-semi-bold;
    text-align: left;
    padding: 0;
    border: none;
    border-bottom: $border-dotted-yellow;
  }

