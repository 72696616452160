.no-projects {
  padding: $default-gutter;
  width: 100%;
  border: $data-grid-border;
  border-radius: $border-radius-default;
  background-color: $white;

  &__title {
    font-size: $font-size-xxl;
    margin-bottom: $default-gutter;
  }
  &__body {
    font-size: $font-size-lg;
  }
}
