.blackline-previous-panel {
    &__statement-header {
        height: 40px;
        border: 1px solid $active-grey;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 20px 0 20px;
        margin: 10px 0 0 10px;

        &--title {
            font-weight: 600;
            font-size: 14px;
            width: 300px;
        }
    }
}