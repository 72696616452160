.compare-panel {
  &__title {
    font-weight: bold;
  }

  & .panelContent {
    display: grid;
    grid-auto-rows: min-content;
    font-size: $font-size-md;
  }

  &__stats {
    background: $grey-0;
    padding: $gutter-md;
    border-bottom: 1px solid $grey-2;
    min-height: 165px;

    & .circle {
      border-radius: 50%;
      width: 14px;
      height: 14px;
      display: inline-block;
      margin-right: $gutter-md;
    }

    &--added {
      display: flex;
      align-items: center;

      & .circle {
        background: $green-1;
      }
    }

    &--deleted {
      display: flex;
      align-items: center;

      margin-top: $gutter-xs;

      & .circle {
        background: $red;
      }
    }

    &--changed {
      display: flex;
      align-items: center;

      margin-top: $gutter-xs;

      & .circle {
        background: $orange;
      }
    }
    &--unchanged {
      margin-bottom: $gutter-ml;
    }
  }

  &__toggle-container{
    border-bottom: 1px solid $border-grey;
    &--element-id, &--sync-scroll {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 5px 10px;
      .form-item__container {
        .form-item__label {
          padding-bottom: 0;
        }
      }
      .react-toggle{
        .react-toggle-track {
          height: 20px;
        }
        .react-toggle-thumb {
          top: 0;
          left: 0;
        }
        &--checked{
          .react-toggle-thumb {
            right: 0;
            left: auto;
          }
        }
      }
    }
  }

  .diff-list {
    padding: $gutter-xs;
    min-width: fit-content;

    & .diff-info {
      margin-bottom: 24px;

      .section-name {
        color: $blue-1;
        margin-bottom: $gutter-xs;

        font-weight: $font-w-semi-bold;
      }

      .section-content {
        & > ul {
          border: 1px solid $grey-2;
          border-radius: 5px;
          & > li:not(:last-child) {
            border-bottom: 1px solid $grey-2;
          }
          & > li {
            .row-items {
              padding: $gutter-xxs;
              margin-bottom: $gutter-xxs;
            }

            .clickable-element-title {
              cursor: pointer;
              padding: $gutter-xxs;
              margin-left: $gutter-md;

              &:hover {
                background-color: $grey-1;
                text-decoration: underline;
              }
              &--selected {
                background-color: $grey-2;
              }
            }

            .element-title {
              white-space: nowrap;
              padding: $gutter-xxs;
              margin-left: $gutter-md;

              &:hover {
                background-color: $grey-1;
              }
            }

            .tree-view__item {
              margin-bottom: 0;
              padding: 10px 15px;
            }
          }
        }
      }
    }
  }
}
