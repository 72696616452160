.create-tickmark-modal {
  &__text {
    & textarea {
      max-width: $modal-text-area-max-width;
      max-height: $modal-text-area-max-height;
    }
  }
}
.error-text-validation-msg{
  color: $red;
}