.annotation-display-filter {
  color: $black;
  position: absolute;
  z-index: $z-index-overlay;
  right: 0;
  top: 0;

  &__container {
    display: flex;
    align-items: center;
    border-radius: $border-radius-default;
    border: solid 1px $border-grey;
    background-color: $white;

    &--plus_minus_container {
      margin-right: $gutter-xxs;
    }
  }

  &__fixed-container {
    display: flex;
    align-items: center;
    width: fit-content;
    position: fixed;
    margin-left: -130px;
  }

  &__button {
    background: none;
    border: none;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    outline: none;
    cursor: pointer;
    padding: $gutter-xxs;

    &:focus,
    &:hover {
      background-color: $hover-grey;
    }

    &:active {
      background-color: $active-grey;
    }
    &[disabled] {
      & svg {
        cursor: not-allowed;
        background-color: $white;
        #BulkAction // ids of the particular pieces of the svg that need to change
        {
          fill: $disabled-font-grey;
        }
      }
    }
  }
}

.annotation-display-filter-options {
   
    z-index: $z-index-statement-summary-display-options;
    
    &__menu {
      padding: $gutter-md;
      border-radius: $border-radius-default;
      box-shadow: 0 1px 4px 0 $border-grey;
      outline: solid 1px $border-grey;
      background-color: $white;
      z-index: $z-index-dropdown;
      position: absolute;
      right: -95px;
      top: 5px;
      font-size: $font-size-md;
      color: $black;
      width: $statement-summary-display-options-width;
      margin-top: $gutter-sm + 20px;

      .checkbox {
        border: none;
      }
    }
  
    &__menu-options {
      display: flex;
      justify-content: space-between;
    }
  
  
    &__label {
      margin: 0 0 $gutter-xs $gutter-xs;
      color: $grey-4;
    }
  
    &__header {
      font-weight: $font-w-semi-bold;
      margin-bottom: $gutter-md;
    }

    &__footer{
      margin-top: 10px;
      display: flex;
      flex-direction: row;
      
      &-left{
        justify-content: flex-start;
        margin-right: auto;
      }
      &-right{
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
      }
    }

    &-button{
      margin-left: 5px;
      height: 2rem;
    }
   
  }
  