.blackline-version-subheader {
    &__statement-header {
        height: 40px;
        border: 1px solid $active-grey;
        border-top: none;
        border-right: none;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 10px 0 20px;

        &--title {
            font-weight: 600;
            font-size: 14px;
            width: 300px;
        }
    }
}