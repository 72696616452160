.report-preview-page-right-panel {
   &__element-report-header {
      height: 40px;
      border: 1px solid $active-grey;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 20px;

      &--title {
         font-weight: 600;
         font-size: 14px;
         padding-left: 30px;
      }

      &--title-reverse {
         padding-left: 0;
      }

      &--refresh-icon {
         display: flex;
         cursor: pointer;
         margin-right: 20px;
      }
   }

   &__tools {
      display: flex;
      align-items: center;
   }

   &__sync-scroll-toggle {
      display: flex;
      align-items: center;
      margin-right: 20px;

      .form-item__container {
         .form-item__label {
            padding-bottom: 2px;
         }
      }
   }

   &__refresh-icon {
      &--disabled {
         opacity: 0.5;
      }
   }

   .data-grid__row--highlight {
      background-color: $grey-10;
   }

   .data-grid__row--selected {
      background-color: $grey-9;
   }

   .data-grid {
      border-top: none;
      cursor: pointer;

      &__row {
         &:hover {
            background-color: $hover-grey;
         }

      }

      overflow: auto;
      height: calc(100vh - 193px);
      @include scroll-bar();

      >div {
         width: fit-content;
         border-right: solid 1px #c4c4c4;
         border-bottom: solid 1px #c4c4c4;
      }

      .data-grid__body {
         max-height: unset !important;
         min-height: unset !important;

         .virtualized-list {
            height: calc(100vh - 255px);

            .data-grid__col {
               min-width: 160px;
            }
         }
      }

   }
}