.annotation-map-action{
    &__success-header{
        display: inline;
        bottom: 6px;
        position: relative;
        margin-inline: 4px auto;
    }

    &__fail-header{
        display: inline;
        bottom: 6px;
        position: relative;
        margin-inline: 4px auto;
    }

    &__notify-header{
        display: inline;
        bottom: 4px;
        position: relative;
        margin-inline: 2px auto;
    }
}