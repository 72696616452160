.chatbot-response-feedback-block {
    &__reason-list {
        margin-bottom: 15px;
    }
    &__reason-label{
        display: table;
        color: $grey-4;
        padding-bottom: 8px;
        font-weight: $font-w-bold;
        font-size: $font-size-sm;
        padding-right: 10px;
    }
    &__text-count{
        display: flex;
        justify-content: flex-end;
    }
  }