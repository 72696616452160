// Overriding some of Mention's default styles
.notes-text-area {
  &__control {
    background-color: $white;
    height: 97px;
    font-size: $font-size-md;
    color: $default-input-text-grey;
  }

  &__highlighter {
    padding: $gutter-md;
    overflow: auto !important;
    z-index: -1; //this class needs to render below the buttons
  }

  &__input {
    padding: $gutter-md;
    overflow: auto !important;
    border-radius: $border-radius-default !important;
    border: solid 1px $border-grey;

    &:focus,
    &:hover {
      border: $hover-focus-border;
      @include adjust-padding-for-thicker-border-hover($gutter-md);
    }
  }

  &__suggestions {
    border: solid 1px $border-grey;
    padding: $gutter-xs;
    border-radius: $border-radius-default !important;
    margin-top: 15px !important;
  }

  &__focused {
    background-color: $blue-5;
  }
}
