@mixin scroll-bar {
  &::-webkit-scrollbar {
    height: $gutter-sm;
    width: $gutter-sm;
  }

  &::-webkit-scrollbar-track {
    background-color: $scroll-bar-track-grey;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: $border-radius-scroll-bar;
    background-color: $scroll-bar-thumb-grey;
    &:hover {
      background-color: $secondary-label-grey;
    }
  }
}
