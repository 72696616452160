.search {
  background-color: $white;
  padding: $gutter-xs;
  color: $default-input-text-grey;
  width: 100%;
  display: flex;
  font-size: $font-size-md;
  align-items: center;

  &:enabled {
    color: $black;
  }

  &--invalid {
    border: $invalid-border;
    border-radius: $border-radius-default;
  }

  &[disabled] {
    border: $disabled-border;
    border-left: none;
    color: $disabled-text;
    cursor: not-allowed;
  }

  &__container {
    width: 100%;
    display: inline-block;
    position: relative;
  }

  &__bar {
    display: flex;
    flex-wrap: nowrap;
    border: $search-border-grey;
    border-radius: $border-radius-default;

    &:focus-within,
    &[disabled] {
      &:hover {
        outline: $hover-focus-border;
      }
    }
  }

  &__input {
    border: none;
    width: 100%;

    &[disabled] {
      background-color: $white;
      border: none;
      color: $disabled-text;
      cursor: not-allowed;
    }

    &::-webkit-search-decoration,
    &::-webkit-search-cancel-button,
    &::-webkit-search-results-button,
    &::-webkit-search-results-decoration {
      -webkit-appearance: none;
    }
  }

  &__close {
    @include close-button();

    &[disabled] {
      &:hover {
        background-color: $white;
      }
      cursor: not-allowed;
    }
  }

  &__search-icon {
    padding: $gutter-xxs;
    vertical-align: bottom;
  }

  &__search-button {
    padding: $gutter-xs;
    background-color: $white;
    cursor: pointer;

    &[disabled] {
      cursor: not-allowed;
    }
  }
  &__arrow-icon {
    cursor: pointer;
    position: absolute;
    right: $gutter-xs;
    top: $gutter-xs;
  }
}
