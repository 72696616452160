.add-revision {
  &__banner {
    margin-top: $gutter-ml;

    &--error {
      margin-top: $gutter-md;

      &-header {
        margin-bottom: $gutter-xxs;
      }

      &-list-container {
        margin-left: $gutter-md;
      }

      &-item {
        margin-bottom: $gutter-xxxs;
      }
    }
    .banner__body{
      font-size: $font-size-sm;
    }
  }

  &__upload {
    width: 100%;
    border-radius: $border-radius-default;
    border: none;
    margin-top: $gutter-md;
    padding: $gutter-ml 0 $gutter-ml 0;

    &--active {
      border: $hover-focus-border;
      background-color: $drag-drop-blue-overlay;
      padding: $gutter-ml - 1px 0 $gutter-ml - 1px 0;
    }

    &--rejected {
      border: dashed 1px $error-red;
      background-color: $white;
    }
  }

  &__drop-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  &__select-statement-button {
    margin-top: $gutter-sm;
  }

  &__drop-title {
    font-size: $font-size-md;
    font-weight: $font-w-semi-bold;
    margin-top: $gutter-sm;
  }

  &__drop-copy {
    font-size: $font-size-sm;
    margin-top: $gutter-xs;
    width: 75%;
    text-align: center;
  }

  &__accepted-files-list {
    margin: $gutter-xs 0 $gutter-xs 0;
  }

  &__accepted-files-row {
    display: flex;
    flex-wrap: nowrap;
  }

  &__accepted-file-name {
    color: $green-2;
    font-size: $font-size-sm;
    font-weight: $font-w-semi-bold;
    margin-right: $gutter-xs;

    &--error {
      color: $error-red;
    }
  }

  &__clear-icon {
    border-radius: 50%;
    padding: $statement-creation-clear-icon-padding;

    .icon-path {
      fill: $primary-blue;
    }

    &:focus,
    &:hover {
      background-color: $hover-blue;

      .icon-path-inner {
        fill: $white;
      }
    }

    &:active {
      background-color: $active-blue;

      .icon-path-inner {
        fill: $white;
      }
    }
  }


}