.side-by-side-page {
  @include side-by-side-view-selected-element();
  width: 100%;
  height: calc(100vh - #{$disclosure-analytics-header-height});
  min-width: auto;
  display: block;

  &__header{
    height: $gutter-xl;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: $white;
    font-size: $font-size-sm;
    box-shadow: 0 0 .25rem 0 $border-grey;
    border-bottom: $expandable-row-border;
  }

  &__header-container{
    display: flex;
    height: 100%;
    align-items: center;
  }

  &__header-back-button {
    @include back-button();
    display: grid;
    grid-template-columns: $global-header-back-button-spacing 1fr;
    margin-right: -$gutter-sm;
  }

  &__header-title {
    font-weight: $font-w-semi-bold;
    font-size: $font-size-default;
    color: #212121;
    max-width: 9.375rem; //150px
    margin-right: $gutter-xs;
    @include overflow-ellipsis;
  }

  &__element-callout {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    font-weight: 500;
    cursor: pointer;
    min-width: 35px;
    left: 1rem;
    &--icon {
      border: none;
      padding: 0px;
      min-width: 0px;
    }

    &--annotation-icon {
      padding: 1px;

      &--disabled {
        background-color: $disabled-button-background-grey;

        svg path {
          stroke: $disabled-text;
        }

        &:hover {
          background-color: $disabled-button-background-grey;
          cursor: not-allowed;

          svg path {
            fill: $disabled-text;
          }
        }
      }
    }
  }

  &__subheader {
    // by default position is static
    height: 0px;
    width: 100%;
  }

  &__subheader-container {
    display: flex;
    justify-content: center;
  }

  &__subheader-wrap {
    z-index: 1;
    padding: 0.6875rem 1rem; //11px 16px
    width: $gutter-xxl;
    background-color: #ffffff;
    cursor: pointer;
    border-left: $expandable-row-border;
    border-right: $expandable-row-border;
  }

  &__swap-icon{
    display: flex;
    justify-content: center;
  }

  &__swap-icon-tooltip{
    &.tooltip{
      &.place-top{
        &::before{
          height: 0.4375rem //7px
        }
      }  
    } 
  }

  &__body-section{ 
    display: flex;
    flex-direction: row;
    width: 100%;
    &__swap{
      flex-direction: row-reverse;
    }
  }


  &__footer{
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: white;
    z-index: 1;
    box-shadow: 0 -0.3125rem 0.625rem 0 rgba(0, 0, 0, 0.2); // 0 -5px 10px 0
    border-bottom: solid 1px #e0e0e0;
    position:relative;
  }

  &__footer-wrap{
    height: 100%;
    width: 95%;
    display: flex;
    justify-content: center;
    align-items: center;
    &__swap{
      justify-content: space-between;
      flex-direction: row-reverse;
    }
  }

  &__footer-left{
    width: 54%;
    padding: 0 .625rem; //10px
    &__swap{
      width: 46%;
      padding: 0 0.3125rem; //5px
    }
  }

  &__pointer-icon{
    width: 2%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__footer-left-title{
    text-align: center;
    font-size: $font-size-sm;
    border: dashed #efefef;
    background-color: #f5f5f4;
    padding: 7px;
  }

  &__clearSelection-btn {
    @include close-button();
    cursor: pointer;
    background: none;
    border: none;
    outline: none;
    position: absolute;
    top: 0.25rem; //4px    
  }

  &__display-title-modifier{
    display: flex;
  }

  &__element-count-label{
    width: 97%;
  }

  &__double-arrow-icon{
    &__swap{
      & svg{
        transform: scale(-1, 1);
      }
    }
  }

  &__footer-right{
    width: 49%;
    padding: 0 0.3125rem 0 0.625rem; // 0 5px 0 10px
    &__swap{
      width: 51%;
    }
  }

  &__footer-right-title{
    text-align: center;
    font-size: $font-size-sm;
    border: dashed #efefef;
    background-color: #f5f5f4;
    padding: 0.4375rem; //7px
  }

  &__save{
   height: 2.125rem; //34px
  }

  &__scroll-toggle-container{
    display: inline-flex;
    position: relative;
  }

  &__scroll-title{
    font-size: 1rem; //16px
    padding: 0.3rem 0.3125rem 0 .3125rem; // 0.3125 == 5px
    white-space: nowrap;
  }

  &__scroll-toggle{
    padding: 0.3rem 0 0 0; // 0.3125 == 5px
  }

  &__icon-dropdown-container{
    display: flex;
    justify-content: center;
    height: calc($gutter-ml + 1px);
    position: relative;
    left: 1.3rem;
  }

  &__icons {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    font-weight: 500;
    cursor: pointer;
  }
}  