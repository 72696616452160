.expandable-row {
  width: 100%;
  background-color: $white;
  border-radius: $border-radius-default;
  border-top: $expandable-row-border;
  &:last-child{
    border-bottom: $expandable-row-border;
  }
  

  &__header {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    padding: $gutter-md;
    cursor: pointer;
  }

  &__caret-header{
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    padding: $gutter-md;
    cursor: pointer;
  }

  &__title {
    font-weight: $font-w-semi-bold;
  }

  &__caret-title {
    font-weight: $font-w-semi-bold;
    margin-left: 10px;
  }

  &__toggle {
    border-radius: 50%;
    background-color: $white;
    cursor: pointer;
    outline: none;

    &:hover,
    &:focus {
      background-color: $hover-grey;
    }

    &:active {
      background-color: $active-grey;
    }
  }

  &__body {
    display: flex;
    flex-direction: column;
    padding: $gutter-md;
    padding-top: 0;
  }
}
