.notes-entry {
  padding: $gutter-md;
  border-bottom: $border-gray-light;
  position: relative;

  &:hover {
    background-color: $hover-grey;
    cursor: pointer;
    .notes-entry__note-body {
      text-decoration: underline;
    }
  }
  &:active,
  &--selected {
    background-color: $grey-2;
  }

  &--selected {
    .notes-entry__text-selected,
    .notes-entry__note-body {
      white-space: normal;
      overflow: visible;
    }
  }

  &__mark-note {
    position: absolute;
    top: $gutter-xxs;
    right: $gutter-md;
    display: flex;
    align-items: center;
  }

  &__status-icon {
    margin-right: $gutter-xxs;
    margin-top: $gutter-xs;
  }

  &__priority {
    margin-right: $gutter-xxs;
    &[disabled] {
      & path {
        fill: $grey-2;
      }
    }
  }

  &__status {
    &[disabled] {
      & circle {
        stroke: $grey-2;
      }
      & path {
        fill: $grey-1;
      }
    }
  }

  &__ghost-note {
    margin-right: $gutter-sm;
    cursor: auto;
  }

  &__text-selected {
    font-weight: $font-w-semi-bold;
    color: $blue-1;
    @include overflow-ellipsis;
    margin-bottom: $gutter-xxs;
  }
  &__name {
    font-size: $font-size-md;
    font-weight: $font-w-semi-bold;
    width: 65%;
    @include overflow-ellipsis;
    margin-bottom: $gutter-xs;

    &--ghost {
      width: 52%;
    }
  }
  &__section-header {
    font-size: $font-size-md;
    color: $grey-5;
    margin-bottom: $gutter-xs;
  }
  &__note-body {
    @include overflow-ellipsis;
    overflow-wrap: break-word;
    word-wrap: break-word;
    [cfto_user_id] {
      color: $blue-9;
    }
  }
  &__section-name {
    font-weight: $font-w-semi-bold;
  }

  &__reply-container {
    display: flex;
    margin-top: $gutter-md;
    margin-bottom: $gutter-sm;
  }

  &__note-edit-actions {
    margin-left: auto;
    display: flex;
  }

  &__expand-replies {
    @include hyperlink-button;
    font-size: $gutter-md - 2;
  }

  &__reply-button {
    @include icon-button-with-one-path;
    margin-right: $gutter-sm;
    &[disabled] {
      stroke: $grey-2;
    }
  }

  &__edit-button {
    @include icon-button-with-one-path;
    margin-right: $gutter-sm;
    &[disabled] {
      stroke: $grey-2;
      background-color: $grey-2;
    }
  }

  &__delete {
    @include icon-button-with-one-path;
    &[disabled] {
      stroke: $grey-2;
    }
  }

  &__reply-entry {
    padding: $gutter-md $gutter-sm;
    background-color: $white;
    margin: $gutter-sm 0;
    border-left: 3px solid $blue-1;
    border-radius: $gutter-xxxs;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__reply-user {
    margin-bottom: $gutter-xxs;
    color: $grey-4;
  }

  .reply-details {
    display: flex;
    margin-top: $gutter-xs;
  }
  &__item {
    display: inline-block;
    padding-right: $gutter-xs;
    margin-right: $gutter-xs;
    font-size: $font-size-sm;
    line-height: 1;
    flex-shrink: 0;
    color: $grey-4;
    &:not(:last-of-type) {
      border-right: 1px solid $grey-4;
    }
    &:last-of-type {
      margin-right: 0;
    }
  }

  &__reply-entry {
    padding: $gutter-md $gutter-sm;
    background-color: $white;
    margin: $gutter-sm 0;
    border-left: 3px solid $blue-1;
    border-radius: $gutter-xxxs;
  }

  &__reply-user {
    margin-bottom: $gutter-xxs;
    color: $grey-4;
  }

  .reply-details {
    display: flex;
    margin-top: $gutter-xs;
  }
  &__item {
    display: inline-block;
    padding-right: $gutter-xs;
    margin-right: $gutter-xs;
    font-size: $font-size-sm;
    line-height: 1;
    flex-shrink: 0;
    color: $grey-4;
    &:not(:last-of-type) {
      border-right: 1px solid $grey-4;
    }
    &:last-of-type {
      margin-right: 0;
    }
  }

  &__reply-body {
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-all;
    -ms-word-break: break-all;
    [cfto_user_id] {
      color: $blue-9;
    }
  }
}
