#menu-root {
  position: relative;
}
.menu {
  border-radius: $border-radius-default;
  box-shadow: 0 1px 4px 0 $border-grey;
  outline: solid 1px $border-grey;
  background-color: $white;
  z-index: $z-index-dropdown;
  position: absolute;
  font-size: $font-size-md;
  color: $black;
  width: 100%;
  list-style: none;
  overflow: auto;
  display: inline-grid;
  @include scroll-bar();

  &__default-table-api-menu{
    outline: none;
  }

  &__dropdown-menu-withcheckbox{
    max-height: 190px;
    overflow: auto;
    @include scroll-bar();
  }

  &--overflow-scroll {
    overflow-y: scroll;
    @include scroll-bar();
  }

  &--centered {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &__option {
    &:focus:not([disabled]),
    &:hover:not([disabled]) {
      background-color: $hover-grey;
      font-weight: $font-w-bold;
    }
    & span {
      padding: $gutter-sm;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: flex;
      cursor: pointer;
      height: $menu-option-height;

      &[disabled] {
        color: $disabled-text;
        cursor: not-allowed;
      }
    }
    &--selected {
      background-color: $selected-menu-option-blue;
    }
    &--centered {
      display: flex;
      justify-content: center;
    }
    &--create-button {
      background-color: $white;
      width: 100%;
      box-shadow: $box-shadow-1;
      border: solid 1px $border-grey;
      border-top: none;
      &[disabled] {
        background-color: $disabled-button-background-grey;
      }
    }
  }
  
  &__checkboxoption {
    padding-left: 2rem;
    &:focus:not([disabled]),
    &:hover:not([disabled]) {
      background-color: $hover-grey;
      font-weight: $font-w-bold;
    }
    &--selected {
      background-color: $selected-menu-option-blue;
    }
    &--centered {
      display: flex;
      justify-content: center;
    }
    &--create-button {
      background-color: $white;
      width: 100%;
      box-shadow: $box-shadow-1;
      border: solid 1px $border-grey;
      border-top: none;
      &[disabled] {
        background-color: $disabled-button-background-grey;
      }
    }

    .checkbox{
      &:hover,
      &:focus {
        border: none;
        .checkbox__input {
          border: $checkbox-border-size + 1px solid;
          &[disabled] {
            border: none;
          }
        }    
      }
      &.checkbox--checked{
        border: none;
      }
    }   
  }

  &__optionlabel{
    font-size: larger;
    font-weight: 600;
    padding: 8px;
    width: 100%;
    display: flex;
  }
  &__no-results {
    padding: $gutter-sm $gutter-md;
    display: flex;
    align-items: center;
    border-bottom: solid 1px $border-grey;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__checkmark {
    vertical-align: bottom;
    margin-left: auto;
  }

  &__table-drawer {
    box-shadow: $box-shadow-1;
    .data-grid {
      &__cols-header-row {
        top: 0; // ensures data grid col header doesn't scroll when in a menu
      }
      &__row {
        border-bottom: none;
        cursor: pointer;
        &:focus,
        &:hover {
          background-color: $hover-grey;
          font-weight: $font-w-bold;
        }
        &[disabled] {
          font-weight: normal;
          cursor: not-allowed;
        }
      }
    }
  }
}
