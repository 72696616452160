.element-internal-reference-list {
  &__button-container {
    padding: $gutter-md $gutter-md 0 $gutter-md;
  }
  &__suggested-count {
    @include hyperlink-button;
    text-align: left;
    margin-bottom: $gutter-md;
    &[disabled] {
      color: $disabled-buttton-text-grey;
      cursor: not-allowed;
    }
  }

  &__button-container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: $gutter-sm;
  }

  &__internal-reference-add-button{
    margin-bottom: $gutter-md;
  }

  &__listing-container {
    min-height: $element-panel-annotations-tab-content-min-height;
  }
  &__empty-text-container {
    padding: $gutter-md;
  }
  &__copy-all-annotation-class {
      margin: 0 $gutter-md $gutter-md $gutter-md;
  }
}
