.annotations-tabs {
  border: $element-panel-border;
  border-bottom: none; 
  .tabs-group__tab {
    padding-left: $gutter-xxxs;
    padding-right: $gutter-xxxs;
  }
  &__tabs {
    border-bottom: $element-panel-border; 
    & span {
      @include breakpoint(tablet) {
        font-size: $font-size-xs;
      }
      @include breakpoint(desktop) {
        font-size: $font-size-sm; 
      }
      @include breakpoint(large-desktop) {
        font-size: $font-size-default;
      }
    }
  }
  &__tab--flagged {
    color: $orange;
  }
}
