.active-users {
  padding: $gutter-xs;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  &__user {
    // style for overlapping
    position: relative;
    overflow: hidden;
    margin-left: -12px;

    //general styles
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    padding: 5px;
    border-radius: 50%;
    background-color: $black-1;
    color: $white;
    font-size: $font-size-xs;
    font-family: $font-family-default;
    font-weight: $font-w-regular;
    cursor: default;

    //transition to animate the hover effect
    transition: 0.25s;
  }

  &--aligned {
    margin-right: 40px;
  }
  &:hover {
    & .active-users__user--multiple {
      margin-right: $gutter-xxxs;
      margin-left: 0;
    }
  }
  &__users-list-tooltip {
    white-space: pre-wrap;
    //this change makes the tooltip arrow to change its position to the end.
    &::after{
      left: 91%!important;
    }
  }
}
