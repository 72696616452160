.project-list-header {
  display: flex;
  flex-direction: column;
  padding: $default-gutter;
  justify-content: space-between;
  min-height: $project-list-header-min-height;
  width: 100%;
  z-index: $z-index-project-list-header; // this z-index was added to ensure that kebab menus didn't overflow the top of the menu when scrolling
  position: relative;
  background-color: $white;
  border-bottom: $project-list-border;
  box-shadow: $box-shadow-2;

  &__title {
    text-align: left;
    margin-bottom: $gutter-xxs;
  }
  & .button__icon__icon {
    margin-right: 0;
  }
  &__settings-button {
    padding:0%;
  }
  &__display-horizontal {
    display:flex;
    flex-direction: row;
    margin-bottom: 4px;
    justify-content: space-between;
    align-items: center;
  }

  &__geo-dropdown{
    .dropdown__header{
      padding: 4px 8px;
      border-left: none;
      border-top: none;
      border-bottom: none;
      &:focus,
      &:hover{
        border-right: solid 1px $border-grey;
      }
      .dropdown__title{
        margin-right: $gutter-xxs;
      }
    }
    
  }

  &__create {
    width: 100%;
    justify-content: center;
  }

  &__link {
    text-decoration: none;
    display: flex;
  }

  &__omnia-link {
    display: flex;
    margin: $gutter-xxs auto;
  }

  &__link-icon {
    transform: rotate(90deg);
    margin-top: -$gutter-xxs;
  }

  &__omnia-link-text {
    font-size: $gutter-md - 2;
    @include hyperlink-button;
  }

  &__no-projects {
    font-size: $font-size-md;
    color: $secondary-label-grey;
  }
}

.button {
  font-weight: $font-w-regular;
}
