.radio {
  display: inline-flex;
  align-items: center;
  padding: $gutter-xs;
  border-radius: $border-radius-default;
  &:not(:last-child) {
    margin-right: $gutter-xs;
  }

  &--checked {
    border: $radio-border-size solid $radio-button-hover-outline-blue;
    padding: $gutter-xs - $radio-border-size;
  }

  &--invalid {
    color: $error-red;
    .radio__input {
      @include radio-outline-fix($error-red);
    }
  }

  &--disabled:not(.radio--checked) {
    border: none !important;
    padding: $gutter-xs !important;
  }

  &:hover,
  &:focus {
    border: $radio-border-size solid $radio-button-hover-outline-blue;
    padding: $gutter-xs - $radio-border-size;
    .radio__input:not([disabled]) {
      @include radio-outline-fix($hover-blue);
    }
  }
  &__input {
    appearance: none;
    position: relative;
    cursor: pointer;
    outline: none;
    width: $radio-height;
    height: $radio-height;
    margin-right: $gutter-xs;
    background: url('../../../icons/radio-unselected.svg') center no-repeat;
    border-radius: 50%;
    padding: $radio-border-size;
    @include radio-outline-fix($radio-unchecked-grey);

    &:checked {
      background: url('../../../icons/radio-selected.svg') center no-repeat;
      @include radio-outline-fix($primary-blue);
    }

    &[disabled] {
      @include radio-outline-fix($radio-disabled-grey);
      padding: 0px;
      &:checked {
        @include radio-outline-fix($radio-disabled-grey);
        background: url('../../../icons/radio-selected-disabled.svg') center
          no-repeat;
      }
    }
  }

  &__label {
    &[disabled] {
      color: $radio-label-disabled-grey;
    }
  }
}
