.statement-context-menu {
  position: fixed;
  background: $white;
  border: 1px $border-gray;
  box-shadow: $box-shadow-4;
  border-radius: $border-radius-default;
  white-space: nowrap;
  user-select: none;

  & li {
    &:hover {
      background-color: $hover-grey;
    }
    button {
      @include remove-default-button-styling;
      padding: $gutter-sm;
    }
  }
}
