.bulk-assign-sections{
    &__wrapper{
        max-height: 65vh;
        overflow: auto;
        @include scroll-bar();
    }
    &__tree-view-container{
        overflow: auto;
        @include scroll-bar();
        max-height: 30vh;
    }
    &__select-all-and-count{
        display: flex;
        justify-content: space-between;
        margin-top: $gutter-md;
        margin-bottom: $gutter-md;
    }
    &__count {
        color: $grey-5;
        white-space: nowrap;
      }
    &__guidance{
        margin-bottom: $gutter-md + $gutter-md;
    }
    &__actions{
        margin-bottom: $default-gutter;
        font-size: $font-size-md;
    }
    &__bookmarks{
        margin-bottom: $gutter-sm;
        font-size: $font-size-md;
        margin-top: $gutter-sm;
    }

    &__actions-title, &__bookmarks-title{
        display: table;
        color: $label-grey;
        padding-bottom: $gutter-xs;
        font-weight: $font-w-bold;
        font-size: $gutter-sm;
        padding-right: 10px;
    }

    &__bookmarks-list{
        border: 1px solid $grey-3;
        padding: 0 $gutter-xs;
        border-radius: $border-radius-default;
    }
   
    &__radio{
        .radio{
            display: block;
            width: fit-content;
        }
    }
}