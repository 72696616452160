.already-attached-tickmarks {
  padding: $gutter-xs;
  border-bottom: $border-dark-grey;

  &__title {
    font-size: $font-size-sm;
    color: $grey-4;
    font-weight: $font-w-semi-bold;
    margin: $gutter-xs 0 $gutter-md 0;
  }

  &__no-results {
    font-size: $font-size-sm;
  }
}
