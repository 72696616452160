.dropdown {
  width: 100%;
  display: inline-block;
  position: relative;

  &__header {
    border-radius: $border-radius-default;
    border: solid 1px $border-grey;
    background-color: $white;
    padding: $gutter-xs;
    color: $default-input-text-grey;
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    font-size: $font-size-md;
    cursor: pointer;

    &:enabled {
      color: $black;
    }

    &--invalid {
      border: $invalid-border;
      @include adjust-padding-for-thicker-border-hover($gutter-xs);
      .chevron {
        fill: $error-red;
      }
    }

    &:focus,
    &:hover {
      border: $hover-focus-border;
      outline: none;
      @include adjust-padding-for-thicker-border-hover($gutter-xs);
    }

    &[disabled] {
      border: $disabled-border;
      padding: $gutter-xs;
      color: $disabled-text;
      cursor: not-allowed;
    }
    &--centered {
      display: flex;
      justify-content: center;
    }
    &--borderless {
      border: none !important;
      padding: $gutter-xs !important;
    }
  }

  &__title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: $font-size-md;
    margin-right: $gutter-xs;
    &--selected {
      color: $black;
    }
  }

  &__chip-container{
    display: flex;
    flex-wrap: wrap;
    .chip {
      margin: 0 5px 5px 0;
    }
  }

  &__arrow-down{
    margin-left: auto;
    display: flex;
    align-items: center;
  }

  &__toggle {
    vertical-align: bottom;
    margin-left: auto;

    &[disabled] {
      .chevron {
        fill: $disabled-text;
      }
    }
  }

  &__kebab {
    border-radius: 50%;
    vertical-align: bottom;
    padding: $gutter-xxs;
    cursor: pointer;

    &:focus,
    &:hover {
      background-color: $hover-blue;
      .kebab-icon {
        fill: $white;
      }
    }

    &:active {
      background-color: $active-blue;
      .kebab-icon {
        fill: $white;
      }
    }

    &[disabled] {
      cursor: not-allowed;
      background-color: transparent;
      .kebab-icon {
        fill: $disabled-text;
      }
    }
  }

  &--borderless {
    width: fit-content;
  }
}

.__kebab-menu {
  min-width: fit-content;
}
