@mixin clickable-icon-no-circle {
  border-radius: 50%;

  &:hover:not([disabled]),
  &:focus:not([disabled]),
  &:active:not([disabled]) {
    cursor: pointer;
    & .icon-path {
      fill: $white;
    }
  }
  &:hover:not([disabled]),
  &:focus:not([disabled]) {
    g {
      fill: $hover-grey;
    }
  }
  &:active:not([disabled]) {
    g {
      fill: $hover-blue;
    }
  }
  &[disabled] {
    cursor: not-allowed;
    g {
      fill: none;
    }

    .icon-path {
      fill: $disabled-grey;
    }
    outline: none;
  }
}

@mixin clickable-icon-circle {
  border-radius: 50%;
  border: solid 1px $primary-blue;
  padding: 2px;

  & .icon-path {
    fill: $primary-blue;
  }

  &:hover:not([disabled]),
  &:focus:not([disabled]),
  &:active:not([disabled]) {
    cursor: pointer;
    & .icon-path {
      fill: $white;
    }
  }
  &:hover:not([disabled]),
  &:focus:not([disabled]) {
    border: solid 1px $hover-blue;
    background-color: $hover-blue;
    g {
      fill: $hover-blue;
    }
  }
  &:active:not([disabled]) {
    border: solid 1px $active-blue;
    background-color: $active-blue;
    g {
      fill: $active-blue;
    }
  }
  &[disabled] {
    cursor: not-allowed;
    border: solid 1px $grey-3;
    g {
      fill: none;
    }
    .icon-path {
      fill: $disabled-grey;
    }
    outline: none;
  }
}
