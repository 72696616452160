.banner {
  &__success {
    @include banner(
      $border-radius-default,
      $success-border-green,
      $success-background-green
    );
  }

  &__information {
    @include banner(
      $border-radius-default,
      $information-border-blue,
      $information-background-blue
    );
  }
  &__information-secondary {
    @include banner(
      $border-radius-default,
      $white,
      $white
    );
  }

  &__guidance {
    @include banner(
      $border-radius-default,
      $guidance-border-teal,
      $guidance-background-teal
    );
  }

  &__warning {
    @include banner(
      $border-radius-default,
      $warning-border-yellow,
      $warning-background-yellow
    );
  }

  &__error {
    @include banner(
      $border-radius-default,
      $error-border-red,
      $error-background-red
    );
  }

  &__header {
    display: flex;
  }

  &__close {
    @include close-button();
  }

  &__icon {
    margin-right: $gutter-xs;
    vertical-align: bottom;
    flex-shrink: 0;
  }

  &__body {
    font-size: $font-size-sm;
  }
}
