.statement-list {
  &__list {
    &--revision-container,
    &--admin-columns-container {
      display: flex;
      flex-wrap: nowrap;
      align-content: center;
      align-items: center;
    }
    &--revision-icon,
    &--admin-columns-icon {
      margin-left: $gutter-xxs;
      border-radius: 50%;
      cursor: pointer;

      &:hover,
      &:focus {
        background-color: $hover-blue;
        border: solid 1px $hover-blue;
        & path {
          fill: $white;
        }
      }
      &:active {
        background-color: $active-blue;
        border: solid 1px $active-blue;
        & path {
          fill: $white;
          stroke: $white;
        }
      }
    }
    &--admin-columns-icon {
      margin-right: $gutter-xxxs;
    }
    &--pinned{
      position: relative;
      left: 4px;
    }
  }

  &__legal-hold-prefix {
    color: $orange;
    font-size: $font-size-default;
    margin-right: $gutter-xxs;
    font-weight: $font-w-semi-bold;
  }
  &__legal-hold {
    display: flex;
    flex-wrap: nowrap;
  }
  &__list-item {
    &--pending-approval {
      background-color: $pale-orange;
      border: 1px solid $orange;
      & .statement-name-pending-approval-text {
        color: $orange;
      }
    }
    &--not-viewed-yet {
      background-color: $blue-4;
    }
  }
  &__revisions-list {
    &--link-icon {
      border-radius: 50%;
      cursor: pointer;

      &:hover,
      &:focus {
        background-color: $hover-blue;
        border: solid 1px $hover-blue;
        .icon-path {
          fill: $white;
          stroke: $white;
        }
      }
      &:active {
        background-color: $active-blue;
        border: solid 1px $active-blue;
        .icon-path {
          fill: $white;
          stroke: $white;
        }
      }
    }
    &--revision-container {
      display: flex;
      flex-wrap: nowrap;
      align-content: center;
    }
    &--revision-icon {
      margin-left: $gutter-xxs;
      border-radius: 50%;
      cursor: pointer;

      &:hover,
      &:focus {
        background-color: $blue-4;
        border: solid 1px $blue-4;
        .icon-path {
          fill: $white;
          stroke: $white;
        }
      }
      &:active {
        background-color: $blue-6;
        border: solid 1px $blue-6;
        .icon-path {
          fill: $white;
          stroke: $white;
        }
      }
    }
  }
  &__revisions-list {
    &--link-icon {
      border-radius: 50%;
      cursor: pointer;

      &:hover,
      &:focus {
        background-color: $blue-4;
        border: solid 1px $blue-4;
        .icon-path {
          fill: $white;
          stroke: $white;
        }
      }
      &:active {
        background-color: $blue-6;
        border: solid 1px $blue-6;
        .icon-path {
          fill: $white;
          stroke: $white;
        }
      }
    }
  }
  &__modal {
    &--copy {
      font-size: $font-size-sm;
      margin-left: $gutter-ml;
    }

    &--radio {
      margin-top: $default-gutter;
    }
  }
  &__kebab-menu {
    width: $statement-list-kebab-menu-width;
    left: auto;
    right: 1rem;
  }
  &__delete-revision-modal-kebab-menu {
    width: $statement-list-delete-revision-modal-kebab-menu-width;
    left: auto;
    right: 0rem;
  }

  &__overdue-within-thirty-days {
    color: $orange;
    width: 63%;
  }

  &__overdue-past-thirty-days {
    color: $red;
    width: 63%;
  }

  &__days-container {
    display: flex;
    align-items: center;
  }

  &__information-icon {
    margin-left: $gutter-xxs;
  }

  &__button-container {
    display: flex;
    flex-direction: row;
  }
  &__esc-button {
    margin-right: calc(#{$gutter-md} + #{$gutter-sm});
  }
}
#statement-list-id-esc-support-tooltip-tooltip {
  width: 500px;
}
