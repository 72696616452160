.flyoutPanel {
  background-color: $white;
  width: $side-panel-width;
  position: fixed;
  z-index: $flyout-z-index;
  top: $gutter-xl + $disclosure-analytics-header-height;

  bottom: 0;

  border: $flyout-border;
  border-top: none;
  border-left: $border-gray;

  display: flex;
  overflow: auto;
  flex-flow: column;

  animation-iteration-count: 1;
  animation-fill-mode: forwards;

  background: $white;

  right: -$side-panel-width;

  &.left {
    right: auto;
    left: -$side-panel-width;
  }

  &-enter-done {
    right: 0;

    animation: slideInFromRight 0.3s;

    &.left {
      animation: slideInFromLeft 0.3s;

      right: auto;
      left: 0;
    }
  }

  &-exit {
    animation: slideOutToRight 0.3s;
    animation-iteration-count: 1;

    animation-fill-mode: forwards;
    &.left {
      animation: slideOutToLeft 0.3s;
    }
  }

  &-exit-done {
    right: -$side-panel-width;
    &.left {
      left: -$side-panel-width;
      right: auto;
    }
  }

  .panelHeader {
    position: relative;
    padding: $flyout-header-padding;

    background: $white;

    border-bottom: $border-gray-light;

    &__back {
      position: absolute;
      left: $flyout-header-padding;
      top: $flyout-header-padding;
      &:hover {
        .icon-path {
          fill: $blue-1;
        }
        border-bottom: 1px $blue-1 solid;
        cursor: pointer;
      }
    }

    .title {
      @include truncate;

      font-size: 2rem;

      display: inline-block;

      width: 100%;
      margin: 0;

      vertical-align: middle;
    }
    & .panelHeader__close {
      @include close-button();
      cursor: pointer;
      background: none;
      border: none;
      outline: none;
      position: absolute;
      top: $gutter-xs;
      right: $gutter-xs;
    }
  }

  .panelFooter {
    display: flex;
    width: 100%;
    padding: $gutter-md;
  }
  .panelHeader ~ .tabbedContent,
  .panelHeader ~ form .panelContent {
    border-color: $white;
  }

  .panelContent {
    overflow-y: auto;
    @include scroll-bar();

    width: 100%;
    height: 100%;

    text-align: left;

    border-bottom: $flyout-border;
  }
}
