.export-panel {
  &__title {
    font-size: $font-size-xl;
    font-weight: $font-w-semi-bold;
  }

  &__download-counter{
    height: 25px;
    width: 25px;
    background-color: green;
    border-radius: 50%;
    display: inline-block;
    margin-left: 3px;
    color:white;
    vertical-align:baseline;
  }

  &__section {
    padding: $gutter-md;
    display: flex;

    &:hover {
      background-color: $hover-grey;
      cursor: pointer;
    }

    &--selected {
      background-color: $grey-2;
    }

    &--with-border {
      border-bottom: $border-gray-light;
    }
  }

  &__icon {
    margin-right: $gutter-sm;
    min-width: 28px;
    min-height: 28px;
  }

  &__col-2 {
    display: flex;
    flex-direction: column;
    margin-top: $gutter-xxxs;
  }

  &__section-header {
    font-size: $font-size-ml;
    font-weight: $font-w-semi-bold;
    margin-bottom: $gutter-xxs;
  }

  &__section-content {
    font-size: $font-size-sm;
    color: $grey-5;

    &--not_selected {
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }

  &__button-icon {
    margin-right: $gutter-sm;
    width: 25px;
    height: 25px;
  }

  &__button {
    outline: none;
    background-color: transparent;
    border: none;
    display: flex;
    align-items: center;
    margin-top: $gutter-xxs;
    color: $blue-3;
    font-size: $font-size-sm;
    width: fit-content;
    padding: $gutter-xxs;
    margin-left: -$gutter-xxs;

    &--download {
      &:hover {
        text-decoration: underline;
      }
    }

    &:hover {
      cursor: pointer;
    }
    &:active {
      background-color: $active-grey;
      cursor: pointer;
    }

    &[disabled] {
      color: $disabled-font-grey;
      cursor: not-allowed;
      svg path {
        opacity: 0.4;
      }
      &:hover {
        text-decoration: none;
      }
    }
  }

  &__button-container {
    width: fit-content;
  }
  &__navigate-export-tab{
    border-bottom: $border-gray-light;
    border-top: none;
    border-left: none;
    border-right: none;
  }
}

.download-ready-message{
  color:blue;
  text-decoration: underline;
  :hover{
    background-color: $grey-1;
    cursor: pointer;
  }
}
