.carry-forward-info {
  font-size: $font-size-md;
  &__header {
    font-weight: $font-w-semi-bold;
  }

  &__ordered-list {
    margin-top: $gutter-xs;
    padding-left: $gutter-ml;
  }

  &__bullet-container {
    margin-top: $gutter-xs;
    padding-left: $gutter-xl;
  }

  &__unordered-list {
    list-style-type: disc;
    padding-left: $gutter-ml;
    margin-top: $gutter-xs;
  }

  &__footer {
    margin-top: $gutter-md;
  }
}
