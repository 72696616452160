.zoom-control {
  color: $black;
  position: static;
  z-index: $z-index-overlay;
  right: 0;
  top: 0;

  &__container {
    display: flex;
    align-items: center;
    border-radius: $border-radius-default;
    border: solid 1px $border-grey;
    background-color: $white;
    height: $gutter-ml-1;

    &--plus_minus_container {
      margin-right: $gutter-xs;
    }
  }

  &__fixed-container {
    display: flex;
    align-items: center;
    width: fit-content;
    position: static;
    margin-left: 0px;
  }

  &__plus,
  &__minus {
    background: none;
    border: none;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    outline: none;
    cursor: pointer;
    padding: $gutter-xxs;

    &--border {
      border-right: solid 1px $border-grey;
    }

    &:focus,
    &:hover {
      background-color: $hover-grey;
    }

    &:active {
      background-color: $active-grey;
    }

    &[disabled] {
      & svg {
        cursor: not-allowed;
        background-color: $white;

        #minus,
        // ids of the particular pieces of the svg that need to change
        #add {
          fill: $disabled-font-grey;
        }
      }
    }
  }

  &__content-view-mode-button {
    margin-left: auto;
    background-color: $white;
    cursor: pointer;
    fill: $primary-blue;

    &:focus,
    &:hover {
      background-color: $hover-blue;
      fill: $white;
    }

    &--disabled {
      fill: $disabled-text;
      background-color: $disabled-button-background-grey;

      &:hover {
        fill: $disabled-text;
        cursor: not-allowed;
        background-color: $disabled-button-background-grey;
      }
    }

    height: 28px;
    width: 28px;
  }
}

.side-by-side-zoom-control {
  color: $black;
  position: absolute;
  z-index: 50;
  right: 0;
  top: 0;

  &__fixed-container {
    display: flex;
    align-items: center;
    width: fit-content;
    position: fixed;
    margin-left: -95px;
  }
}