.create-workpaper-modal {
  &__instructions {
    font-size: $font-size-md;
    margin-bottom: $gutter-md;
  }

  &__reference-number {
    margin-bottom: $gutter-md;
    & textarea {
      height: $gutter-xxxl;
      max-width: $modal-text-area-max-width;
      max-height: $modal-text-area-max-height;
    }
  }
}
