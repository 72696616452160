.learn-more-modal {
  &__title,
  &__description {
    font-size: $font-size-sm;
  }

  &__title {
    font-weight: $font-w-semi-bold;
    margin-bottom: $gutter-xs;
  }
  &__description {
    margin-bottom: $gutter-md;
  }
}
