.maintenance-banner{
    display: flex;
    position: fixed;
    max-height: 10vh;
    width: 100%;
    z-index: 300;
        @include banner(
          $border-radius-default,
          $information-border-blue,
          $information-background-blue
        );
        &__close {
          @include close-button();
          width: 10%;
          margin-right: $gutter-md;
        }
        &__body{
            align-items: center;
            margin-left: 20px;
            word-break: break-word;
            hyphens: auto;
            ul,
            ol {
              list-style-position: outside;
              margin-left: $gutter-xxs;
            }
            ul {
              list-style-type: disc;
            }
        }
        &-tooltip-message{
          word-break: break-word;
          hyphens: auto;
          ul,
          ol {
            list-style-position: outside;
            margin-left: $gutter-xxs;
          }
          ul {
            list-style-type: disc;
          }
        }
}