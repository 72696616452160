.statement-annotations-update-block {
  display: flex;
  justify-content: center;
  height: calc($gutter-ml + 1px);
  margin-right: 8px;

  &__icons {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    font-weight: 500;
    cursor: pointer;
  }
}