.tickmark-search-results {
  @include scroll-bar();
  display: flex;
  flex-direction: column;
  padding: $gutter-md;
  border-radius: $border-radius-default;
  box-shadow: 0 1px 4px 0 $border-grey;
  outline: solid 1px $border-grey;
  background-color: $white;
  margin-bottom: $gutter-md;
  max-height: $element-panel-search-results-max-height;
  overflow-y: auto;
  position: absolute;
  width: 100%;
  z-index: $z-index-dropdown;

  &__entry {
    display: flex;
    justify-content: space-between;
    margin-bottom: $gutter-md;

    &__info {
      display: flex;
      flex-direction: column;
    }

    &__ordinal {
      font-weight: $font-w-semi-bold;
      margin-bottom: $gutter-xxs;
    }

    &__ordinal,
    &__body {
      display: block;
      font-size: $font-size-md;
    }
  }
}
