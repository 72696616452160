.toolkit {
  background-color: $white;
  width: $toolkit-width;
  height: 100%;
  position: fixed;
  z-index: $flyout-z-index + 1; // show up on top of flyout
  border-right: $flyout-border;
  //border-top: $flyout-border;
  top: $gutter-xl + $disclosure-analytics-header-height;
  &__option {
    @include remove-default-button-styling;
    width: $toolkit-width;
    height: $toolkit-width;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    svg path{
      fill: #A3A3A3;
    }

    &:hover,
    &:active,
    &--selected {
      svg path {
        fill: $white;
      }
    }

    &:hover {
      background-color: $hover-blue;
    }

    &:active {
      background: $active-blue;
    }

    &--selected {
      background-color: $primary-blue;
    }

    &--disabled {
      cursor: not-allowed;
      background-color: $disabled-button-background-grey;
      &:hover {
        background-color: $disabled-button-background-grey;
        svg path {
          fill: $disabled-text;
        }
      }
      
    }

    &--disabled-icon {
     pointer-events: none;
    }
  }
  #toolkit-compare{
    svg path{
      fill: #A3A3A3;
    }
    &.toolkit__option--selected{
      svg path {
        fill: $white;
      }
    }
    &:hover,
    &:active{
      svg path {
        fill: $white;
      }
    }
  }
}
