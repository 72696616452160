.tertiary-comfort-annotation-filters {
    &__search {
      padding-right: $gutter-md;
      margin-bottom: $gutter-md;
    }
  
    &__checkbox-container {
      display: flex;
      margin-bottom: $gutter-sm;
    }
  }
  