.project-edit {
  background-color: $page-background-grey;
  padding: $gutter-ml;
  padding-top: $gutter-ml;
  width: 100%;

  &__back-button {
    @include back-button();
    padding: 0;
  }

  &__header {
    font-size: $font-size-xl;
    margin-bottom: $gutter-xxs;
  }

  &__back-title {
    font-size: $font-size-md;
    color: $primary-blue;
  }

  &__page-title {
    font-size: $font-size-xl;
    margin-top: $gutter-xs;
  }

  &__body {
    width: 100%;
    display: flex;
  }

  &__section-menu {
    width: 18%;

    &__menu {
      top: calc(
        #{$gutter-md} + 58px + #{$disclosure-analytics-header-height}
      ); //58 px is the edit page header height
      position: sticky;
    }
  }
  &__project-sections {
    width: 82%;
  }

  &__progress-bar {
    width: 20%;
  }

  &__project-info {
    width: 100%;
    padding-bottom: $gutter-md;
  }

  &__manage-users {
    width: 100%;
    padding-bottom: $gutter-md;
  }
  &__manage-coe-users{
    width: 100%;
    padding-bottom: $gutter-md;
  }
  &__legal-hold {
    width: 100%;
  }

  &__button-container {
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-end;
    background-color: rgba(
      250,
      250,
      250,
      0.85
    ); //$page-background-grey wit 0.85% transparency
    position: sticky;
    top: $disclosure-analytics-header-height;
    z-index: $z-index-global-header;
    padding: $gutter-sm 0 $gutter-md;
  }

  &__cancel-button {
    padding-left: $default-gutter;
    padding-right: $default-gutter;
    margin-right: $gutter-md;
    position: sticky;
    top: 0;
    border: $blue-border;
  }
  &__create-button {
    position: sticky;
    top: 0;
  }
}
