@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes slideInFromRight {
  0% {
    right: -$side-panel-width;
  }
  100% {
    right: 0;
  }
}

@keyframes slideInFromLeft {
  0% {
    left: -$side-panel-width;
  }
  100% {
    left: 0;
  }
}

@keyframes slideOutToRight {
  0% {
    right: 0;
  }
  100% {
    right: -$side-panel-width;
  }
}

@keyframes slideInFromLeftNew {
  0% {
    left: 0;
  }
  100% {
    left: -$side-panel-width;
  }
}
@keyframes slideOutToLeft {
  0% {
    left: 0;
  }
  100% {
    left: -$side-panel-width;
  }
}
