.statement-summary-elements-total {
  display: flex;
  border: none;
  align-items: center;
  margin-left: $gutter-xs;
  margin-right: $gutter-xs;
  height: $gutter-ml;

  &__total {
    font-size: $font-size-sm;
    background-color: $white;
    padding: 0px;
    height: 100%;
    display: flex;
    align-items: center;
  }
}
