.confirm-override-system-flag {
    width: 38vw;
    &__guidance{
        margin-bottom: $gutter-md + $gutter-md;
    }
    &__maxlength-error-message{
        color:red;
    }
    &-tickmark_attach_list{
        overflow-y: auto;
        max-height: 200px;
    }
}