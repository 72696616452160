.soft-delete-revision-modal {
  &__copy {
    font-size: $font-size-sm;
    margin-left: $gutter-ml;
  }

  &__copy + &__radio {
    margin-top: $default-gutter;
  }
}
