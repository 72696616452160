// VARIABLES
@import 'variables/colors-variables.scss';
@import 'variables/global-variables.scss';
@import 'variables/fonts-variables.scss';
@import 'variables/header-variables.scss';
@import 'variables/common/tooltip-component-variables.scss';
@import 'variables/common/button-component-variables.scss';
@import 'variables/feature/element-panel-variables.scss';
@import 'variables/feature/formula-panel-variables.scss';
@import 'variables/common/modal-component-variables.scss';
@import 'variables/common/slide-toggle-component-variables.scss';
@import 'variables/common/checkbox-component-variables.scss';
@import 'variables/common/radio-component-variables.scss';
@import 'variables/feature//project-list-variables.scss';
@import 'variables/feature//project-details-variables.scss';
@import 'variables/common/progress-bar-step-varibales.scss';
@import 'variables/common//tabs-group-component-variables.scss';
@import 'variables/common/menu-component-variables.scss';
@import 'variables/common/global-header-component-variables.scss';
@import 'variables/pages/statement-content-page-variables.scss';
@import 'variables/common/flyout-component-variables.scss';
@import 'variables/feature/formula-panel-variables.scss';
@import 'variables/common/expandable-row-component-variables.scss';
@import 'variables/feature/progress-bar-variables.scss';
@import 'variables/feature/statement-processing-variables.scss';
@import 'variables/feature/statement-creation-variables.scss';
@import 'variables/feature/internal-reference-panel-variables.scss';
@import 'variables/feature/tieout-element-variables.scss';
@import 'variables/feature/toolkit-variables.scss';
@import 'variables/feature/statement-navigator-variables.scss';
@import 'variables/feature/batch-panel-variables.scss';
@import 'variables/feature/statement-list-variables.scss';
@import 'variables/feature/statement-summary-variables.scss';
@import 'variables/feature/disclosure-analytics-header-variables.scss';
@import 'variables/pages/home-page-variables.scss';
@import 'variables/feature/project-creation-edit-variables.scss';
@import 'variables/feature/copy-statement-modal-variables.scss';

//MIXINS
@import 'mixins/breakpoints-mixin.scss';
@import 'mixins/colors-mixin.scss';
@import 'mixins/fonts-mixin.scss';
@import 'mixins/third-party/hover-css-mixins.scss';
@import 'mixins/common/icon-mixins.scss';
@import 'mixins/common/button-component-mixins.scss';
@import 'mixins/common/icon-button-mixins.scss';
@import 'mixins/common/close-button-mixins.scss';
@import 'mixins/common/banner-message-mixins.scss';
@import 'mixins/common/radio-component-mixins.scss';
@import 'mixins/common/scroll-bar-mixins.scss';
@import 'mixins/common/ellipsis-mixins.scss';
@import 'mixins/common/back-button-mixins.scss';
@import 'mixins/common/circle-up-element-mixin.scss';
@import 'mixins/common/side-by-side-view-selected-element-mixin.scss';
@import 'mixins/common/hover-padding-mixins.scss';
@import 'mixins/common/flyout-component-mixins.scss';

// ANIMATIONS
@import 'animations/common/flyout-component-animation.scss';

//THIRD PARTY
@import 'third-party/react-toggle.scss';
@import 'third-party/react-toastify.scss';
@import '~react-dates/lib/css/_datepicker.css';

//GLOBAL STYLES
@import 'base-styles.scss';
@import 'page-styles.scss';

//COMPONENTS
@import 'components/common/modal-component.scss';
@import 'components/common/slide-toggle-component.scss';
@import 'components/common/button-component.scss';
@import 'components/common/form-item-component.scss';
@import 'components/common/text-area-component.scss';
@import 'components/common/dynamic-text-area-component.scss';
@import 'components/common/text-input-component.scss';
@import 'components/common/dropdown-component.scss';
@import 'components/common/checkbox-component.scss';
@import 'components/common/checkbox-list-component.scss';
@import 'components/common/tool-tip-component.scss';
@import 'components/common/message-component.scss';
@import 'components/common/banner-component.scss';
@import 'components/common/data-grid/data-grid-component.scss';
@import 'components/common/data-grid/data-grid-header-component.scss';
@import 'components/common/data-grid/data-grid-filter-component.scss';
@import 'components/common/date-range-component.scss';
@import 'components/common/date-picker-component.scss';
@import 'components/common/date-time-picker-component.scss';
@import 'components/common/radio-component.scss';
@import 'components/common/search-component.scss';
@import 'components/common/menu-component.scss';
@import 'components/common/global-header-component.scss';
@import 'components/common/zoom-control-component.scss';
@import 'components/common/batch-select-control-component.scss';
@import 'components/common/annotation-display-filter-component.scss';
@import 'components/common/pagination-component.scss';
@import 'components/common/loading-component.scss';
@import 'components/common/card-component.scss';
@import 'components/common/progress-bar-step-component.scss';
@import 'components/common/tabs-group-component.scss';
@import 'components/common/omnia/flyout-component.scss';
@import 'components/common/expandable-row-component.scss';
@import 'components/common/element-amount-attributes-details-component.scss';
@import 'components/common/element-annotation-details-component.scss';
@import 'components/common/text-editor-component.scss';
@import 'components/common/icon-button-componenent.scss';
@import 'components/common/treeview-component.scss';
@import 'components/common/chip-component.scss';
@import 'components/common/footer-component.scss';
@import 'components/common/virtualized-list-component.scss';
@import 'components/common/element-details-component.scss';
@import 'components/common/element-status-based-color';
@import 'constants/feature/dateComponent/customDatePicker.scss';
@import 'components/common/automatically-identified-annotations.scss';
@import 'components/common/icon-dropdown.scss';
@import 'components/common/thumbnail.scss';
@import 'components/feature/statement-creation/recent-statement-content-updates-modal.scss';
//FEATURE

@import 'components/feature/project-details/project-details-component.scss';
@import 'components/feature/project-details/_project-details-deleted-overlay-component';
@import 'components/feature/project-details/_project-details-user-grid.scss';
@import 'components/feature/project-details/_project-details-no-projects-component';
@import 'components/feature/project-details/_project-details-expanded-users-component';
@import 'components/feature/project-list/project-list-component.scss';
@import 'components/feature/project-list/_project-list-card-component.scss';
@import 'components/feature/project-list/_project-list-header-component.scss';
@import 'components/feature/project-creation/project-info/project-creation-project-info-component.scss';
@import 'components/feature/project-creation/project-info/_project-creation-project-info-non-audit-text-input.scss';
@import 'components/feature/project-creation/manage-users/project-creation-manage-users-component.scss';
@import 'components/feature/project-creation/manage-users/learn-more-about-roles-modal.scss';
@import 'components/feature/project-creation/manage-users/support-team-access-disclaimer-modal.scss';
@import 'components/feature/project-edit/manage-coe/coe-team-access-view-disclaimer.scss';
@import 'components/feature/project-edit/project-info/project-edit-project-info-component.scss';
@import 'components/feature/project-edit/manage-users/project-edit-manage-users-component.scss';
@import 'components/feature/project-edit/manage-users/tech-support-access-acknowledge-disclaimer-modal.scss';
@import 'components/feature/project-edit/manage-coe/project-edit-manage-coe-access.scss';
@import 'components/feature/project-edit/legal-hold/project-edit-manage-legal-hold-component.scss';
@import 'components/feature/project-edit/section-menu/project-edit-section-menu-component.scss';
@import 'components/feature/statement-list/statement-list-component.scss';
@import 'components/feature/statement-list/revision-history-modal.scss';
@import 'components/feature/statement-list/statement-list-progress-bar-component.scss';
@import 'components/feature/statement-list/statement-list-add-revision-modal.scss';
@import 'components/feature/statement-list/soft-delete-revision-modal.scss';
@import 'components/feature/statement-list/permanently-delete-revisions-modal.scss';
@import 'components/feature/statement-list/submit-statement-for-wrap-up-approval-modal.scss';
@import 'components/feature/statement-list/copy-statement-modal';
@import 'components/feature/statement-list/statement-wrap-up-stats-component';
@import 'components/feature/statement-list/statement-wrap-up-approval-modal-1.scss';
@import 'components/feature/statement-list/statement-wrap-up-approval-modal-2.scss';
@import 'components/feature/statement-processing/statement-processing-component.scss';
@import 'components/feature/statement-summary/statement-summary-component.scss';
@import 'components/feature/statement-summary/statement-summary-display-options-dropdown.scss';
@import 'components/feature/statement-summary/statement-summary-elements-component.scss';
@import 'components/feature/statement-summary/statement-summary-notes-component.scss';
@import 'components/feature/statement-summary/statement-summary-sections-component.scss';
@import 'components/feature/statement-summary/statement-summary-flagged-component.scss';
@import 'components/feature/statement-summary/statement-summary-elements-total-component.scss';
@import 'components/feature/statement-creation/select-statement/statement-creation-select-statement-component.scss';
@import 'components/feature/statement-creation/statement-creation-statement-info-component.scss';
@import 'components/feature/statement-creation/statement-creation-additional-consideration-modal.scss';
@import 'components/feature/statement-creation/carry-forward/statement-creation-carry-forward-component.scss';
@import 'components/feature/statement-creation/carry-forward/statement-creation-carryforward-info-component.scss';
@import 'components/feature/statement-content-panel/section-content-component.scss';
@import 'components/feature/statement-content-panel/section-anchor-component.scss';
@import 'components/feature/statement-content-panel/statement-content-panel-component.scss';
@import 'components/feature/statement-content-panel/statement-context-menu-component.scss';
@import 'components/feature/statement-content-panel/statement-content-panel-segment-component.scss';
@import 'components/feature/statement-content-panel/export-workpaper-modal.scss';
@import 'components/feature/statement-content-panel/tieout-element-component.scss';
@import 'components/feature/statement-content-panel/preview-report-selectedable-element-component.scss';
@import 'components/feature/statement-content-panel/note-anchor-component.scss';
@import 'components/feature/statement-content-panel/statement-content-panel-component-ocr.scss';
@import 'components/feature/statement-content-panel/delete-confirmation-modal.scss';
@import 'components/feature/statement-content-panel/section-content-overlay-element-map.scss';
@import 'components/feature/statement-content-panel/blackline-view-changes-ocr.scss';
@import 'components/feature/statement-content-panel/note-details-ocr.scss';
@import 'components/feature/toolkit/toolkit-component.scss';
@import 'components/feature/toolkit/toolkit-downloads-card-component.scss';
@import 'components/feature/toolkit/toolkit-downloads-list-component.scss';
@import 'components/feature/toolkit/toolkit-export-panel-component.scss';
@import 'components/feature/toolkit/toolkit-chatbot-panel-component.scss';
@import 'components/feature/toolkit/toolkit-chatbot-response-feedback-modal.scss';
@import 'components/feature/toolkit/toolkit-audit-checklist-component.scss';
@import 'components/feature/toolkit/toolkit-audit-checklist-element.scss';
@import 'components/feature/toolkit/toolkit-audit-checklist-card.scss';
@import 'components/feature/toolkit/comfort-annotation-panel-component.scss';
@import 'components/feature/toolkit/toolkit-workpaper-panel-component.scss';
@import 'components/feature/toolkit/toolkit-workpaper-element-component.scss';
@import 'components/feature/toolkit/toolkit-edit-workpaper-modal-component.scss';
@import 'components/feature/toolkit/statement-nav/statement-navigator-panel-component.scss';
@import 'components/feature/toolkit/statement-nav/table-of-contents.scss';
@import 'components/feature/toolkit/statement-nav/statement-nav-content-search-results-component.scss';
@import 'components/feature/toolkit/statement-nav/statement-nav-elements-search-results-component.scss';
@import 'components/feature/toolkit/statement-nav/statement-nav-elements-search-entry-component.scss';
@import 'components/feature/toolkit/statement-nav/statement-nav-element-filters-modal.scss';
@import 'components/feature/toolkit/statement-nav/statement-nav-element-filters-tertiary-wpr-component.scss';
@import 'components/feature/toolkit/statement-nav/statement-nav-element-filters-tertiary-tickmarks-component.scss';
@import 'components/feature/toolkit/statement-nav/statement-nav-element-filters-tertiary-comfort-annotations-component.scss';
@import 'components/feature/toolkit/statement-nav/statement-nav-add-navigation-modal.scss';
@import 'components/feature/toolkit/statement-nav/statement-nav-bulk-assignment/statement-nav-bulk-select-sections';
@import 'components/feature/toolkit/statement-nav/statement-nav-bulk-assignment/statement-nav-bulk-select-users';
@import 'components/feature/toolkit/statement-nav/statement-nav-bulk-assignment/statement-nav-bulk-assignment-modal-component.scss';
@import 'components/feature/toolkit/statement-nav/statement-nav-element-filters-chips-component.scss';
@import 'components/feature/statement-edit/statement-edit-statement-info-component.scss';
@import 'components/feature/element-panel/element-panel-component.scss';
@import 'components/feature/element-panel/element-status-component.scss';
@import 'components/feature/element-panel/element-attributes-component.scss';
@import 'components/feature/element-panel/element-history-component.scss';
@import 'components/feature/element-panel/_element-panel-header-component';
@import 'components/feature/element-panel/confirm-override-system-flag-modal-component';
@import 'components/feature/element-panel/annotations/_element-panel-annotations-component.scss';
@import 'components/feature/element-panel/annotations/element-panel-annotations-tab-component';
@import 'components/feature/element-panel/annotations/element-panel-formula-list-annotation-component';
@import 'components/feature/element-panel/annotations/element-panel-comfort-letter-list-annotation-component';
@import 'components/feature/element-panel/annotations/element-panel-internal-reference-list-annotation-component';
@import 'components/feature/element-panel/annotations/formula-entry-component';
@import 'components/feature//element-panel//annotations/internal-reference-element-entry-component';
@import 'components/feature/element-panel/annotations/workpaper-entry-component';
@import 'components/feature/element-panel/annotations/tickmark-entry-component.scss';
@import 'components/feature/element-panel/annotations/comfort-letter-entry-component.scss';
@import 'components/feature/element-panel/annotations/element-panel-workpaper-list-annotation-component';
@import 'components/feature/element-panel/annotations/element-panel-tickmark-list-annotation-component';
@import 'components/feature/element-panel/formula-panel/formula-panel-component.scss';
@import 'components/feature/element-panel/formula-panel/formula-panel-header-component';
@import 'components/feature/element-panel/formula-panel/formula-panel-content-component';
@import 'components/feature/element-panel/formula-panel/_formula-panel-edit-formula-component';
@import 'components/feature/element-panel/formula-panel/formula-operator-component.scss';
@import 'components/feature/element-panel/formula-panel/_formula-row-manual-input-component';
@import 'components/feature/element-panel/formula-panel/_formula-row-element-component';
@import 'components/feature/element-panel/formula-panel/_formula-panel-round-number-modal-component';
@import 'components/feature/element-panel/internal-reference-panel/internal-reference-panel-header-component';
@import 'components/feature/element-panel/internal-reference-panel/internal-reference-panel-content-component';
@import 'components/feature/element-panel/internal-reference-panel/_copy-annotation-linked-elements-modal-component';
@import 'components/feature/element-panel/internal-reference-panel/internal-references-panel-suggested-references-component';
@import 'components/feature/element-panel/internal-reference-panel/internal-references-panel-listing';
@import 'components/feature/element-panel/workpaper-panel/workpaper-panel-component.scss';
@import 'components/feature/element-panel/workpaper-panel/create-work-paper-modal.scss';
@import 'components/feature/element-panel/comfort-letter-panel/create-comfort-letter-modal.scss';
@import 'components/feature/element-panel/comfort-letter-panel/element-comfort-panel-search-results-component';
@import 'components/feature/element-panel/comfort-letter-panel/comfort-letter-panel-attach-component';
@import 'components/feature/element-panel/workpaper-panel/workpaper-panel-attach-component.scss';
@import 'components/feature/element-panel/workpaper-panel/workpaper-panel-attach-component';
@import 'components/feature/element-panel/workpaper-panel/workpaper-panel-already-linked-component.scss';
@import 'components/feature/element-panel/tickmark-panel/element-tickmark-panel-component.scss';
@import 'components/feature/element-panel/tickmark-panel/element-tickmark-panel-already-attached-component';
@import 'components/feature/element-panel/tickmark-panel/element-tickmark-panel-attach-component';
@import 'components/feature/element-panel/tickmark-panel/element-tickmark-panel-search-results-component';
@import 'components/feature/element-panel/tickmark-panel/create-tickmark-modal.scss';
@import 'components/feature/batch-panel/batch-panel-component.scss';
@import 'components/feature/batch-panel/batch-panel-exclude-elements-confirmation-modal';
@import 'components/feature/batch-panel/batch-panel-tickmark-section';
@import 'components/feature/batch-panel/batch-panel-workpaper-section';
@import 'components/feature/batch-panel/_batch-panel-selected-elements-component';
@import 'components/feature/toolkit/notes-panel/notes-panel-component.scss';
@import 'components/feature/toolkit/notes-panel/notes-panel-input-area-component.scss';
@import 'components/feature/toolkit/notes-panel/notes-panel-edit-input-area-component.scss';
@import 'components/feature/toolkit/notes-panel/notes-panel-notes-entry-component';
@import 'components/feature/toolkit/notes-panel/notes-panel-notes-list-component';
@import 'components/feature/toolkit/notes-panel/notes-panel-notes-additional-details-component';
@import 'components/feature/toolkit/notes-panel/notes-panel-header-component';
@import 'components/feature/toolkit/notes-panel/notes-panel-note-summary-component';
@import 'components/feature/toolkit/notes-panel/notes-panel-advanced-filter-modal-component';
@import 'components/feature/toolkit/notes-panel/notes-panel-user-filter-list-component';
@import 'components/feature/toolkit/notes-panel/notes-panel-filter-chips-component';
@import 'components/feature/toolkit/notes-panel/notes-panel-statement-segment-filter-component';
@import 'components/feature/toolkit/notes-panel/notes-textarea-component';
@import 'components/feature/toolkit/tickmark-panel/tickmark-panel-component.scss';
@import 'components/feature/toolkit/compare-panel-component.scss';
@import 'components/feature/toolkit/tickmark-panel/tickmark-panel-tickmark-list-component';
@import 'components/feature/toolkit/_toolkit-comfort-annotation-panel-comfort-annotation-list.scss';
@import 'components/feature/omnia/omnia-engagements-confirmation-modal';
@import 'components/feature/omnia/omnia-linking-confirmation-modal';
@import 'components/feature/omnia/omnia-engagement-list-component';
@import 'components/feature/omnia/omnia-share-confirmation-modal';
@import 'components/feature/disclosure-analytics/disclosure-analytics-header-component';
@import 'components/feature/disclosure-analytics/user-settings-modal.scss';
@import 'components/feature/section-details-panel/section-details-panel-component.scss';
@import 'components/feature/section-details-panel/section-details-history-element-component.scss';
@import 'components/feature/active-users/active-users-list-component.scss';
@import 'components/feature/section-details-panel/section-details-section-assignment-modal-component';
@import 'components/feature/toolkit/statement-nav/customized-report/toolkit-export-custom-report-content-component.scss';
@import 'components/feature/toolkit/statement-nav/customized-report/toolkit-export-customized-report-component.scss';
@import 'components/feature/home-page/recent-updates-modal.scss';
@import 'components/feature/home-page/maintenance-banner.scss';
@import 'components/feature/statement-summary/statement-summary-annotations-component.scss';
@import 'components/feature/statement-summary/statement-annotations-update.scss';
@import 'components/feature/statement-summary/statement-summary-elements-display.scss';

//PAGES
@import 'pages/home-page-styles.scss';
@import 'pages/project-creation-page-styles.scss';
@import 'pages/project-edit-page-styles.scss';
@import 'pages/statement-creation-page-styles.scss';
@import 'pages/statement-content-page-styles.scss';
@import 'pages/side-by-side-compare/side-by-side-view-styles.scss';
@import 'pages/side-by-side-compare/left-side/select-statement-tab-styles.scss';
@import 'pages/side-by-side-compare/left-side/left-side-view-styles.scss';
@import 'pages/side-by-side-compare/left-side/left-side-statement-view-styles.scss';
@import 'pages/side-by-side-compare/left-side/right-side-view-styles.scss';
@import 'pages/side-by-side-compare/side-by-side-toolkits/side-by-side-statement-nav/side-by-side-statement-nav-flyout.scss';
@import 'pages/side-by-side-compare/left-side/select-revision-tab-styles';
@import 'pages/side-by-side-compare/side-by-side-dropdown-styles.scss';
@import 'pages/side-by-side-compare/annotation-map-notification-styles.scss';
@import 'pages/statement-edit-page-styles.scss';
@import 'pages/omnia-engagement-page-component.scss';
@import 'pages/session-timeout-page.scss';
@import 'pages/logout-page-styles.scss';
@import 'pages/admin-module/admin-module-page-styles.scss';
@import 'pages/admin-module/tab-admin-module-manage-users-styles';
@import 'pages/admin-module/tab-monitoring-styles';
@import 'pages/admin-module/tab-admin-module-maintenance-banner-styles';
@import 'pages/admin-module/tab-admin-summary-of-key-changes-styles';
@import 'pages/admin-module/manage-users-support-id-modal';
@import 'pages/report-preview/report-preview-styles.scss';
@import 'pages/report-preview/report-preview-right-side-view-styles.scss';
@import 'pages/report-preview/report-preview-left-side-view-styles.scss';
@import 'pages/report-preview/report-preview-page-display-options.scss';
@import 'pages/blackline-version/blackline-version-component.scss';
@import 'pages/blackline-version/blackline-previous-version-component.scss';
@import 'pages/blackline-version//blackline-current-version-component.scss';
@import 'pages/blackline-version//blackline-version-subheader-component.scss';

//UTIL
@import 'components/util/api-error-notification-component.scss';
@import 'components/util/copy-formula-banner-component.scss';
