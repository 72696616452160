.internal-reference-panel-content {
  &__selected-value-container {
    display: flex;
    justify-content: space-between;
    padding: $gutter-md;
  }

  &__element-details-container {
    color: $blue-1;
    font-size: $font-size-sm;
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  &__display_container {
    display: flex;
  }
  &__selected-value {
    font-size: $font-size-sm;
    color: $grey-4;
    font-weight: $font-w-semi-bold;
  }

  &__element-value {
    @include overflow-ellipsis;
    display: block;
    font-size: $font-size-ml;
    color: $primary-blue;
    font-weight: $font-w-semi-bold;
    text-align: left;
    &:hover {
      cursor: pointer;
    }
  }

  &__internal-refrence-number {
    font-size: $font-size-ml;
    margin-left: $gutter-md;
    font-weight: $font-w-semi-bold;
    color: rgba(0, 0, 0, 0.87);
  }

  &__unlink-button-container {
    display: flex;
    align-items: center;
    max-width: 25%;
  }

  &__unlink {
    margin-left: auto;
    cursor: pointer;
    @include remove-default-button-styling;

    &:focus svg,
    &:hover svg {
      fill: $primary-blue;
      .icon-path {
        fill: $white;
      }
    }

    &:active svg {
      fill: $active-blue;
      .icon-path {
        fill: $white;
      }
    }
  }
}
