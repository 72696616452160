.create-comfort-letter-modal {
  &__custom-label{
      margin-top: $gutter-md;
      ::placeholder{
        font-size: $font-size-default;
        color: #b1b1b1;
        font-family: "Open Sans", Verdana, Helvetica, sans-serif
      }
      ::-webkit-input-placeholder { /*For Edge */
        font-size: $font-size-default;
        color: #b1b1b1;
        font-family: "Open Sans", Verdana, Helvetica, sans-serif
      }
  }
}