.button {
  padding: $gutter-sm $gutter-md;
  border-radius: $border-radius-default;
  font-weight: $font-w-semi-bold;
  outline: none;
  display: flex;
  align-items: center;
  cursor: pointer;
  border: none;

  &__primary {
    background-color: $primary-blue;
    color: $white;
    &:focus,
    &:hover {
      background-color: $hover-blue;
    }
    &:active {
      background-color: $active-blue;
    }
    &[disabled] {
      color: $disabled-buttton-text-grey;
      background-color: $disabled-button-background-grey;
      cursor: not-allowed;
    }
  }

  &__secondary {
    padding: $gutter-sm - 1px $gutter-md - 1px;
    background-color: $white;
    border: $button-border-size solid $primary-blue;
    color: $primary-blue;

    &:focus,
    &:hover {
      background-color: $hover-grey;
    }

    &:active {
      background-color: $active-grey;
    }

    &[disabled] {
      border: $button-border-size solid $disabled-button-border-grey;
      background-color: $disabled-button-background-grey;
      color: $disabled-buttton-text-grey;
      cursor: not-allowed;
    }
  }

  &__tertiary {
    background-color: transparent;
    color: $primary-blue;
    border: none;
    &:focus,
    &:hover {
      background-color: $hover-grey;
    }
    &:active {
      background-color: $active-grey;
    }
    &[disabled] {
      background-color: $disabled-button-background-grey;
      color: $disabled-buttton-text-grey;
      cursor: not-allowed;
    }
  }

  &__icon {
    display: flex;
    background-color: transparent;
    color: $primary-blue;
    border: none;

    &__icon {
      margin-right: $gutter-xs;
      fill: $primary-blue;
      .icon-path {
        fill: $primary-blue;
      }
      &--disabled {
        fill: $disabled-buttton-text-grey !important;
        .icon-path {
          fill: $disabled-buttton-text-grey !important;
        }
      }
    }

    &:focus,
    &:hover {
      background-color: $hover-grey;
      &__icon {
        fill: $primary-blue;
        .icon-path {
          fill: $primary-blue;
        }
      }
    }
    &:active {
      background-color: $active-grey;
      &__icon {
        fill: $primary-blue;
        .icon-path {
          fill: $primary-blue;
        }
      }
    }
    &[disabled] {
      background-color: transparent;
      color: $disabled-buttton-text-grey;
      cursor: not-allowed;
    }
  }

  &__delete {
    background-color: $primary-red;
    color: $white;
    &:focus,
    &:hover {
      background-color: $hover-red;
    }
    &:active {
      background-color: $active-red;
    }
    &[disabled] {
      color: $disabled-buttton-text-red;
      background-color: $disabled-button-background-red;
      cursor: not-allowed;
    }
  }
}
