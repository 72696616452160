.statement-processing {
  width: 339px;
  background-color: $white;
  border-radius: $border-radius-default;
  border: solid 1px $statment-processing-border-grey;
  z-index: $z-index-statement-processing-pop-over;
  position: fixed;
  bottom: $gutter-ml;
  right: $gutter-ml;
  box-shadow: $box-shadow-3;

  &__header {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    padding: $default-gutter $gutter-md $gutter-md $gutter-md;
    border-bottom: solid 1px $statment-processing-border-grey;
    cursor: pointer;
  }

  &__title {
    font-weight: $font-w-semi-bold;
  }

  &__toggle {
    border-radius: 50%;
    background-color: $white;
    cursor: pointer;

    &:hover,
    &:focus {
      background-color: $hover-grey;
    }

    &:active {
      background-color: $active-grey;
    }
  }

  &__body {
    display: flex;
    flex-direction: column;
    padding: $gutter-md;
    max-height: $statement-processing-body-max-height;
    overflow-y: scroll;
    @include scroll-bar();
  }

  &__row {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
    margin-bottom: $gutter-md;
  }

  &__col {
    &--right {
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      width: 15%;
    }
    &--left {
      width: 75%;
      overflow-wrap: break-word;
    }
  }

  &__step {
    font-size: $font-size-sm;
    color: $label-grey;
    margin-bottom: $gutter-xs;
    &--failed {
      color: $error-red;
    }
  }

  &__statement {
    font-size: $font-size-md;
    &--failed {
      color: $error-red;
    }
  }

  &__info{
    font-size: $font-size-sm;
    color: $grey-4;
    margin-bottom: $gutter-xs;
  }

  &__cancel {
    border-radius: 50%;
    background-color: $white;
    margin-left: auto;
    cursor: pointer;

    &:hover,
    &:focus {
      background-color: $hover-grey;
    }

    &:active {
      background-color: $active-grey;
    }
  }
  &__refresh {
    border-radius: 50%;
    background-color: $white;
    cursor: pointer;
    margin-left: auto;

    &:hover,
    &:focus {
      background-color: $hover-grey;
    }

    &:active {
      background-color: $active-grey;
    }
  }

  &__statement-count {
    padding: $gutter-xs $gutter-md $gutter-xs $gutter-md;
    font-size: $font-size-md;

    &--processing {
      margin-bottom: $gutter-xxs;
    }
    &--failed {
      margin-bottom: $gutter-xxs;
      color: $error-red;
    }
  }

  &__kebab {
    position: absolute;
    & .statement-processing__kebab-menu {
      width: 10rem;
      right: $gutter-md;
    }
  }
}
