.date-picker {
  &__close-button {
    @include close-button();
    vertical-align: top;
  }

  &__calendar {
    vertical-align: top;
  }

  &--invalid {
    .SingleDatePickerInput__withBorder {
      border: solid 2px $error-red;
      border-radius: $border-radius-default;
      background-color: $white;
      color: $default-input-text-grey;
      margin: -1px;
      .calendar-icon-svg {
        fill: $error-red;
      }
    }
  }

  &[disabled] {
    .SingleDatePickerInput__withBorder {
      border: dashed 1px $disabled-grey;
      color: $disabled-text;
      border-radius: $border-radius-default;
      margin: -1px;
      .calendar-icon-svg {
        fill: $disabled-text;
      }
    }
  }
}

.SingleDatePicker {
  width: 100%;
}

.SingleDatePickerInput__withBorder {
  border-radius: $border-radius-default;
  border: solid 1px $border-grey;
  background-color: $white;
  color: $default-input-text-grey;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;

  &:enabled {
    color: $black;
  }

  &:focus,
  &:hover {
    border: $hover-focus-border;
    margin: -1px;
    .calendar-icon-svg {
      fill: $hover-blue;
    }
  }
}
