.side-by-side-dropdown{
    &__dropdown-input{
        width: 300px;
        height: 40px;
        display: flex;
        margin: 0 15px 0 0;
        align-items: center;
        cursor: pointer;
        border: $expandable-row-border;
    }

    &__setting-icon{
        display: flex;
        align-items: center;    
        width: 40px;
        padding: 0 5px 0 10px;
    }

    &__selected-items{
        width: 225px;
        font-size: $font-size-default;
        padding: 0 5px;
        @include overflow-ellipsis;

    }

    &__carret-icon{
        display: flex;
    }

    &__dropdown-options{
        width: 300px;
        position: absolute;
        top: 144px;
        background-color: #ffffff;
        border-bottom: $expandable-row-border;
        box-shadow: 0 0 4px 0 $border-grey;
        z-index: 100;
        padding: 10px;
    }

    &__sub-description{
        font-size: $font-size-sm;
    }

    &__option{
       display: flex;
       justify-content: space-between;
       align-items: center;
       font-size: $font-size-default;
       padding: 5px 0;
    }
}