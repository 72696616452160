.workpaper-toolkit-element {
  border-bottom: $border-gray-light;
  padding: $gutter-md;
  font-size: $font-size-md;

  &__element {
    &--selected {
      background-color: $grey-2;
    }
  }

  &__top {
    margin-bottom: $gutter-xs;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__automatic-workpaper{
    display: flex;
  }

  &__workpaper-reference-name {
    margin-bottom: $gutter-xs;
  }

  &__workpaper-ref-number {
    font-weight: $font-w-semi-bold;
  }

  &__element-count-container {
    border: $border-gray-light;
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  &__element-count-title {
    display: flex;
    align-self: flex-start;
  }
  &__element-count-elements {
    width: 100%;
  }
  &__element-count-elements_expanded {
      height: 50vh;
      width: 100%;
  }

  &__button-container {
    display: flex;
    align-items: center;
  }

  &__delete-icon {
    @include icon-button-with-one-path;
  }

  &__edit-icon {
    @include icon-button-with-one-path;
    margin-right: $gutter-xs;
  }

  &__caret-icon {
    fill: black;
    margin-left: $gutter-md;

    &--expanded {
      transform: rotate(90deg);
    }
  }

  &__element-count {
    padding: $gutter-xs;
  }

  &__pagination-button-container {
    display: flex;
    padding-bottom: $gutter-sm;
  }

  &__pagination-button {
    width: 90px;
    height: 28px;
    border-radius: $border-radius-default;
    font-weight: $font-w-semi-bold;
    outline: none;
    cursor: pointer;
    border: none;

    &--next {
      margin-bottom: $gutter-sm;
      background-color: transparent;
      color: $primary-blue;
      border: none;
      &:focus,
      &:hover {
        background-color: $hover-grey;
      }
      &:active {
        background-color: $active-grey;
      }
      &[disabled] {
        background-color: $disabled-button-background-grey;
        color: $disabled-buttton-text-grey;
        cursor: not-allowed;
      }
    }
  }
}
