.element-tickmark-panel {
  &__title {
    margin-left: $gutter-ml;
    margin-bottom: $gutter-md;
  }

  &__selected-value {
    font-size: $font-size-sm;
    color: $grey-4;
    font-weight: $font-w-semi-bold;
  }

  &__element-label-tooltip {
    width: $side-panel-tooltip-width;
  }

  &__element-value {
    @include hyperlink-button-for-element;
    @include overflow-ellipsis;
    display: block;
    font-size: $font-size-ml;
    color: $primary-blue;
    font-weight: $font-w-semi-bold;
    text-align: left;
  }

  &__amount-attributes {
    margin-top: $gutter-xs;
  }

  &__flyout-content {
    padding-bottom: 150px; // allows for the search menu to have enough room to display a few rows
  }
}
