.note-details-ocr {
    &__hover-effect {
        background-color: rgba(255, 205, 0, 0.5) !important;
    }

    &--container {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        cursor: pointer;
        z-index: 40;
    }

    &--note-icon {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: -1px;
        z-index: 49;
        border-radius: 0px 5px 5px 0;

        &:hover {
            cursor: pointer;
        }
    }
}