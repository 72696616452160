$black: #000000;
$blackTransparent: rgba(0, 0, 0, 0.12); // black with alpha
$white: #ffffff;
$grey: #fafafa;
$red: #da291c;
$orange: #ed8b00;
$pale-orange: #fef4e6;
$yellow: #ffcd00;
$lightgreen: #90ee90;

$blue-1: #00a3e0;
$blue-2: #008fc4;
$blue-3: #007caa;
$blue-4: #ebf8fd; //information blue.
$blue-5: #addef3;
$blue-6: #ebf8fd;
$blue-7: #e0eef4; //selected blue
$blue-8: #a0dcff;
$blue-9: #0076a8;
$blue-10: #012169; //Extra Dark Blue
$blue-11: #00008b; //Dark Blue
$lightskyblue:#87cefa;

$grey-0: #f0f0f0;
$grey-1: #f1f1f1;
$grey-2: #e0e0e0;
$grey-3: #c9c9c9;
$grey-4: #757575;
$grey-5: #a3a3a3;
$grey-6: #53565a;
$grey-7: #63666a;
$grey-8: #f9f9f9;
$grey-9: #0000004d;
$grey-10: #00000066;
$grey-11: #00000026;

$teal-1: #007680;
$teal-2: #ebf7f9;

$green-1: #86bc25;
$green-2: #43b02a;
$green-3: #f0f9ee;
$green-4: #009a44;
$green-5: #d5ece0;
$green-6: #d8dd40;

$yellow-1: #fffbeb;
$yellow-2: #ffff9d;
$yellow-3: #ffff3b;
$yellow-4: #fff40073;
$yellow-5: #ffcd0073;

$element-status-unverified: #ffcd003d;
$element-status-verified: #00a3e03d;
$element-status-reviewed: #43b02a3d;

$blackline-added-color: #68bc25;
$blackline-changed-color: #ed8b00;
$blackline-deleted-color: #f9ddda99;

$black-1: #212121;

$red-1: #feecea;
$blackA24: rgba(0, 0, 0, 0.24);
$red-2: #c02418;
$red-3: #a71c07;
$red-4: #f7f7f7;
$primary-red: $red;
$hover-red: $red-2;
$active-red: $red-3;
$disabled-button-background-red: $red-4;
$disabled-buttton-text-red: $blackA24;

$page-background-grey: $grey;

$disabled-font-grey: $grey-3;

$primary-blue: $blue-1;
$hover-blue: $blue-2;
$active-blue: $blue-3;

$statement-progress-blue: $blue-9;
$calendar-day-span-blue: $blue-7;

$disabled-buttton-text-grey: $disabled-font-grey;
$disabled-button-background-grey: $grey-1;
$disabled-button-border-grey: $disabled-font-grey;
$disabled-button-text-black: $black;

$radio-button-hover-outline-blue: $blue-8;
$radio-unchecked-grey: $grey-4;
$radio-disabled-grey: $grey-3;
$radio-label-disabled-grey: $disabled-font-grey;
$checkbox-hover-outline-blue: $blue-8;
$checkbox-unchecked: $grey-4;
$checkbox-disabled-grey: $grey-3;
$checkbox-label-disabled-grey: $disabled-font-grey;

$hover-grey: $grey-1;
$hover-grey-legacy: $grey-11;
$active-grey: $grey-2;

$hover-grey-new: $grey-9;
$active-grey-new: $grey-10;

$border-grey: $grey-3;
$label-grey: $grey-4;
$secondary-label-grey: $grey-6;
$disabled-text: $grey-5;
$disabled-grey: $grey-2;
$search-icon-grey: $grey-4;
$default-input-text-grey: $grey-5;
$project-list-border-grey: $grey-2;
$modal-border-grey: $grey-2;
$scroll-bar-track-grey: $grey-2;
$statment-processing-border-grey: $grey-2;

$scroll-bar-thumb-grey: $grey-4;
$project-card-deleted-grey: $grey-4;
$project-card-grey: $grey-1;
$project-card-client-name-grey: $grey-7;

$guidance-border-teal: $teal-1;
$guidance-background-teal: $teal-2;
$success-border-green: $green-2;
$success-background-green: $green-3;
$information-background-blue: $blue-4;
$information-border-blue: $blue-1;
$warning-background-yellow: $yellow-1;
$warning-border-yellow: $yellow;
$error-background-red: $red-1;
$error-border-red: $red;

$progress-bar-step-text-green: $green-4;
$progress-bar-step-background-green: $green-5;
$progress-bar-step-dashed-grey: $grey-4;

$selected-menu-option-blue: $blue-7;
$drag-drop-blue-overlay: $blue-7;
$transparent-blue: rgba(160, 220, 255, 0.25);

$toggle-green: $green-2;
$toggle-track: $grey-4;

$error-red: $red;

$footer-grey: #f8f8f8;
$footer-border: #ddd;

$side-by-side-element-border: #00bfff;
$side-by-side-identifier: #007cb0;
$side-by-side-element-selection: #a3cee080;
$side-by-side-map-success: #A56EFF73;
$side-by-side-map-failure: #ffa50073;
