// Overriding some of tostify's default styles

.Toastify__toast-container {
  right: 7px !important;
  top: 48px !important;
  width: 24% !important;

  .Toastify__toast {
    padding: 16px;

    &__no-hover {
      &:hover {
        cursor: default;
      }
    }

    &-body {
      width: 100%;
    }

    &-title {
      align-items: center;
      color: $black;
      display: flex;
      flex-direction: row;
      font-size: $font-size-lg;
      font-weight: $font-w-bold;
    }

    &-message {
      font-size: $font-size-md;
      margin-top: $gutter-sm;
    }

    &-children {
      margin-top: $gutter-sm;
    }

    &-text--truncated {
      @include overflow-ellipsis;
      display: block;
    }

    &--success {
      background-color: rgb(224, 236, 220);
      border: solid 1px $green-2;
      border-radius: 3px;
      color: $grey-4;
    }

    &--warning {
      background-color: #fffbeb;
      border-bottom: solid 2px #ffcd00;
      font-size: 0.75rem;
      color: $black;
    }

    &--error {
      background-color: #feecea;
      border: solid 1px #ec8a83;
      border-radius: 3px;
      color: black;
    }

    svg {
      height: 24px;
      margin-right: $gutter-sm;
      width: 24px;
    }
  }
}
