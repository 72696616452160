.edit-manage-users {
  display: flex;
  flex-wrap: nowrap;

  &__title {
    margin-bottom: $gutter-xs;
  }

  &__info {
    font-size: $font-size-md;
    display: flex;
    align-items: center;
  }

  &__search {
    margin-top: $gutter-md;
    width: 75%;
  }

  &__banner {
    margin-top: $gutter-md;
  }

  &__added-users {
    margin-top: $gutter-md;
    .data-grid__scrollable-wrapper {
      overflow: visible;
      overflow-y: visible;
    }
  }

  &__checkbox {
    margin-top: $gutter-ml;
  }
  
  &__tech-support-access-checkbox {
    margin-top: $gutter-ml;
    display: flex;
  }

  &__disclaimer-button {
    @include hyperlink-button;
    font-weight: $font-w-semi-bold;
    margin-left: $gutter-ml + 3;
  }
  &__tech-support-access-disclaimer-button{
    @include hyperlink-button;
    font-weight: $font-w-semi-bold;
    margin-left: $gutter-ml + 3;
  }
  &__learn-more-button {
    @include hyperlink-button;
    font-weight: $font-w-semi-bold;
    padding: $gutter-sm $gutter-xxs;
  }

  &__admin-enable-history {
    background: $blue-4;
    width: 75%;
    font-size: $font-size-md;
    font-weight: $font-w-semi-bold;
    padding: 0 0 $gutter-md $gutter-xl - 4;
    margin-top: -$gutter-xs;
  }

  &__admin-enable-history > div > span {
    color: $blue-9;
  }
  &__pencil-icon{
    margin: 10px;
    cursor: pointer;
}
}
