$square-dropdown-padding-override: $gutter-xxs;
.formula-operator {
  display: flex;
  align-items: center;
  margin-left: $formula-row-op-margin;
  width: $formula-operator-container-width;

  &__remove {
    display: block;
    margin-right: $gutter-xs;
    padding: $gutter-xxxs;
    border-radius: 50%;
    .icon-path {
      fill: $primary-blue;
    }
    &[disabled] {
      cursor: not-allowed;
      g {
        fill: none;
      }
      .icon-path {
        fill: $disabled-grey;
      }
      outline: none;
    }
    &:focus:not([disabled]),
    &:hover:not([disabled]) {
      background-color: $hover-blue;
      .icon-path {
        fill: $white;
      }
    }

    &:active:not([disabled]) {
      background-color: $active-blue;
      .icon-path {
        fill: $white;
      }
    }

    &--hidden {
      visibility: hidden;
    }
    &--show {
      visibility: visible;
      cursor: pointer;
    }
  }
  &__operator {
    width: auto !important;
    /** Overrides to make them square */
    & .dropdown {
      height: $formula-operator-dropdown-size;
      width: $formula-operator-dropdown-size !important;
    }
    & .dropdown__header {
      padding: $square-dropdown-padding-override;
      height: 100%;
      width: 100%;
      &:hover {
        @include adjust-padding-for-thicker-border-hover(
          $square-dropdown-padding-override
        );
      }
    }
    & .menu__option {
      height: $formula-operator-dropdown-size;
      display: flex;
      justify-content: center;
      align-items: center;
      span {
        padding: 0;
        height: auto;
      }
    }
  }
}
