.toolkit-download-list {
 &__refresh-list-button{
    display: flex;
    justify-content: center;
 }   
 &-refresh-list-icon{
    margin-top: $gutter-sm;
    .icon-path {
      fill: $primary-blue;
    }
 }
 &__report-cleared-msg{
   color: $grey-5;
   font-size: 12px;
 }
}