.icon-button {
  cursor: pointer;
  @include remove-default-button-styling;

  display: flex;
  align-items: center;
  justify-content: center;

  &:focus,
  &:hover {
    .icon-path-outer {
      fill: $primary-blue;
    }
    .icon-path-inner {
      fill: $white;
    }
  }

  &:active svg {
    fill: $active-blue;
    .icon-path-inner {
      fill: $white;
    }
  }
  &[disabled] {
    cursor: not-allowed;
    .icon-path-outer {
      stroke: $grey-3;
      fill: $grey-1;
    }
    .icon-path-inner {
      fill: $grey-3;
      stroke: $grey-3;
    }
  }
}
.icon-button-container {
  display: flex;
}