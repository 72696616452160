.automatically-identified-annotation {
  margin-right: 5px;

  &__automatically-identified-tooltip {
    height: 25px;
    margin-top: -2px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $grey-0;
    border-radius: 100px;
    padding: 1px 6px;
    color: $black;
    font-size: $font-size-sm;
    width: 25px;
    font-weight: 700;
  }
}
