.project-deleted-overlay {
  background: rgba($grey, 0.85);
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  position: absolute;
  min-width: $project-details-min-width;
  z-index: $z-index-overlay;

  &__message-container {
    position: fixed;
    top: 50%;
    left: 60%;
    transform: translate(-50%, -50%);
    max-width: 656px;
  }

  &__title {
    font-size: $font-size-xxl;
    margin-bottom: $gutter-ml;
  }

  &__body {
    font-size: $font-size-md;
    margin-bottom: $gutter-md;
  }
}
