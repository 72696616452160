.formula-entry {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: $gutter-md $gutter-md;
  &:hover {
    background-color: $hover-grey;
    text-decoration: underline;
    cursor: pointer;
  }
  &__result-container {
    display: flex;
    align-items: center;
    flex-direction: row;
    &--flagged {
      color: $orange;
      display: flex;
      align-items: center;
      flex-direction: row;
    }
    &--selected {
      background-color: $grey-2;
    }
  }
  &__prefix-icon {
    margin-right: $gutter-xxs;
    display: flex;
    align-items: center;
  }
  &__expression-container {
    margin-top: $gutter-xs;
  }
  &__copy-delete-button-container {
    display: flex;
  }
  &__delete-icon {
    flex-shrink: 0;
    margin-right: -15px;
    &:hover,
    &:focus,
    &:active {
      fill: $white;
      stroke: $hover-blue;
    }
  }

  &__copy-formula-icon {
    flex-shrink: 0;
    margin: 15px;
    &:hover,
    &:focus,
    &:active {
      fill: $white;
      stroke: $hover-blue;
    }
  }

  &[disabled] {
    cursor: not-allowed;
    color: $disabled-button-text-black;
    &:hover {
      background-color: transparent;
      text-decoration: none;
    }
  }

  &__container {
    display: flex;
  }
}
