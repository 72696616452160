.element-formula-list {
  &__button-container {
    padding: $gutter-md $gutter-md 0 $gutter-md;
    display: flex;
    margin-bottom: $gutter-md;
  }
  &__formula-new-button {
    margin-right: $gutter-xs;
  }
  &__listing-container {
    min-height: $element-panel-annotations-tab-content-min-height;
    margin-top: $gutter-md;
  }
  &__formula-dropdown {
    width: 95%;
    margin-bottom: 8px;
    margin-left: 8px;
  }
  &__formula-dropdown-menu {
    max-height: fit-content !important;
    overflow: auto;
  }
  &__no-formulas {
    padding: $gutter-md;
  }

}