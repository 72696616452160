@mixin icon-button-with-one-path {
  border-radius: 50%;
  
  &:focus {
    background-color: $primary-blue;
    & svg > path {
      fill: $white;
    }
  }
  &:hover {
    background-color: $primary-blue;
    & svg > path {
      fill: $white;
    }
  }

  &:active {
    background-color: $active-blue;
      & svg > path {
      fill: $white;
    }
  }
  &[disabled] {
    background-color: transparent;
    cursor: not-allowed;
    & svg > path {
      fill: $grey-1;
    }
  }
}