.timeout-page {
  text-align: center;
  padding: $gutter-lg;

  &__header {
    font-size: $gutter-lg;
    margin-bottom: $gutter-sm;
  }

  &__button {
    margin: $gutter-md auto;
  }
}
