.workpaper-entry {
  display: flex;
  margin-bottom: $gutter-sm;
  justify-content: space-between;

  &__info {
    display: flex;
  }
  &__ref-num {
    font-weight: $font-w-semi-bold;
    margin-bottom: $gutter-xxs;
  }
  &__ref-num,
  &__ref-name {
    display: block;
    font-size: $font-size-md;
    word-break: break-word;
    hyphens: auto;
  }
}
