.statement-content-segment {
  margin-bottom: $gutter-md;
  background-color: $white;

  & .new-element,
  .new-element a,
  .new-element button {
    background-color: #a6eca0 !important;
  }

  .changed-element,
  .changed-element a,
  .changed-element button {
    background-color: #f90 !important;
  }

  & .deleted-element,
  .deleted-element a,
  .deleted-element button {
    color: #c30 !important;
    text-decoration: line-through !important;
  }

  & td {
    & .deleted-element {
      display: block;
    }
  }
}
