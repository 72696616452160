#dateComponent{
  width:100%;
}

.selectedCalendar{
  width: 100%;
  height: 100%;
  position: relative;
}

/* Basic styling for the calendar container */
.calendar-container {
  width: 100%;
  top:0.75rem;
  position:absolute;
  z-index: 1;
  font-family: $font-family-default;
  background-color: #fff;
  padding: 5px;
  border: 2px solid #a1a6a782;
  border-radius: 2px;
  box-shadow:0 1px 4px 0 #c9c9c9;
}

.fitContentWidth{
  width: fit-content;
}

/* Styling for the calendar header */
.calendar-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: #fff;
  font-size: 18px;
}

/* Styling for the calendar header arrow buttons */
.calendar-header button {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 20px;
}

/* Styling for the weekdays row */
.calendar-weekdays {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  text-align: center;
  background-color: #fff;
  font-weight: bold;
  font-size: 16px;
}

/* Styling for the days of the month */
.calendar-days {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  text-align: center;
  padding: 5px;
  border: 1px solid #fff;
}

/* Styling for the individual day of the month */
.calendar-days .day {
  cursor: pointer;
  padding: 5px;
  &:hover {
    font-weight: bold;
    background-color: #eee;
    color: #bbb;
  }
}

/* Styling for the past days of the current month */
.calendar-days .pastDate {
  color: #cdcdcd !important;
  &:hover {
    background-color: #ebebeb;
    color: #cccccc !important;
  }
}

/* Styling for the days out side the range */
.calendar-days .dateRange {
  color: #cdcdcd !important;
  &:hover {
    background-color: #ebebeb;
    color: #cccccc !important;
  }
}

/* Styling for the current day */
.calendar-days .current-day {
  background-color: #008fc4;
  color: #fff;
  border-radius: 50%;
}

/* Styling for the days of the previous and next months */
.calendar-days .prev-month,
.calendar-days .next-month {
  color: #adadad;
}

/* Styling for the previous months past days */
.calendar-days .prev-month .pastDate{
  &:hover {
    background-color: #ebebeb;
    color: #cccccc !important;
  }
}

/* Styling for the selected date display */
.selected-date {
  text-align: center;
  margin-top: 10px;
  font-size: 16px;
}

/* Styling for the header text inside the calendar */
.calendar-month-year{
  font-family: $font-family-default;
  font-weight: bold;
  font-size: 16px;
}

/* Styling for the html input type as text input element */
.date-input{
  border: none;
  font-family: $font-family-default;
  padding: 8px;
  font-weight: 200;
  font-size: 16px;
  width: 100%;
  line-height: 24px;
  color: #484848;
  background-color: #fff;
  position:relative;
  vertical-align: middle;
  display: inline-block;
}

/* Styling for the entire date input or selector which includes the calendar icon, date input and clear icon */
.DatePicker{
  border-radius: 2px;
  border: solid 1px #c9c9c9;
  background-color: #ffffff;
  color: #a3a3a3;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  width:100%;
  
  &:hover {
    border: 2px solid #008fc4; 
    path {
      fill: #008fc4;
    }
    color: #008fc4;
  }

  &__closeButton{
    path {
      fill: #121212 !important;
    }
  }

  &__disabled{
    cursor:not-allowed;
    &:hover{
      border: 1px solid #c9c9c9;
      path {
        border: none;
        fill: #ccc;
      }
    }
  }
  &__errorMessage{
    border: 2px solid #da291c; 
    &:hover{
      border: 2px solid #008fc4; 
      path {
        border: none;
        fill: #008fc4;
      }
    }
  }
}


.dateInputFangShape{
  position: absolute;
  z-index: 2;
  left: 4rem;
  &__icon{
    fill: #a6a6a6;
    background-color: #fff;
  }
  
}

/* Styling for the arrow buttons in the calendar includes for both right and left arrows */
.arrowIcons{
  border: 2px solid #c9c9c9;
  padding: 5px;
  border-radius: 4px;
  &:hover {
    border: 2px solid #bbb;
    path {
      fill: #bbb;
    } 
  }
  &__leftArrow{
    margin-left: 5px;
    margin-right: 5px;
    vertical-align: top;
    fill: #c9c9c9;
  }
  &__rightArrow{
    margin-left: 5px;
    margin-right: 5px;
    vertical-align: top;
    fill: #c9c9c9;
  }
}

/* Styling for the calendar icon button */
.calendarBtn{
  background: 0 0;
  border: 0;
  color: inherit;
  font: inherit;
  line-height: normal;
  overflow: visible;
  cursor: pointer;
  display: inline-block;
  vertical-align: middle;
  padding: 5px;
  margin: 0 5px 0 5px;
  position: relative;
  &:hover {
    color: #008fc4;
  }

  &__icon {
    vertical-align: unset;
    &:hover {
      path {
        fill: #008fc4;
      } 
    }
  }
  
  &__disabled{
    cursor:not-allowed;
    border: none;
    path {
      fill: #ccc;
    }
  }
  &__errorMessage{
    path {
      fill: #da291c;
    }      
  }
}

/* Styling for the clear/close icon button */
.clearBtn{
  &:hover {
    color: #000f00;
  }

  &__icon {
    vertical-align: middle;
    &:hover {
      path {
        fill: #000f00;
      } 
    }
  }
  
  &__disabled{
    cursor:not-allowed;
    border: none;
    path {
      fill: #ccc;
    }
  }
  &__errorMessage{
    path {
      fill: #da291c;
    }      
  }
}

/* Styling for the inputs clear/close button */
.closeBtn{
  @include close-button();
  cursor: pointer;
  background: none;
  border: none;
  outline: none;
  padding: 5px;
  margin: 0 5px 0 5px;
  position: relative;
  &:hover {
    color: #121212;
  }

  &__icon {
    vertical-align: middle;
    &:hover {
      path {
        width: 22px;
        height: 22px;
        fill: #121212;
      } 
    }
  }
    &__disabled{
      cursor:not-allowed;
      border: none;
      path{
        fill: #ccc;
        border: none;
      }
    }
    &__errorMessage{
      path{
        fill: #da291c;
      }      
    }
}
