.formula-panel-content {
  padding: $gutter-md;
  padding-bottom: $formula-panel-no-menu-overflow-padding; // ensures that all operator dropdown menu options are visible
  border-top: $formula-panel-border;
  display: flex;
  flex-direction: column;
  &__message {
    font-size: $font-size-sm;
    color: rgba(0, 0, 0, 0.87);
  }
  &__selected-rounded-value-container {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
  }
  &__flagged {
    display: flex;
    align-items: center;
    color: $orange;
  }
  &__computed-amount {
    display: flex;
    align-items: center;
  }
  &__prefix-icon {
    display: flex;
    flex-direction: row;
    margin-right: $gutter-xxs;
  }

  &__display-value {
    display: flex;
    font-weight: $font-w-semi-bold;
    & .multi-line-ellipsis {
      width: 100%;
    }
  }
  &__selected-value-container {
    width: auto;
    float: left;
    display: flex;
    padding: $gutter-xs 0 calc(#{$gutter-md} + #{$gutter-xs}) 0;
    &--margin-left {
      margin-left: $gutter-md;
      & .formula-panel-content__selected-value-display-container {
        margin-right: 0;
      }
    }
  }
  &__selected-value-display-container {
    display: grid;
    margin-right: $gutter-xl;
    span {
      font-size: $font-size-sm;
      color: $grey-4;
      font-weight: $font-w-semi-bold;
    }
  }
  &__element-value {
    @include hyperlink-button-for-element;
    text-overflow: ellipsis;
    font-size: $font-size-ml;
    color: $primary-blue;
    font-weight: $font-w-semi-bold;
    text-align: left;
  }

  &__save-button-container {
    margin-top: $gutter-md;
    justify-content: flex-end;
    margin-left: auto;
    display: flex;
  }
  &__cancel-button {
    margin-right: $gutter-md;
  }
  &__element-value-tooltip{
    &.tooltip{
      &.place-top{
        &::before{
          height: 7px
        }
      }  
    }
  }
}
