.blackline-current-panel {
    &__element-report-header{
       height: 40px;
       border: 1px solid $active-grey;
       display: flex;
       align-items: center;
       justify-content: flex-start;
       padding: 0 0 0 20px;
       border-left: none;
       border-bottom: none;
       &--title{
           font-weight: 600;
           font-size: 14px;
       }
   }
 }