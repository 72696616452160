.element-history {
  &__entry {
    margin: $gutter-md 0;

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__change-description {
    margin-bottom: $gutter-xxs;
  }

  &__change-metadata {
    font-size: $font-size-md;
    color: $grey-5;
  }
}