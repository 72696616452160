.attach-work-paper {
  padding: $gutter-xs;
  &__create-button {
    margin-bottom: $gutter-md;
    font-size: $font-size-md;

    & svg {
      width: $gutter-md;
      height: $gutter-md;
    }
  }

  &__title {
    font-size: $font-size-sm;
    color: $grey-4;
    font-weight: $font-w-semi-bold;
    margin: $gutter-xs 0 $gutter-md 0;
  }

  &__search {
    & .menu__option span {
      // Styles added to allow for fix for bug https://symphonyvsts.visualstudio.com/TieOut2/_workitems/edit/829334
      // this allows us to display legacy WPRs that have more than 15 characters for the name property
      height: auto; // height is typically controlled in menu-component for determining page overflow behavior, may cause issues with many options
      white-space: normal;
      overflow: auto;
      word-break: break-all;
    }
  }
}
