.omnia-page {
  padding: $gutter-ml;
  width: 100%;

  &__back-button {
    @include back-button();
    padding: 0;
  }

  &__back-title {
    font-size: $font-size-md;
    color: $primary-blue;
  }

  &__page-title {
    font-size: $font-size-xxxl;
    margin-top: $gutter-md;
  }

  &__no-engagements {
    margin-top: $gutter-md;
    font-weight: $font-w-bold;
  }

  &__engagement {
    margin-right: $gutter-lg;
    display: flex;
    align-items: center;
  }

  &__omnia-icon {
    margin-right: $gutter-xs;
  }

  &__guidance-1 {
    margin: $gutter-md 0;
  }
  &__guidance-2 {
    font-weight: $font-w-bold;
    margin: $gutter-md 0;
  }

  &__projects-available-title {
    margin-top: $gutter-lg;
    font-weight: $font-w-bold;
  }

  &__project-info {
    margin: $gutter-md 0;
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: $gutter-xl;
    background-color: $grey-0;
  }

  &__project-info-engagement-name {
    font-size: $font-size-lg;
    font-weight: $font-w-bold;
  }

  &__project-info-client-name {
    font-size: $font-size-lg;
    font-weight: $font-w-bold;
  }

  &__project-info-title {
    color: $grey-7;
    font-size: $gutter-md;
    margin-bottom: $gutter-xxxs;
  }
}
