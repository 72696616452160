.bulk-select-sections{
    &__chip-container {
        margin-bottom: $gutter-sm;
        margin-top: -$gutter-sm;
        & .chip {
          display: inline-flex;
          margin-right: $gutter-xxs;
          margin-top: $gutter-xxs;
        }
      }
}