.add-navigator-modal {
  &__form-item {
    margin-bottom: $gutter-sm;
    font-size: $font-size-md;
  }

  &__instructions {
    margin-bottom: $gutter-sm;
    font-size: $font-size-md;
  }
}
#add-navigator-modal-id {
  & .modal {
    min-width: 450px;
    
  }
}
