.edit-manage-legal-hold {
  &__title {
    margin-bottom: $gutter-xs;
    display: flex;
    align-items: center;
  }
  &__info {
    font-size: $font-size-md;
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
  }
  &__information-icon {
    margin-left: $gutter-xs;
  }
  &__statements {
    margin-top: $gutter-md;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  &__statements-card {
    width: 32%;
    & .card {
      height: 100%;
      min-height: 28.75rem;
    }
  }
  &__card-title {
    margin-bottom: $gutter-xs;
    font-size: $font-size-default;
    font-weight: $font-w-semi-bold;
  }
  &__banner {
    margin-top: $gutter-xs;
  }
}
