.tertiary-workpaper-filters {
  &__search {
    padding-right: $gutter-md;
    margin-bottom: $gutter-md;
  }

  &__checkbox-container {
    display: flex;
    align-items: center;
    margin-bottom: $gutter-sm;
  }

  &__checkbox-label {
    display: flex;
    flex-direction: column;
  }
  .checkbox__input {
    margin-right: 0;
  }
}
