.statement-content-panel {
  width: 100%;
  padding: $gutter-xs;

  &--add-element-cursor {
    cursor: $add-element-cursor;
  }

  &--left-statement-cursor {
    cursor: alias;
  }

  &--right-statement-selection-cursor {
    cursor: alias;
  }

  &--right-statement-cursor {
    cursor: not-allowed;
  }

  &--range-select {
    user-select: none; // prevents text from being highlighted when user drags their mouse
  }

  &__range-select-box {
    border: $dashed-border-black;
    position: absolute;
    z-index: $z-index-selection;
  }
}
