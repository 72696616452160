.statement-summary-elements {
  display: flex;
  border: $border-dark-grey;
  border-radius: $border-radius-default;
  align-items: center;
  margin-right: $gutter-xs;
  height: $gutter-ml-1;

  &--button-block {
    border-right: solid 1px #d9d9d9;
  }

  &__button {
    align-items: center;
    background-color: transparent;
    border: none;
    display: flex;
    flex-direction: row;
    outline: none;
    margin: 7px;
    height: fit-content;

    &:hover {
      cursor: pointer;
      background-color: $hover-grey;
    }

    &--unverified {
      padding-left: $gutter-xs;
      padding-right: $gutter-xs;
      background-color: $element-status-unverified;
      border-bottom: $border-dotted-yellow;
    }

    &--verified {
      padding-left: $gutter-xs;
      padding-right: $gutter-xs;
      background-color: $element-status-verified;
      border-bottom: $border-dashed-blue;
    }

    &--reviewed {
      padding-right: $gutter-xs;
      padding-left: $gutter-xs;
      background-color: $element-status-reviewed;
      border-bottom: $border-solid-green;
    }

    &--disabled {
      background-color: $disabled-button-background-grey;
      &:hover {
        cursor: not-allowed;
        background-color: $disabled-button-background-grey;
      }
    }
  }

  &__icon {
    height: $statement-summary-icon-height;
    margin-right: $gutter-xxs;
    width: $statement-summary-icon-width;
  }

  &__label {
    font-size: $font-size-sm;
  }

  &__heading {
    font-size: $font-size-sm;
    background-color: $grey-1;
    padding: 0 $gutter-xs;
    height: 100%;
    display: flex;
    align-items: center;
  }
}
