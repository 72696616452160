.batch-workpaper-section {
  & .attach-work-paper {
    padding: 0;
  }
  &__entries {
    margin-top: $gutter-md;
  }
  &__remove {
    border-radius: 50%;
    padding: $gutter-xxs;
    cursor: pointer;
    .icon-path {
      fill: $primary-blue;
    }
    &:focus,
    &:hover {
      background-color: $hover-blue;
      .icon-path-inner {
        fill: $white;
      }
    }
    &:active {
      background-color: $active-blue;
      .icon-path-inner {
        fill: $white;
      }
    }

    &[disabled] svg {
      background-color: transparent;
      cursor: not-allowed;
      & .icon-path-outer {
        stroke: $grey-1;
      }
    }
  }
}
