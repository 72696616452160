.custom-export-block {
  &__export-button-container {
    display: flex;
    justify-content: center;
    margin: 20px;
  }
  &__export-button {
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
  }
  &__heading {
    font-weight: 550;
    margin: $gutter-md;
  }

  &__master-checkbox-container {
    display: flex;
    padding: 0 $gutter-md $gutter-md $gutter-sm;
    align-items: center;
  }
  &__count {
    color: $grey-5;
    white-space: nowrap;
  }
}
