.toolkit-downlaod-card{
    padding: $gutter-sm $gutter-md $gutter-sm $gutter-md;
    overflow-wrap: break-word;
    &:hover {
      background-color: $hover-grey;
      cursor: pointer;
    }
    &-download-ready-icon{
      &:active {
        background-color: $active-grey;
      }
    }
    &__cancel-button{
     justify-items: end; 
    }
    &--selected {
      background-color: $grey-2;
    }
      border-bottom: $border-gray-light;

    &-download-pending-icon{
        display: flex;
        justify-content: space-between;
        color:blue
    }
    &-download-status-icon{
        display: flex;
        justify-content: space-between;
        &__left-items{
          display: flex;
          justify-content: flex-start;
          align-items: center;
        }
        &__right-items{
          display: flex;
          justify-content: flex-end;
          align-items: center;
        }
    }
    &-name{
      font-weight: $font-w-bold;
      margin : $gutter-sm $gutter-sm $gutter-sm 0px;
      &-user-downloaded{
        font-weight: inherit;
      }
    }
}
.toolkit-downlaod-card-download-pending-icon{
  width: 20px;
  height: 20px;
}

@media (prefers-reduced-motion: no-preference) {
  .toolkit-downlaod-card-download-pending-icon {
    animation: Pending-icon-spin infinite 1s linear;
  }
}

@keyframes Pending-icon-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
