.project-list {
  border-right: $project-list-border;
  width: 100%;
  border-radius: $border-radius-default;
  height: 100%;
  display: flex;
  flex-direction: column;

  &__list {
    flex: auto;
    position: relative;

    &--virtualized {
      // height is equal 100% of the container minus project-list-header minus footer-padding top
      height: calc(100% - $project-list-header-min-height - $footer-padding);
    }
    &--scroll {
      overflow-y: scroll;
      @include scroll-bar();
    }
  }

  &__no-matching-results {
    padding: $default-gutter;
  }
}
