.icon-dropdown {
  &__icon-dropdown-container {
    position: relative;
    cursor: pointer;
    &--disabled{
      background-color: $disabled-button-background-grey;
      &:hover {
        background-color: $disabled-button-background-grey;
        cursor: not-allowed;
      }
    }
  }

  &__display-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__display-highlight svg path {
    stroke: #0076a8;
  }

  &__display-highlight--disabled svg path {
    stroke: $disabled-text;
  }

  &__list-title {
    margin-left: 5px;
  }

  &__list-container {
    border-radius: 2px;
    box-shadow: 0 1px 4px 0 $grey-3;
    outline: 1px solid $grey-3;
    background-color: $white;
    z-index: 999;
    position: absolute;
    font-size: 0.875rem;
    color: $black;
    width: max-content;
    list-style: none;

    &--items {
      display: flex;
      align-items: center;
      padding: 1px 5px;
      cursor: pointer;
    }

    &--item-selected {
      color: $blue-9;
      background-color: $blue-7;
    }

    &--item-selected svg path {
      stroke: #0076a8;
    }

    &--items:hover {
      color: $blue-9;
      background-color: $blue-7;
    }

    &--items:hover svg path {
      stroke: #0076a8;
    }
  }
}