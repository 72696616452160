.edit-project-info {
  display: flex;
  flex-wrap: nowrap;

  &__title {
    font-size: $font-size-xl;
    margin-bottom: $gutter-xs;
  }

  &__text-input {
    margin-top: $gutter-ml;
  }

  &__display-horizontal {
    margin-top: $gutter-md;
    display: flex;
    width: 100%;
  }

  &__dropdown{
    width: 25%;
  }

  &__region{
    width: 50%;
  }

  &__region-label {
    display: table;
    color: #757575;
    padding-bottom: 8px;
    font-weight: $font-w-bold;
    font-size: $font-size-sm;
  }

  &__region-name {
    font-weight: $font-w-semi-bold;
  }

  &__search {
    margin-top: $gutter-md;
  }

  &__checkbox {
    margin-top: $gutter-ml;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
  }

  &__information-icon {
    margin-left: $gutter-xs;
  }

  &__banner {
    margin-top: $gutter-xs;
  }

  &__copy {
    margin-top: $gutter-md;
    font-size: $font-size-md;
    width: 75%;
  }

  &__switch {
    display: flex;
    margin-top: $project-creation-edit-switch-margin-top;
  }

  &__switch-label {
    font-weight: $font-w-semi-bold;
    margin-right: $gutter-sm;
  }
}
