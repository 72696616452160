.export-workpaper-modal {
  &__form-item {
    margin-bottom: $gutter-sm;
    font-size: $font-size-md;
  }

  &__instructions {
    margin-bottom: $gutter-md;
    font-size: $font-size-md;
  }

  &__validation-message {
    color: $primary-red;
  }

  &__bundle-options{
    display: flex;
  }

  &__status,&__flag{
    margin-left: 10px;
  }
}
#export-workpaper-modal-id {
  & .modal {
    min-width: 450px;
  }

  & .modal__header {
    margin-bottom: $gutter-md;
  }
}
