.data-grid {
  border: $data-grid-border;
  background-color: $white;
  border-radius: $border-radius-default;

  &__scrollable-wrapper {
    overflow: hidden;
    overflow-y: scroll;
    @include scroll-bar();
  }

  &__cols-header-row {
    display: flex;
    padding: $gutter-xs $gutter-md;
    align-items: center;
    position: sticky;
    top: -$gutter-ml; // ensures when sticky, sticks to top of viewport
    background-color: $white;
    z-index: $z-index-sticky-table-header;
    border-bottom: $data-grid-border;
  }

  &__row {
    display: flex;
    padding: $gutter-xs $gutter-md;
    border-bottom: $data-grid-border;
    align-items: center;
    &:last-child {
      border-bottom: none;
    }
    &[disabled] {
      background-color: $disabled-button-background-grey;
      color: $disabled-buttton-text-grey;
    }
  }

  &__no-results {
    padding: $gutter-xs $gutter-md;
    display: flex;
    align-items: center;
  }

  &__header-col {
    text-align: left;
    height: 100%;
    display: flex;
    align-items: center;
    font-size: $font-size-sm;
    font-weight: $font-w-semi-bold;
    color: $grey-6;

    button,
    span {
      @include font-swc(
        $font-size-sm,
        $font-w-semi-bold,
        $secondary-label-grey
      );
    }
  }

  &__header-col-button {
    background-color: $white;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    &[disabled] {
      cursor: auto;
    }

    .sort-arrow,
    span {
      display: block;
    }

    & .sort-arrow {
      margin-right: $gutter-xxxs;
    }
  }

  &__col {
    padding: $gutter-xxxs;
    /* DO NOT apply ellipsis text styles {
   *  white-space: nowrap;
   *  overflow: hidden;
   *  text-overflow: ellipsis;
   * } here, instead use the MultiLineEllipsis component so tooltips can get added */
    .LinesEllipsis {
      // adds a bit of space between the generated ellipsis and the next column
      padding-right: $default-gutter;
    }
    &.icon-col,
    .dropdown__kebab-wrapper {
      display: flex;
      justify-content: center;
    }

    &.icon-col svg {
      @include clickable-icon-circle();
    }
  }

  &__col.kebab-col,
  &__col.dropdown-col {
    overflow: visible;
  }
  &__col:last-child {
    &.icon-col {
      padding-left: $gutter-md; // based on right margin of row, so icon appears centered
    }
  }

  &__omnia-icon {
    margin-right: $gutter-xxs;
  }
}
