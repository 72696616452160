.bulk-select-sections{
        font-family: $font-family-default;
        font-size: $font-size-sm;
        user-select: none;
      
        &__arrow{
          min-width: $gutter-sm;
          margin-right: $gutter-xxs;
          display: inline-block;
          cursor: pointer;
      
          & svg {
            fill: black;
            height: 10px;
            width: 10px;
          }
      
          &--collapsed {
            & svg {
              fill: black;
              transform: rotate(-90deg);
            }
          }
        }
      
        &__item {
          white-space: nowrap;
          cursor: pointer;
          padding: $gutter-xxs;
          margin-bottom: $gutter-xxs;
          display: grid;
          grid-template-columns: 1fr auto;
      
          &--col1 {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            margin-right: $gutter-xxs;
            display: flex;
            align-items: center;
          }
      
          &:hover {
            background-color: $hover-grey;
          }
      
          &--expanded {
            color: $blue-1;
      
            & svg {
              fill: $blue-1;
            }
          }
        }
      
        &__children--collapsed {
          height: 0px;
        }
      
        &__children {
          & .bulk-select-sections__item {
            padding-left: $gutter-md;
          }
          & .bulk-select-sections__children {
            .bulk-select-sections__item {
              padding-left: $gutter-md * 2;
            }
            & .bulk-select-sections__children {
              .bulk-select-sections__item {
                padding-left: $gutter-md * 3;
              }
              & .bulk-select-sections__children {
                .bulk-select-sections__item {
                  padding-left: $gutter-md * 4;
                }
                & .bulk-select-sections__children {
                  .bulk-select-sections__item {
                    padding-left: $gutter-md * 5;
                  }
                  & .bulk-select-sections__children {
                    .bulk-select-sections__item {
                      padding-left: $gutter-md * 6;
                    }
                    & .bulk-select-sections__children {
                      .bulk-select-sections__item {
                        padding-left: $gutter-md * 7;
                      }
                      & .bulk-select-sections__children {
                        .bulk-select-sections__item {
                          padding-left: $gutter-md * 8;
                          & .bulk-select-sections__children {
                            .bulk-select-sections__item {
                              padding-left: $gutter-md * 9;
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }

        &__section-assignments {
          display: inline;
          white-space: nowrap;
          margin-right: $gutter-xs;
        }
      
        &__assigned {
          font-weight: $font-w-semi-bold;
          font-size: $gutter-md;
          color: $grey-4;
          margin-left: $gutter-xxs;
          display: inline-block;
        }
        &__assigned::after {
          content: ',';
        }
        &__assigned:last-of-type::after {
          content: '';
        }
}      