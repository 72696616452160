.checkbox {
  display: inline-flex;
  align-items: center;
  padding: $gutter-xs;
  border-radius: $border-radius-default;

  

  &--checked {
    border: $checkbox-border-size solid $checkbox-hover-outline-blue;
    padding: $gutter-xs - $checkbox-border-size;
  }

  &--invalid {
    color: $error-red;
    .checkbox__input {
      border: $checkbox-border-size + 1px solid $error-red;
    }
  }
  &--multiLineLabel {
    width: 100%;
  }
  &--disabled {
    cursor: not-allowed;
    &:not(.checkbox--checked) {
      border: none !important;
      padding: $gutter-xs !important;
    }
  }

  &:hover,
  &:focus {
    border: $checkbox-border-size solid $checkbox-hover-outline-blue;
    padding: $gutter-xs - $checkbox-border-size;
    .checkbox__input {
      border: $checkbox-border-size + 1px solid $hover-blue;
    }
  }
  &__input {
    appearance: none;
    position: relative;
    cursor: pointer;
    outline: none;
    width: $checkbox-height;
    height: $checkbox-height;
    margin-right: $gutter-xs;
    border: $checkbox-border-size + 1px solid $checkbox-unchecked;
    flex-shrink: 0;

    &:checked {
      background: url('../../../icons/checkbox-selected.svg') center no-repeat;
      border: $checkbox-border-size + 1px solid $primary-blue;
    }
    &--alt:checked {
      background: url('../../../icons/checkbox-selected-alt.svg') center
        no-repeat;
      border: $checkbox-border-size + 1px solid $primary-blue;
    }
    &--master:checked {
      background: url('../../../icons/checkbox-indeterminate.svg') center
        no-repeat;
      border: $checkbox-border-size + 1px solid $primary-blue;
    }
    &[disabled] {
      cursor: not-allowed;
      border: $checkbox-border-size + 1px solid $checkbox-disabled-grey !important;
      &:checked {
        background: url('../../../icons/checkbox-selected-disabled.svg') center
          no-repeat;
      }
    }
    &:hover,
    &:focus {
      border: $checkbox-border-size + 1px solid $primary-blue;
    }
  }

  &__label {
    &[disabled] {
      color: $checkbox-label-disabled-grey;
    }
  }
  
}
