.formula-row-manual-input {
  height: 100%;
  display: flex;
  min-width: $formula-value-display-min-width;
  &__input,
  &__readonly {
    text-align: right;
  }
  &__readonly {
    margin-left: auto;
    text-overflow: ellipsis;

    border-radius: $border-radius-default;
    border: solid 1px $border-grey;
    background-color: $white;
    padding: $gutter-xs;
    width: 100%;
    font-size: $font-size-md;

    &:enabled {
      color: $black;
    }

    &:focus,
    &:hover {
      border: $hover-focus-border;
      @include adjust-padding-for-thicker-border-hover($gutter-xs);
    }

    &[disabled] {
      border: $disabled-border;
      color: $disabled-text;
      cursor: not-allowed;

      &:hover {
        padding: $gutter-xs;
      }
    }
    &--invalid {
      border: $invalid-border;
      border-radius: $border-radius-default;
      background-color: $white;
      @include adjust-padding-for-thicker-border-hover($gutter-xs);
      color: $default-input-text-grey;
      width: 100%;
      &:hover {
        border: $invalid-border;
      }
    }
  }

  &__invalid {
    border: $invalid-border;
    border-radius: $border-radius-default;
    background-color: $white;
    @include adjust-padding-for-thicker-border-hover($gutter-xs);
    color: $default-input-text-grey;
    width: 100%;
  }
}
