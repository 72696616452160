.notes-panel-input-area {
    padding: $gutter-md; 
    background-color: $blue-7;
    &__current-user {
        margin-bottom: $gutter-md;
        font-weight: $font-w-semi-bold;
        font-size: $font-size-md;
    }
    &__selected-text {
        @include overflow-ellipsis;
        display: block; 
        margin-bottom: $gutter-md;
        font-weight: $font-w-semi-bold;
    }
    &__radio-buttons {
        display: flex;
        margin-bottom: $gutter-md;
    }
    &__button-container {
        display: flex;
        width: 100%;
        justify-content: flex-end;  
        margin-top: $gutter-md;      
        &--cancel {
            margin-right: $gutter-md;
        }
    }

    & textarea {
        height: $gutter-xxxl + 25;
    }
}