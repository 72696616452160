.audit-checklist-element {
    &__title{
        display: inline-block;
        font-weight: $font-w-semi-bold;
        
      }

      &__id {
        display: flex;
        justify-content: flex-start;
        font-weight: $font-w-semi-bold;
        
    }

    &__question-content {
      display: flex;
      justify-content: flex-start;
      font-size: $font-size-sm;
      
  }

      &__button-container {
        display:flex;
        width: 100%;
        justify-content:flex-start;  
        margin-top: $gutter-md;      

        &--answer {
          &[disabled] {
            background-color: $primary-blue;
            color: $white;
            cursor: not-allowed;
          }
        }
        
    }
   

    }


   