.maintenance-page {
     border-radius: $border-radius-default;
    border-top: $data-grid-border;
    border-right: $data-grid-border;
    border-left: $data-grid-border;
    border-bottom: $data-grid-border;
    &__maintenance-text--disabled{
           pointer-events: none;
    }
    &__maintenance-rich-text--disabled{
        cursor: not-allowed;
            opacity: 0.4;
    }
    &-switch{
        margin: $gutter-md;
        
    }
    &-switch-banner{
        margin-right : $gutter-md;
    }
    &__start-date{
        margin-left: $gutter-sm;
        justify-content: space-between;
        margin-top: $gutter-md;
        align-items: center;
    }
    &__end-date{
        margin-left: $gutter-sm;
        margin-top: $gutter-md;
        justify-content: space-between;
        align-items: center;
    }
    &-button-wrapper{
        display: flex;
    }
    &-save-button{
        margin: $gutter-md;
    }
    &-edit-button{
        margin: $gutter-md;
    }
   
}