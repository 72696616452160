$project-list-card-right: 12.5%;
.project-card {
  width: 100%;
  min-height: 182px;
  padding: $default-gutter + $project-list-hover-border-size;
  border-radius: $border-radius-default;
  border-top: $project-list-border;
  position: relative;

  &--selected {
    background-color: $project-card-grey;
  }

  &:hover {
    border: $project-list-hover-border;
    cursor: pointer;
    // corrects for hover border, so no shifting
    padding: $default-gutter;
    padding-top: $default-gutter + $project-list-border-size;
    .project-card__kebab {
      right: $gutter-xs - $project-list-hover-border-size;
      top: $gutter-xs - $project-list-hover-border-size +
        $project-list-border-size;
    }
  }
  &:focus,
  &:active {
    background-color: $hover-grey;
  }

  &__pinIcon{
    position: absolute;
    top: 10px;
    right: 36px;
  }

  &__kebab {
    position: absolute;
    top: $gutter-xs;
    right: $gutter-xs;
    & .project-card__kebab-menu {
      width: 8rem;
      right: $project-list-card-right;

      & .tooltip {
        width: $project-list-width-percentage - 2; // no relative/absolutely positioned parent above this so reference to full page width
        left: $gutter-xxs !important;

        &:after {
          left: auto !important;
          right: $project-list-card-right !important;
        }
      }
    }
  }

  &__list-item:not(:last-child) {
    margin-bottom: $gutter-md;
  }

  &__title {
    font-size: $font-size-ml;
    font-weight: $font-w-bold;
    color: $secondary-label-grey;
    padding-left: $gutter-xs;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    .multi-line-ellipsis {
      width: 100%;
    }

    .tooltip {
      max-width: $project-list-min-width - $gutter-xxxl;
    }

    &--deleted {
      color: $project-card-deleted-grey;
      display: flex;
      padding-left: 0;
    }

    &--admin-group {
      padding-left: 0;
    }
  }

  &__hold {
    color: $orange;
    font-size: $font-size-default;
    margin-left: $gutter-xxs;
    margin-right: $gutter-sm;
    font-weight: $font-w-semi-bold;
  }

  &__shield {
    margin-right: $gutter-xxs;
  }
  &__rocket {
    margin-right: $gutter-xxs;
  }
  &__delete-icon {
    padding-right: $gutter-xs;
  }

  &__client,
  &__engagement,
  &__period-ending,
  &__deleted-date {
    font-size: $font-size-md;
  }
  &__client {
    color: $project-card-client-name-grey;
  }

  &__display-horizontal{
    display: flex;
    justify-content: space-between;
    align-items: baseline;
  }

  &__geo-code{
    font-size: $font-size-md;
  }

  &__deleted-date {
    font-weight: $font-w-semi-bold;
    color: $black;
    margin-top: -$gutter-xs;
  }

  &__omnia-icon {
    margin: 0 $gutter-xxs 0 $gutter-xxs;
  }
}
