.omnia-file-sharing-confirm-modal {
  padding: $gutter-ml;
  width: 100%;

  &__omnia-icon {
    margin-right: $gutter-xs;
  }

  &__guidance-1 {
    padding: $gutter-xxs;
    margin-top: $gutter-xs;
  }

  &__guidance-2 {
    padding: $gutter-xxs;
    margin-top: $gutter-xs;
    color: $red;
  }

  &__guidance-3 {
    margin-top: $gutter-xs;
    margin-bottom: $gutter-md;
    font-weight: $font-w-bold;
  }

  &__project-info {
    margin: $gutter-xxxs 0;
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: $gutter-sm;
    grid-gap: $gutter-md;
  }

  &__project-info-wrapper {
    margin: $gutter-xxxs 0;
    background-color: $grey-0;
    border: 1px solid #a9a9a9;
  }

  &__project-info-link-details {
    font-size: $font-size-md;
    font-weight: $font-w-bold;
    padding: $gutter-sm $gutter-md $gutter-sm $gutter-md;
    border-bottom: 1px solid #a9a9a9;
  }

  &__project-info-engagement-name {
    font-size: $font-size-md;
    font-weight: $font-w-bold;
  }

  &__project-info-client-name {
    font-size: $font-size-md;
    font-weight: $font-w-bold;
  }

  &__project-info-title {
    color: $grey-7;
    font-size: $gutter-md;
    margin-bottom: $gutter-xxs;
  }

  &__bundle-options{
    display: flex;
  }
}
