$formula-panel-no-menu-overflow-padding: 150px;
$formula-panel-selected-value-hover-padding: 15px;
$formula-panel-border: 1px solid $blackTransparent;
$formula-panel-edit-min-height: 326px;
$formula-panel-edit-margin-top: 104px;
$formula-panel-edit-remove-row-icon-size: 14px;

$formula-inter-row-margin: $gutter-xxs;
$formula-inter-operator-margin: $gutter-md;

$formula-error-background: #f9dfdd;

$formula-operator-dropdown-size: 1.8rem;
$formula-value-display-min-width: 80%; // for elements and manual number rows

// These values ensure the formula panel scales appropriately at laptop screens
// op-left-margin + op container width + margin between op and value + value container width + value-right-margin = entire formula edit container width
// 4 + 22 + 4 + 66 + 4 = 100%
$formula-row-op-margin: 4%;
$formula-operator-container-width: 22%;
$formula-value-container-width: 66%;
$formula-row-error-offset: 54px;
