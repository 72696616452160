.manage-coe-team-access {
    display: flex;
    flex-wrap: nowrap;
  
    &__title {
      margin-bottom: $gutter-xs;
    }
  
    &__checkbox {
      margin-top: $gutter-ml;
    }
  
    &__disclaimer-button {
      @include hyperlink-button;
      font-weight: $font-w-semi-bold;
      margin-left: $gutter-ml + 3;
      margin-bottom: 25px;
    }

    &__info {
        font-size: $font-size-md;
        margin-top: 20px;
        display: flex;
        align-items: center;
      }

      &__coe-enable-history {
        background: $blue-4;
        width: 75%;
        font-size: $font-size-md;
        font-weight: $font-w-semi-bold;
        padding: 0 0 $gutter-md $gutter-xl - 4;
        margin-top: -$gutter-xs;
      }

      &__coe-enable-history > div > span {
        color: $blue-9;
      }

      &__banner {
        margin-top: $gutter-md;
      }
  }
  