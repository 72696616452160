$statement-nav-filter-icon-size: 12px;
$statement-nav-modal-element-filter-height: 275px;

$statement-nav-filter-left-spacing: $gutter-md;

$statement-nav-chips-container-max-height: 150px;

$statement-nav-tabs-height-desktop: 48px;
$statement-nav-tabs-height-tablet: 40px;

$statement-nav-elements-search-entry-wpr-width: 125px;
