.section-panel {
  @include scroll-bar();

  &__header {
    display: flex;
    flex-direction: column;

    &--icon {
      display: flex;
      margin-bottom: $gutter-xs;
      align-items: center;
    }

    &--buttons {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  &__sign-icon {
    width: 30px;
    margin-top: $gutter-xxxs;
    align-self: flex-start;
    margin-bottom: 6px;
  }

  &__section-name {
    font-size: $font-size-ml;
    color: $primary-blue;
    font-weight: $font-w-semi-bold;
    text-align: left;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    width: fit-content;
    margin-left: 20px;
    margin-bottom: 2px;

    &:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }

  &__status-button {
    display: flex;
  }

  &__info-icon {
    margin-left: $gutter-xxs;
  }

  &__section-assign-container {
    margin-top: $gutter-xs;
  }

  &__container {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  &__total {
    display: flex;
    border: $border-dark-grey;
    border-radius: $border-radius-default;
    align-items: center;
    margin-right: $gutter-xs;
    height: $gutter-ml;


    &--totalcount {
      font-size: $font-size-sm;
      background-color: $grey-1;
      padding: 0 $gutter-xs;
      height: 100%;
      display: flex;
      align-items: center;
    }
  }

  &__elementstatus {
    display: flex;
    border: $border-dark-grey;
    border-radius: $border-radius-default;
    align-items: center;
    margin-right: $gutter-xs;
    height: $gutter-ml;
    width: fit-content;

    &__button {
      align-items: center;
      background-color: transparent;
      border: none;
      display: flex;
      flex-direction: row;
      outline: none;
      height: 100%;

      &:hover {
        cursor: pointer;
        background-color: $hover-grey;
        text-decoration: underline;
      }

      &--verified {
        padding-left: $gutter-xs;
        padding-right: $gutter-xxs;
      }

      &--reviewed {
        padding-left: $gutter-xxs;
        padding-right: $gutter-xs;
      }
    }

    &__icon {
      height: $statement-summary-icon-height;
      margin-right: $gutter-xxs;
      width: $statement-summary-icon-width;
    }

    &__label {
      font-size: $font-size-sm;
    }
  }


  &--notesbox {
    align-items: center;
    background-color: transparent;
    border: $border-dark-grey;
    border-radius: $border-radius-default;
    display: flex;
    flex-direction: row;
    padding: 0 $gutter-xs;
    outline: none;
    height: $gutter-ml;

    &:hover {
      cursor: pointer;
      text-decoration: underline;
      background-color: $hover-grey;
    }
  }

  &--notesicon {
    height: $gutter-ml;
    margin-right: $gutter-xxs;
    width: $statement-summary-icon-width;
  }

  &--noteslabel {
    font-size: $font-size-sm;
  }

  &__flaggedbutton {
    align-items: center;
    background-color: transparent;
    border: $border-dark-grey;
    border-radius: $border-radius-default;
    display: flex;
    flex-direction: row;
    padding: 0 $gutter-xs;
    outline: none;
    height: $gutter-ml;
    margin-left: 8px;

    &:hover {
      cursor: pointer;
      text-decoration: underline;
      background-color: $hover-grey;
    }
  }

  &__flaggedicon {
    height: $statement-summary-icon-height;
    margin-right: $gutter-xxs;
    width: $statement-summary-icon-width;
  }

  &__flaggedlabel {
    font-size: $font-size-sm;
  }

  &__refresh-button {
    position: relative;
    bottom: 0;
    right: 0;
    margin-right: $gutter-md;
    border: none;
    background-color: transparent;
    outline: none;

    :hover {
      cursor: pointer;
    }
  }

  &__refresh-button-tooltip {
    width: 325px;
  }

}