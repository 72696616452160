.api-error-notification {
  &__close-notification-button {
    border: none;
    background-color: transparent;
    height: fit-content;
    outline: none;
    position: absolute;
    right: 0;

    &:hover {
      cursor: pointer;
    }
  }

  &__message-label {
    font-size: $gutter-sm;
    margin-bottom: $gutter-xxs;
    margin-top: $gutter-sm;
  }

  &__message-container {
    display: flex;
    align-items: center;
    font-size: $gutter-sm;
    justify-content: space-between;
    margin-bottom: $gutter-xs;
    background-color: $white;
    padding: $gutter-xxs;
    border: 1px solid $grey-2;
  }

  &__error-message-text {
    @include overflow-ellipsis;
    font-weight: $font-w-regular;
    color: $grey-5;
    width: 50%;
  }

  &__copy-message-button {
    border: none;
    outline: none;
    border-radius: $border-radius-default;
    height: $gutter-lg;
    width: 7.5rem;
    font-size: $gutter-sm;

    &--copied {
      background-color: $green-3;
      color: $green-1;
    }

    &--copy-message {
      background-color: rgba(160, 220, 255, 0.25);
      color: $blue-3;
      &:hover {
        cursor: pointer;
      }
    }
  }

  &__submit-ticket-button {
    width: 100%;
    border: none;
    outline: none;
    background-color: $blue-1;
    color: $white;
    border-radius: $border-radius-default;
    height: $gutter-lg;
    font-size: $gutter-sm;
    &:hover {
      cursor: pointer;
    }
  }

  &__message-paragraph {
    font-size: $font-size-md;
    padding-left: $gutter-xs;
  }

  &__main-message {
    font-size: $font-size-md;
    font-weight: $font-w-bold;
  }

  &__refresh-message {
    font-size: $font-size-md;
    margin-top: $gutter-sm;
  }

  &__refresh-button {
    border: none;
    background-color: transparent;
    outline: none;
    cursor: pointer;

    svg {
      margin-right: $gutter-xxxs !important;
      height: $gutter-md !important;
      width: $gutter-md !important;
    }
  }
}
