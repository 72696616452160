@mixin button-animated-underline {
  background-color: transparent;
  color: $primary-blue;
  border: none;
  border-radius: 0;
  padding-bottom: $gutter-xs + $button-hover-border-size; // to account for highlighted bottom
  @include underline-center-grow();

  &[disabled] {
    background-color: rgba(0, 0, 0, 0);
  }
}

@mixin remove-default-button-styling {
  background: transparent;
  border: none;
  outline: none;
}

@mixin remove-all-tieout-button-styling {
  @include remove-default-button-styling;
  color: $black;
  cursor: default;

  &:hover {
    text-decoration: none;
    background-color: transparent !important;
  }
}

@mixin hyperlink-button {
  color: $blue-9;
  background: transparent;
  border: none;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

@mixin hyperlink-button-for-element {
  color: $blue-9;
  background: transparent;
  border: none;
  cursor: pointer;
}