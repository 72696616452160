.formula-panel-edit {
  min-height: $formula-panel-edit-min-height;
  border: $formula-panel-border;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &__result-container {
    background: $grey-8;
    padding: $gutter-md;
    font-weight: $font-w-semi-bold;
    display: flex;
  }

  &__space {
    justify-content: space-between;
  }

  &__no-space {
    flex-direction: column;
  }

  &__right-side-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }

  &__banner-container {
    padding: $gutter-xs;
    background-color: $grey-0;
    &:first-child {
      margin: 0;
    }
  }

  &__input-row-container {
    display: flex;
    flex-direction: column;
    padding: $gutter-sm * 2 0;

    & > .formula-operator + .formula-panel-edit__formula-row {
      margin-top: -$formula-inter-row-margin;
    }
    & > .formula-panel-edit__formula-row + .formula-operator {
      margin-top: -$formula-inter-row-margin;
    }

    & > .formula-operator + .formula-operator {
      margin-top: $formula-inter-operator-margin;
    }
  }

  &__button-container {
    padding: $gutter-xs;
    background-color: $grey-0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  &__button-row {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    margin: $gutter-xxxs 0;
  }

  &__buttons {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
  }

  &__add-operator-button {
    margin-right: $gutter-xxs;
    background-color: $white;
    padding: $gutter-xs $gutter-sm;
  }

  &__add-row-button {
    background-color: $white;
    padding: $gutter-xs $gutter-sm;
  }

  &__clear-all-button {
    background-color: $white;
    padding: $gutter-sm;
    border-radius: 50%;
    & .button__icon__icon {
      margin-right: 0;
    }
  }

  &__change-operator-button {
    background-color: $white;
    border-radius: 50%;
    margin-right: $gutter-xxs;
    padding: $gutter-md;
    & .button__icon__icon {
      margin-right: 0;
    }
  }

  &__create-tickmark-button {
    margin-bottom: $gutter-md;
  }

  &__error-banner {
    padding: $gutter-md;
    margin-bottom: $gutter-md;
    background-color: $formula-error-background;
    border-bottom: $gutter-xxxs solid $red;
  }

  &__warning-banner {
    padding: $gutter-md;
    margin-bottom: 0 !important;
    border-bottom: $gutter-xxxs solid $yellow;
  }

  &__warning-banner-title {
    font-size: $font-size-sm;
    font-weight: $font-w-semi-bold;
  }

  &__warning-banner-text {
    font-size: $font-size-xs;
    font-weight: $font-w-regular;
    margin-top: $gutter-xxs;
  }

  &__flagged {
    display: flex;
    justify-content: flex-end;
    color: $orange;
  }

  &__flagged-icon {
    margin-right: $gutter-xxs;
    display: flex;
    align-items: center;
    & span {
      color: black;
      margin-right: $gutter-xs;
    }
  }

  &__display-value {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-right: calc(4% + #{$gutter-ml});
    & span {
      color: black;
      margin-right: $gutter-xs;
    }
  }

  &__formula-row {
    display: flex;
    margin-right: $formula-row-op-margin;
    margin-left: auto;
    width: $formula-value-container-width;

    &--error {
      color: $error-red;
      font-size: $font-size-sm;
      margin-left: auto;
      margin-right: calc(
        #{$formula-row-op-margin} + #{$formula-row-error-offset}
      );
      margin-top: $gutter-xxs;
      text-align: right;
    }
  }

  &__clear-row-button {
    padding: 0 $gutter-md 0 $gutter-md;
    display: flex;
    align-items: center;
    justify-content: center;
    .button__icon__icon {
      display: block;
      margin: 0;
      width: $formula-panel-edit-remove-row-icon-size;
      height: $formula-panel-edit-remove-row-icon-size;
    }
  }

  &__round-number {
    @include hyperlink-button;
    @include overflow-ellipsis;
    font-size: $font-size-sm;
    color: $primary-blue;
    font-weight: $font-w-semi-bold;
    text-align: left;
    margin-left: $gutter-xs;
    margin-right: calc(4% + #{$gutter-ml});
    align-self: flex-end;
  }

  &__prefix-icon {
    display: flex;
    flex-direction: row;
    margin-right: $gutter-xxs;
  }

  &__show-more-button {
    color: $primary-blue;
    outline: none;
    background-color: transparent;
    border: none;
    font-size: $font-size-xs;
    &:hover {
      cursor: pointer;
    }
  }
}
