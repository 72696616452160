.notes-summary {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: $border-dark-grey;
  border-radius: $border-radius-default;

  &__icon-container {
    display: flex;
    align-items: center;
  }

  &__icon-button {
    display: flex;
    align-items: center;
    border: none;
    background: none;
    outline: none;
    padding: $gutter-sm;
    &:hover {
      background-color: $hover-grey;
      cursor: pointer;
    }
    &:active {
      background-color: $active-grey;
      cursor: pointer;
    }
  }

  &__note-icon {
    margin-right: $gutter-xxs;
    height: 24px;
    width: 24px;
  }

  &__divider {
    font-weight: lighter;
    font-size: $font-size-xxl;
  }

  &__count {
    font-size: $font-size-md;
  }
}
