//Gutters

$default-gutter: 24px;

$gutter-xxxs: calc($default-gutter / 12); //2px
$gutter-xxs: calc($default-gutter / 6); //4px
$gutter-xs: calc($default-gutter / 3); //8px
$gutter-sm: calc($default-gutter / 2); //12px
$gutter-md: calc($default-gutter / 1.5); //16px
$gutter-ml: $default-gutter * 1.3333; //32px
$gutter-lg: $default-gutter * 1.5; //36px
$gutter-xl: $default-gutter * 2; //48px
$gutter-xxl: $default-gutter * 2.5; //60px
$gutter-xxxl: $default-gutter * 3; //72px
$gutter-ml-1: calc(($default-gutter * 1.3333) + 1px);

//Gutters in rem

$default-gutter-rem: 1.5rem;

$gutter-xxxs-rem: 0.125rem; //2px
$gutter-xxs-rem: 0.25rem; //4px
$gutter-xs-rem: 0.5rem; //8px
$gutter-sm-rem: 0.75rem; //12px
$gutter-md-rem: 1rem; //16px
$gutter-ml-rem: 2rem; //32px
$gutter-lg-rem: 2.25rem; //36px
$gutter-xl-rem: 3rem; //48px
$gutter-xxl-rem:3.75rem; //60px
$gutter-xxxl-rem: 4.5rem; //72px

//Fonts

$font-family-default: 'Open Sans', Verdana, Helvetica, sans-serif;

$font-size-default: 16px;

$font-size-xxs: 0.5625rem; //9px
$font-size-xs: 0.625rem; //10px
$font-size-sm: 0.75rem; //12px
$font-size-md: 0.85rem; //13.6px
$font-size-ml: $font-size-default; //16px
$font-size-lg: 1.125rem; //18px
$font-size-xl: 1.25rem; //20px
$font-size-xxl: 1.5rem; //24px
$font-size-xxxl: 1.875rem; //30px

$font-w-light: 300;
$font-w-regular: 400;
$font-w-semi-bold: 600;
$font-w-bold: 700;
$font-w-bolder: 900;

// Cursor
$add-element-cursor: alias;

//Border-Radius

$border-radius-default: 2px;
$border-radius-scroll-bar: 32px;

//Box shadow

$box-shadow-1: -2px 2px 5px 0 rgba(0, 0, 0, 0.11);
$box-shadow-2: 0 2px 4px 0 rgba(0, 0, 0, 0.5); // CARDS AT BOTTOM
$box-shadow-3: 0 16px 22px 0 rgba(0, 0, 0, 0.26);
$box-shadow-4: 0px 1px 4px rgba(0, 0, 0, 0.16);

//Line height

$line-height-1: 1;
$line-height-2: 1.25;
$line-height-3: 1.5;
$line-height-4: 1.63;
$line-height-5: 1.67;
$line-height-6: 1.8;

//Borders

$border-gray: solid 1px #eaeaea;
$border-gray-light: solid 1px #d0d0ce;
$border-dark-grey: solid 1px #d9d9d9;
$dropdown-gray-border: solid 1px #97999b;
$data-grid-border: solid 1px #c4c4c4;
$search-border-grey: solid 1px #c9c9c9;
$hover-focus-border: solid 2px #008fc4;
$invalid-border: solid 2px #da291c;
$disabled-button-border: solid 1px #e0e0e0;
$disabled-border: dashed 1px #e0e0e0;
$dashed-border-black: dashed 1px black;
$blue-border: solid 1px #00a3e0;
$border-dashed-blue: 1.5px dashed #0076a8;
$border-solid-green: 1.5px solid #009a44;
$border-dotted-yellow: 1.5px dotted #b28800;

//z-index

$z-index-underneath: -1;
$z-index-content: 1;
$z-index-header: 2;
$z-index-selection: 3;
$z-index-disclosure-analytics-header: 9;
$z-index-tooltip: 10;
$z-index-toast: 15;
$z-index-dashboard: 20;
$z-index-sticky-table-header: 25;
$z-index-home-page-project-list: 26;
$z-index-dropdown: 30;
$z-index-project-list-header: 31;
$z-index-global-header: 35;
$z-index-overlay: 53;
$z-index-statement-summary-display-options: 60;
$z-index-statement-processing-pop-over: 998;
$z-index-modal: 2000;
$z-index-modal-overload: 10001;

//Breakpoints
$breakpoint-min-page-width: 1280px;

$breakpoints: (
  mobile: 767px,
  tablet: 768px,
  desktop: $breakpoint-min-page-width,
  large-desktop: 1800px,
  extra-large-desktop: 2500px,
);
