h1 {
  font-size: $font-size-xxxl;
  font-weight: $font-w-regular;
  font-stretch: normal;
  line-height: 1;
  letter-spacing: normal;
}

h2 {
  font-size: $font-size-xxl;
  font-weight: $font-w-bold;
  font-stretch: normal;
  line-height: 1;
  letter-spacing: normal;
}

h3 {
  font-size: $font-size-xl;
  font-weight: $font-w-regular;
  font-stretch: normal;
  line-height: 1;
  letter-spacing: normal;
}

h4 {
  font-size: $font-size-lg;
  font-weight: $font-w-regular;
  font-stretch: normal;
  line-height: 1;
  letter-spacing: normal;
}

h5 {
  font-size: $font-size-ml;
  font-weight: $font-w-bold;
  font-stretch: normal;
  line-height: 1;
  letter-spacing: normal;
}

h6 {
  font-size: $font-size-md;
  font-weight: $font-w-regular;
  font-stretch: normal;
  line-height: 1;
  letter-spacing: normal;
}
