.internal-reference-element-entry {
  @include comfort-assign-element();
  padding: $gutter-sm;
  display: flex;
  justify-content: space-between;
  align-items: center;
  &:hover {
    background-color: $hover-grey;
    cursor: pointer;
  }

  &--selected {
    background-color: $grey-2;
  }
  &__text-container {
    display: flex;
    flex-direction: column;
    width: 60%;
  }

  &__element-value-wrapper {
    display: flex;
    text-align: left;

    & svg {
      width: $gutter-md;
      margin-left: $gutter-xxs;
    }
  }

  &__section-name {
    @include overflow-ellipsis;
    display: block;
    font-size: $font-size-sm;
  }

  &__element-label {
    @include overflow-ellipsis;
    display: block;
    font-size: $font-size-sm;
    color: $grey-4;
  }

  &__button-container {
    display: flex;
    align-items: center;
    max-width: 25%;
  }

  &__flagged {
    color: $orange;
  }
  &__flagged-icon {
    margin-right: $gutter-xxs;
  }

  &__element-value {
    color: $primary-blue;
    margin-right: $gutter-xxs;
    font-size: $font-size-md;
    font-weight: $font-w-semi-bold;
    display: flex;
    text-align: left;
    outline: none;
    &:hover {
      cursor: pointer;
    }

    &--selected {
      background-color: $blue-8;
    }
  }
  &__annotations {
    font-size: $font-size-sm;
    color: $black;
    max-width: 50%;
    @include overflow-ellipsis;
    &--active {
      white-space: normal;
      overflow: visible;
    }
  }

  &__automatically-identified-tooltip {
    margin-right: 5px;
    margin-top: -4px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $grey-0;
    border-radius: 100px;
    padding: 1px 6px;
    color: $black;
    font-size: $font-size-sm;
    height: 25px;
    width: 25px;
    font-weight: 700;
  }

  & .element-annotation-internal-reference-group,
  & .element-annotation-workpaper-reference,
  & .element-annotation-tickmark,
  & .element-annotation-formula {
    padding: 0 $gutter-xxxs;
  }
  & .element-annotation-internal-reference-group {
    color: $blue-11;
  }
  & .element-annotation-internal-reference-group {
    &--flagged {
      color: $orange;
    }
  }
  & .element-annotation-formula {
    color: #046a38;
  }

  & .element-annotation-formula {
    &--flagged {
      color: $orange;
    }
  }

  & .element-annotation-tickmark {
    color: #62b5e5;
  }

  & .element-annotation-comfort-letter-label {
    color: red;
  }

  & .element-annotation-formula {
    &--flagged {
      color: $orange;
    }
  }

  & .element-annotation-workpaper-reference {
    display: inline-block;
    color: #da291c;
  }
}
