.tieout-element-ocr, .tieout-element-ocr-left {
   cursor: pointer;
  &__hover-effect {
    background-color: $hover-grey-new !important;
    opacity: 0.6;
  }
  
  &_side-by-side-map-success{
    background-color: $side-by-side-map-success;
  }

  &_side-by-side-map-failure{
    background-color: $side-by-side-map-failure;
  }
  &__hover-effect-left {
    background-color: $hover-grey-new !important;
    opacity: 0.6;
    border-bottom: 1px solid $black !important;
  }

  &:active {
    background-color: $active-grey-new;
    opacity: 0.6;
  }

  &--disabled{
    &:hover {
      cursor: not-allowed !important;
    }    
  }

  &--selected {
    &--default {
      background-color: $active-grey-new;
    }

    &--report-preview {
      background-color: $grey-9;
    }

    &--blackline-view {
      background-color: #FFCD0073;
    }
  }

  &--highlighted {
    background-color: $grey-10;
  }

  &__added {
    background-color: $green-6;
    opacity: 0.45;
  }

  &__verified {
    background-color: $element-status-verified;
    border-bottom: $border-dashed-blue;
  }

  &__reviewed {
    background-color: $element-status-reviewed;
    border-bottom: $border-solid-green;
  }

  &__unverified {
    background-color: $element-status-unverified;
    border-bottom: $border-dotted-yellow;
  }

  &--comfort-assigned{
    outline: 1px solid $red;
  }  
}

.tieout-element-ocr{
  &--cursor-disabled {
    &:hover {
      cursor: not-allowed !important;
    }
  }
  &--cursor-alias {
    &:hover {
      cursor: alias !important;
    }
  }
}

.side-by-side-selected-element{
  background-color: $side-by-side-element-selection;
  border: 1px solid $side-by-side-element-border;
  padding: 5px;
  position: relative;
  z-index: 48;
  text-indent: 0%;
}

.side-by-side-element-identifier{
  position: relative; 
  z-index: 49;
  text-indent: 0%;
  padding: 5px;

  &__count{
    position: absolute;
    border-radius: 50%;
    background-color: $side-by-side-identifier;
    color: $white;
    font-size: 7px;
    max-width: max-content;
    min-width: 15px;
    text-align: center;
    cursor: alias;
    top: -20px;
    right: -13px;
    line-height: 14px;
    width: fit-content;
    font-weight: 600;
  }
}