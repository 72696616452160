.batch-tickmark-section {
  & .attach-tickmark {
    padding: 0;
  }
  & > .tickmark-entry {
    margin-bottom: 0;
    margin-top: $gutter-md;
  }
  & > .tickmark-entry {
    margin-bottom: 0;
    margin-top: $gutter-md;
  }

  & .tickmark-entry__top {
    align-items: center;
  }

  & .tickmark-entry__detach {
    width: $gutter-md + 5;
    height: $gutter-md + 5;
    border-radius: 50%;
    padding: $gutter-xxs;
    cursor: pointer;
    .icon-path {
      fill: $primary-blue;
    }
    &:focus,
    &:hover {
      background-color: $hover-blue;
      .icon-path-inner {
        fill: $white;
      }
    }
    &:active {
      background-color: $active-blue;
      .icon-path-inner {
        fill: $white;
      }
    }

    &[disabled] svg {
      background-color: transparent;
      cursor: not-allowed;
      & .icon-path-outer {
        stroke: $grey-1;
      }
    }
  }
}
