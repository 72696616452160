.chatbot-panel {
  .banner__success {
    padding: 5px;
  }

  .panelHeader {
    padding: 10px;
  }

  .banner__body {
    font-size: 10px;
  }

  &__title {
    display: flex;
    align-items: flex-ends;
    font-weight: $font-w-semi-bold;
  }

  &__body {
    position: relative;
    height: 100%;
  }

  &__banner {
    svg {
      display: none;
    }
  }

  &__info_icon {
    display: inline;
    margin-inline: 4px auto;
  }

  &__chat-window {
    height: 100%;
    width: 100%;
    position: relative;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__clear-session-btn {
    font-weight: 800;

    &:hover {
      background-color: white;
    }

    &:focus {
      background-color: white;
    }

    &:active {
      background-color: white;
    }
  }

  &__chat-section {
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow-y: auto;
    font-size: 10px;
    @include scroll-bar();
  }

  &__chat-not-available-message {
    font-size: 12px;
    font-weight: $font-w-semi-bold;
    color: $grey-5;
  }

  &__guidance-message {
    height: 100%;
  }

  &__secondary-guidance-message {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__placeholder-title {
    font-weight: $font-w-semi-bold;
    padding: 10px 0;
    font-size: $font-size-md;
  }

  &__placeholder {
    font-size: $font-size-xs;
    font-weight: $font-w-semi-bold;
    color: $grey-5;
  }

  &__chat-footer-section {
    position: absolute;
    bottom: 0px;
    width: calc(100% - 54px);
    background-color: #ffffff;
    padding: 5px 10px;
  }

  &__chat-input {
    display: flex;
    align-items: baseline;
  }

  &__text-area {
    width: 100%;
  }

  &__submit-btn {
    width: 50px;
    display: flex;
    justify-content: end;
  }

  &__chat-submit-btn {
    cursor: pointer;

    &--disabled {
      path {
        fill: $white;
      }

      rect {
        fill: $disabled-grey;
      }

      cursor: not-allowed;
    }
  }

  &__word-count {
    float: right;
    padding-right: 40px;
    font-size: 10px;
  }

  &__btn {
    width: 100%;
    justify-content: center;
    padding: 8px 16px;
    font-size: 10px;
  }

  .panelContent {
    overflow: hidden;
  }

  &__message-bubble {
    display: flex;
    align-items: center;
    flex-direction: row;
    padding: 8px;
    border-radius: 10px;
    max-width: 100%;

    &--citation-container {
      margin-top: 10px;
    }

    &--citation-content {
      line-height: 1.6;
    }

    &--message-response-title {
      margin-bottom: 10px;
      width: 100%;
      font-weight: $font-w-semi-bold;
    }

    &--message-citation-title {
      margin-top: 10px;
      width: 100%;
      font-weight: $font-w-semi-bold;
    }

    &--page-link {
      color: $blue-2;
      font-weight: $font-w-semi-bold;
    }

    &--page-link:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }

  &__user {
    flex-direction: row-reverse;
  }

  &__bot {
    text-align: left;
  }

  &__message-content-user {
    background-color: #F7F7F7;
  }

  &__message-content-bot {
    background-color: #F1F8FB;
  }

  &__initials {
    min-width: 24px;
    min-height: 24px;
    border-radius: 50%;
    background-color: $black;
    color: $white;
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: baseline;
    font-size: 10px;
    font-weight: 600;
    margin-right: 10px;
  }

  &__user-initials {
    background-color: $white;
    border: 1px solid #D0D0CE;
    color: $black;
    margin-left: 10px;
    margin-right: 0;
  }

  &__message-content {
    padding: 5px 7px;
    border-radius: 2px;
  }

  &__feedback-section {
    display: flex;
    justify-content: space-between;
  }

  &__response-type {
    cursor: pointer;
    display: flex;
    align-items: center;
    color: $blue-2;
    padding: 5px 0px;
    border-radius: 5px;

    &--highlight {
      color: $grey-5;
      cursor: default;
    }
  }

  &__like-icon,
  &__dislike-icon {
    margin-right: 5px;

    &:hover {
      rect {
        fill: $hover-grey;
      }
    }
  }

  &__icon-highlight {
    &:hover {
      rect {
        fill: $blue-2;
      }
    }

    path {
      fill: $white;
    }

    rect {
      fill: $blue-2
    }
  }
}

.chatbot-tooltip {
  &.show {
    font-family: $font-family-default;

    .tooltip-text-container {
      font-weight: normal;
      line-height: normal;
      padding: 5px 0;

      .main-title {
        font-weight: $font-w-semi-bold;
      }

      .title-description {
        padding-left: 20px;

        .section {
          padding-top: 5px;

          .heading {
            padding: 0 0 2px 0;
            font-weight: $font-w-semi-bold;
          }

          li {
            list-style-type: disc;
            color: $white;
          }
        }
      }
    }
  }
}