@import 'variables/global-variables.scss';
@import 'variables/colors-variables.scss';
@import 'mixins/common/scroll-bar-mixins.scss';

html {
  font-size: $font-size-default;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  @include scroll-bar();
}

* {
  box-sizing: border-box;
  font-family: $font-family-default;
  margin: 0;
  padding: 0;

  &[disabled] {
    cursor: not-allowed;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

p {
  margin: 0;
}

ul {
  list-style-type: none;
}

textarea:focus,
input:focus {
  outline: none;
}

input::-ms-clear {
  display: none;
}
