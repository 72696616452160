.left-panel {
    background-color: #f7f7f7;

    &__wrap{
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 10px;
      @include scroll-bar();
      overflow: auto;
      height: calc(100vh - 193px);
      background-color: #f7f7f7;
    }

    &__split{
        width: 50%; 
    }

    &__subheader{
      height: 48px;
      width: 100%;
      background-color: $white;
      box-shadow: 0 0 4px 0 $border-grey;
      border-bottom: $expandable-row-border;
      font-weight: $font-w-semi-bold;
      font-size: $font-size-default;
      color: #212121;
      
      &__left {
        padding: 12px 16px;
        width: 87%;
      }
      &__right {
        padding: 12px  0 12px 47px;
      }
    }

    &__container-section{
      height: 100%;
      display: flex;
      align-items: center;
    }

    &__section{
      width: 450px;
      margin: auto;
    }
    &__icon{
      display: flex;
      justify-content: center;
      padding: 5px;
    }
    &__title{
      font-weight: $font-w-bold;
      text-align: center;
      padding: 5px;
    }
    &__subtitle{
      color: #a3a3a3;
      font-size: $font-size-sm;
      text-align: center;
      padding: 5px;
    }
    &__tab{
      color: #00a3e0;
      margin: 5px 0;
      width: 100%;
      border: 1px solid #00a3e0;
      display: flex;
      align-items: center;
      cursor: pointer;
    }
    &__left-tab{
      width: 50%;
      padding: 6px 0;
      text-align: center;
    }
    &__right-tab{
      width: 50%;
      padding: 6px 0;
      text-align: center;
    }
    &__active{
      background-color: #00a3e0;
      color: #ffffff;
    } 
    &__save{
      margin: 5px 0;
      width: 100%;
      justify-content: center;
    }
    &__container__left{
      background-color: #f7f7f7;
      position: relative;  // position relative is necessary here, since we are using z-index for the div
      z-index: $z-index-overlay + 1; // so that statment nav for right statement does not slides into left statement
    }
}
