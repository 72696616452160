.omnia-linking-confirm-modal {
  &__row-1 {
    display: grid;
    grid-template-columns: 1.1fr 1fr 1fr;
    grid-gap: $gutter-md + 2;
    margin-top: $gutter-md;

    & > div > div {
      display: flex;
      align-items: center;
    }
  }

  &__row-2 {
    margin: $gutter-md 0;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: $gutter-md + 2;
  }

  &__label {
    font-size: $font-size-sm;
    color: $grey-5;
    font-size: $gutter-md;
    display: inline-block;
    margin-bottom: $gutter-xxxs;
  }

  &__omnia-icon {
    margin-right: $gutter-xxs;
  }
}

.banner__body {
  font-size: $gutter-sm + 2;
}
