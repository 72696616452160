.element-search-entry {
  @include comfort-assign-element();
  padding: $gutter-md;
  &:hover {
    background-color: $hover-grey;
    cursor: pointer;
  }
  &__view-details-section-name-wrapper{
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__view-element-details-button{
    background-color: transparent;
    &:hover {
      background-color: $hover-grey;
    }
    &:active {
      background-color: $active-grey;
    }
  }

  &__section-name {
    display: block;
    font-size: $font-size-sm;
    color: $grey-5;
    @include overflow-ellipsis;
    &--active {
      white-space: normal;
    }
  }
  &__element-label {
    @include overflow-ellipsis;
    display: block;
    font-size: $font-size-sm;
    color: $black;
    .highlight {
      background-color: $yellow-2;
    }
  }
  &__element-value {
    @include hyperlink-button-for-element;
    margin-right: $gutter-xxs;
    font-size: $font-size-md;
    font-weight: $font-w-semi-bold;
    display: flex;
    text-align: left;
    outline: none;
    .highlight {
      background-color: $yellow-2;
    }
  }

  &--selected {
    &--default {
      background-color: $active-grey;
    }
    &--opened {
      background-color: $blue-8;
    }
    .highlight {
      background-color: $yellow;
    }
  }
  &__annotations {
    font-size: $font-size-sm;
    color: $black;
    @include overflow-ellipsis;
    white-space: normal;
    &--active {
      & .element-annotation-workpaper-reference {
        @include overflow-ellipsis;
        width: $statement-nav-elements-search-entry-wpr-width;
        color:#DA291C;
      }
    }
  }

  & .element-annotation-internal-reference-group,
  & .element-annotation-workpaper-reference,
  & .element-annotation-tickmark,
  & .element-annotation-formula {
    padding: 0 $gutter-xxxs;
  }

  & .element-annotation-workpaper-reference{
    color:#DA291C;
  }

  & .element-annotation-internal-reference-group{
    color: $blue-11;
  }

  & .element-annotation-internal-reference-group {
    &--flagged {
      color: $orange;
    }
  }
  & .element-annotation-tickmark{
    color: #62B5E5;
    }

    & .element-annotation-formula {
      color:#046A38;
    }

  & .element-annotation-formula {
    &--flagged {
      color: $orange;
    }
  }
  & .element-annotation-comfort-letter-label{
    color: red;
  }

  &__element-value-wrapper {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }

  & svg {
    width: $gutter-md;
    margin-left: $gutter-xxs;
  }
}
