.statement-content-segment {
  &__unrendered {
    background-color: $grey-1;
  }
  & .content-search-highlight {
    background-color: $yellow-2;
  }
  & cfto-parser-anchor {
    :not(:last-child) {
      & .section-anchor__section-reviewers {
        display: none;
      }
    }
  }
}
