.non-audit-textarea {
  &__header {
    margin: $gutter-md 0;
    font-weight: $font-w-semi-bold;
  }

  &__client-name,
  &__engagement-name,
  &__dropdowns {
    margin: $gutter-md 0;
  }

  &__dropdowns {
    display: flex;
    & > .form-item {
      margin-right: $gutter-md;
    }
  }
}
