.user-settings {
    &__label {
        color: $label-grey;
        padding-bottom: $gutter-xs;
        font-weight: $font-w-bold;
        font-size: $gutter-sm;
      }
      &__additional-information-message{
        margin-top: $gutter-md;
      }
.menu {
    max-height: $copy-statement-dropdown-menu-height !important;
  }
}