.statement-progress-bar {
  width: 90%;
  height: $gutter-sm;
  border: 1px solid $border-grey;
  border-radius: $border-radius-default;
  &__inner {
    height: 100%;
    background-color: $statement-progress-blue;
    border-radius: $border-radius-default;
  }
}
