.tree-view {
  font-family: $font-family-default;
  font-size: $font-size-sm;
  user-select: none;

  &__arrow {
    min-width: $gutter-sm;
    margin-right: $gutter-xxs;
    display: inline-block;
    cursor: pointer;

    & svg {
      fill: black;
      height: 10px;
      width: 10px;
    }

    &--collapsed {
      & svg {
        transform: rotate(-90deg);
      }
    }
  }

  &__item {
    white-space: nowrap;
    cursor: pointer;
    padding: $gutter-xxs;
    margin-bottom: $gutter-xxs;
    display: grid;
    grid-template-columns: 1fr auto auto;
    align-items: center;

    &--col1 {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-right: $gutter-xxs;
    }

    &-actions {
      display: none;
      &__disabled{
        display: none;
      }
    }

    &:hover {
      background-color: $hover-grey;
      text-decoration: underline;

      .tree-view__item-actions {
        display: block;
        background-color: $grey-2;
        border-radius: 1.25em 0em 0em 1.25em;
        & svg {
          margin: 0px;
          width: 2.5em;
          height: 2.5em;
          &:hover {
            background-color: $grey-3;
            border-radius: 0.25em 0.25em 0.25em 0.25em;
          }
        }
      }
      .tree-view__item-actions__disabled {
          display: block;
          background-color: $grey-2;
          border-radius: 1.25em 0em 0em 1.25em;
          cursor: not-allowed;
          & svg {
           margin: 0px;
           width: 2.5em;
           height: 2.5em;
          & path {
            fill: $grey-3;
          }
         }
       }
      
      .tree-view__item--col2 {
        display: none;
      }
    }

    &--expanded {
      color: $blue-1;

      & svg {
        fill: $blue-1;
      }
    }

    &--scroll-highlighted {
      background-color: $grey-2;
    }

    &--highlighted {
      background-color: $yellow-2;
    }
    &--new-item {
      background-color: $transparent-blue;
      background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%2300A3E0FF' stroke-width='1' stroke-dasharray='5%2c 2' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
    }
  }

  &__children--collapsed {
    height: 0px;
  }

  &__children {
    & .tree-view__item {
      padding-left: $gutter-md;
    }
    & .tree-view__children {
      .tree-view__item {
        padding-left: $gutter-md * 2;
      }
      & .tree-view__children {
        .tree-view__item {
          padding-left: $gutter-md * 3;
        }
        & .tree-view__children {
          .tree-view__item {
            padding-left: $gutter-md * 4;
          }
          & .tree-view__children {
            .tree-view__item {
              padding-left: $gutter-md * 5;
            }
            & .tree-view__children {
              .tree-view__item {
                padding-left: $gutter-md * 6;
              }
              & .tree-view__children {
                .tree-view__item {
                  padding-left: $gutter-md * 7;
                }
                & .tree-view__children {
                  .tree-view__item {
                    padding-left: $gutter-md * 8;
                    & .tree-view__children {
                      .tree-view__item {
                        padding-left: $gutter-md * 9;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
