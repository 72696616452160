.checkbox-list {
  width: 100%;
  display: flex;
  flex-direction: column;
  &__checkbox-container {
    margin-bottom: $gutter-md;
  }
  &__master-checkbox {
    margin-bottom: $gutter-md;
  }
}
