.element-status {
  &__checkbox-group {
    margin: -$gutter-xxs $gutter-xxs 0 $gutter-xxs;
    font-size: $font-size-md;
    display: flex;
  }
  &__checkbox-container-review{
    display: flex;
  }
  &__info-icon {
    margin-left: $gutter-xxs;
  }
}
