.project-details-users {
  &__user-container {
    width: 100%;
  }

  &__row {
    display: flex;
    flex-wrap: nowrap;
    margin-bottom: $default-gutter;
    width: 84%;
  }

  &__col {
    width: 25%;
    padding-right: $gutter-md;
  }

  &__label {
    color: $label-grey;
    margin-bottom: $gutter-xs;
    display: inline-block;
  }

  &__value {
    overflow-wrap: break-word;
  }

  &__engagement-info {
    display: flex;
    & > div:first-child {
      width: 182px;
      padding-right: $gutter-md;
    }
    & > div:nth-child(2) {
      width: 182px;
      padding-right: $gutter-md;
    }
  }

  &__omnia-engagement {
    & > a {
      text-decoration: none;
      @include hyperlink-button;
      display: block;
      max-width: 300px;
    }
  }

  &__omnia-icon {
    margin-right: $gutter-xxs;
    display: inline-block;
  }

  &__arrow-icon {
    margin-left: $gutter-xxs;
    position: relative;
    bottom: -$gutter-xxs;
    border-radius: 50%;
    cursor: pointer;
  }

  &__engagement-name-title {
    display: flex;
    flex-wrap: nowrap;
  }
}
