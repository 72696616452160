.recent-updates {
  margin-left: $gutter-md;
  &__content {
    overflow-y: auto;
    max-height: 500px;
    list-style-position: inside;
    ul,
    ol {
      margin-left: $gutter-xxs;
      list-style-type: decimal; /* Use "decimal" for numbers (1, 2, 3,...) */
    }
    ol ol {
      list-style-type: lower-alpha; /* Use "lower-alpha" for lowercase letters (a, b, c,...) */
    }
    ol ol ol{
      // list-style-type: upper-alpha; /* Use "upper-alpha" for uppercase letters (A, B, C,...) */
      list-style-type: upper-roman; /* Use "upper-roman" for uppercase Roman numerals (I, II, III,...) */
    }
    ol ol ol ol {
      // list-style-type: lower-roman; /* Use "lower-roman" for lowercase Roman numerals (i, ii, iii,...) */
      list-style-type: upper-roman; /* Use "upper-roman" for uppercase Roman numerals (I, II, III,...) */
    }
    ol ol ol ol ol{
      list-style-type: upper-roman; /* Use "upper-roman" for uppercase Roman numerals (I, II, III,...) */
    }
    li {
      margin-left: 15px;
    }
    ul {
      list-style-type: disc;
      margin-left: 20px;
    }
    ul ul{
      list-style-type: disc;
      margin-left: 15px;
    }
  }

  &__content-update {
    margin-top: $gutter-xs;
  }

  &__modal {
    & * {
      font-size: calc(#{$font-size-default} + 1);
    }
  }
}
