.preview-report-selectedable-element {
  &--selected {
    background-color: $grey-9;
  }
  &__annotation-callout {
    &__callout-container {
      font-weight: $font-w-regular;
      text-indent: 0%;
      background-color: #ffffff;
      border-radius: 3px 2px 2px 3px;
      border: 1px solid #0097a9;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
      min-width: 15px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      &--left {
        border: 1px solid $border-grey;
        background-color: $grey-2;
        padding: 0px 0.5px;
      }

      &--left::before {
        content: '';
        top: 0;
        right: 0;
        left: 99%;
        width: 6px;
        height: 100%;
        background-color: $border-grey;
        position: absolute;
        clip-path: polygon(100% 50%, 0 0, 0 100%);
      }

      &--left::after {
        content: '';
        top: 1px;
        right: -1px;
        left: 98%;
        width: 5px;
        height: calc(100% - 2px);
        background-color: $grey-2;
        position: absolute;
        clip-path: polygon(100% 50%, 0 0, 0 100%);
      }

      &--right {
        transform: translateX(100%);
      }
    }
  }
}
