.annotation-details {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;

  &__contents{
    font-size: $font-size-xs;
    &:not(:last-of-type) {
      display: inline-block;
      padding-right: $gutter-xxs !important;
      margin-right: $gutter-xxs;
      font-size: $font-size-xs;
      line-height: 1;
      flex-shrink: 0;
      border-right: 1px solid black;
    }
  }

  & .element-annotation-internal-reference-group,
  & .element-annotation-workpaper-reference,
  & .element-annotation-tickmark,
  & .element-annotation-formula {
    padding: 0 $gutter-xxxs;
    
  }
  & .element-annotation-internal-reference-group{
    color:$blue-11;
  }
  & .element-annotation-internal-reference-group {
    &--flagged {
      color: $orange;
    }
  }
  & .element-annotation-formula {
    color:#046A38;
  }

  & .element-annotation-formula {
    &--flagged {
      color: $orange;
    }
  }
  
  & .element-annotation-tickmark{
  color: #62B5E5;
  }

  & .element-annotation-comfort-letter-label{
    color: red;
  }
  
  & .element-annotation-workpaper-reference {
    display: inline-block;
    color:#DA291C;
  }
}
