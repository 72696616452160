.message {
  &__success {
    @include message(
      $border-radius-default,
      $success-border-green,
      $success-background-green
    );
  }

  &__information {
    @include message(
      $border-radius-default,
      $information-border-blue,
      $information-background-blue
    );
  }

  &__guidance {
    @include message(
      $border-radius-default,
      $guidance-border-teal,
      $guidance-background-teal
    );
  }

  &__warning {
    @include message(
      $border-radius-default,
      $warning-border-yellow,
      $warning-background-yellow
    );
  }

  &__error {
    @include message(
      $border-radius-default,
      $error-border-red,
      $error-background-red
    );
  }

  &__header {
    padding-bottom: $gutter-xs;
    display: flex;
  }

  &__close {
    @include close-button();
  }

  &__icon {
    margin-right: $gutter-xs;
    vertical-align: bottom;
  }

  &__title {
    display: inline;
    font-size: $font-size-ml;
    font-weight: $font-w-bold;
  }

  &__body {
    font-size: $font-size-sm;
  }
}
