.batch-panel {
  &__title {
    font-weight: $font-w-semi-bold;
  }

  &__body {
    padding-bottom: 100px; // lets menus be able to be scrolled
  }

  &__guidance {
    margin: $gutter-md $batch-panel-left-spacing;
    font-size: $font-size-sm;
  }

  &__radio-label {
    font-size: $font-size-md;
    font-weight: $font-w-semi-bold;
    margin-bottom: $gutter-xs;
    display: flex;
  }
  &__info-icon {
    margin-left: $gutter-xs;
  }

  &__radio {
    margin-bottom: $gutter-xs;
    font-size: $font-size-sm;
  }

  &__cancel {
    margin-left: auto;
    margin-right: $gutter-md;
  }
  &__exclude-element-button {
    margin: $gutter-md;
    font-size: $font-size-md;
    & svg {
      width: $gutter-md;
    }
  }
  &__row {
    display: flex;
    padding: $gutter-xs;
    align-items: center;
    &:last-child {
      width: 100%;
      padding-right: 0;
    }
  }
  &__col {
    width: 100%;
    margin: 0 $gutter-xxs 0 $gutter-xxs;
  }
  &__amount-attr-body {
    background: $grey-1;
  }
  &__elements {
    max-height: 300px;
    overflow-y: auto;
    @include scroll-bar();
    margin-right: $gutter-sm;
  }
}
