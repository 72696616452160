.manage-users {
    &__added-users {
        .data-grid__body{
            max-height: none !important;
            .data-grid__row{
                height: unset !important;
                overflow-wrap: break-word;
                .data-grid__col{
                    .admin-module-api-switch{
                        &__switch-container{
                            display: flex;
                            align-items: center;
                        }

                        &__pencil-icon{
                            margin-left: 10px;
                            cursor: pointer;
                        }
                       
                    }
                }
            }
        }
    }
}