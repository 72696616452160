.statement-content-panel-ocr {
  height: 100%;

  &__document {
    height: 100%;
    overflow: auto;
    @include scroll-bar();
  }

  &__page-container {
    margin: 0 auto;
    padding: 1rem;
    box-sizing: unset;
  }

  &__page {
    background-color: $white;
    box-shadow: 0px 0px 10px 0px rgba($black, 0.3);
    position: relative;

    &--loading {
      padding-top: 200px;
    }
  }

  &__overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    user-select: none;
    cursor: text;
    outline: none;
  }

  &__context-menu {
    position: absolute;
    background: $white;
    border: 1px $border-gray;
    box-shadow: $box-shadow-4;
    border-radius: $border-radius-default;
    white-space: nowrap;
    user-select: none;

    & li {
      &:hover {
        background-color: $hover-grey;
      }

      button {
        @include remove-default-button-styling;
        padding: $gutter-sm;
      }
    }
  }

  &__callout-container {
    background-color: #ffffff;
    padding: 0px;
    border-radius: 1px;
    border: 1px solid #0097a9;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    min-width: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    .tieout-element {
      display: flex;
      flex-wrap: wrap;

      .element-annotation-workpaper-reference {
        margin-bottom: 0px;
        word-break: break-all;
        display: inline-block;
        max-width: 120px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .element-annotation-tickmark-reference {
        margin-bottom: 0px;
        word-break: break-all;
        display: inline-block;
        max-width: 120px;
        white-space: initial;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      &__preview-sequence-id {
        padding: 0 0.5px;
        overflow: unset;
      }
    }

    .element-annotation {
      font-size: 9px;
      padding: 0px .5px;
      overflow: unset;
    }

    &--left::before {
      content: '';
      top: 0;
      right: 0;
      left: 100%;
      width: 5px;
      height: 100%;
      background-color: #0097a9;
      position: absolute;
      clip-path: polygon(100% 50%, 0 25%, 0 75%);
    }

    &--left::after {
      content: '';
      top: 1px;
      right: -1px;
      left: 98%;
      width: 4px;
      height: calc(100% - 2px);
      background-color: #ffffff;
      position: absolute;
      clip-path: polygon(100% 50%, 0 25%, 0 75%);
    }

    &--right {
      transform: translateX(100%);
    }

    &--right::before {
      content: '';
      top: 0;
      right: 100%;
      left: 0px;
      width: 5px;
      height: 100%;
      background-color: #0097a9;
      position: absolute;
      transform: translateX(-100%);
      clip-path: polygon(0 50%,100% 75%,100% 25%);
    }

    &--right::after {
      content: '';
      top: 1px;
      right: 98%;
      left: 1px;
      width: 4px;
      height: calc(100% - 2px);
      background-color: #ffffff;
      position: absolute;
      transform: translateX(-100%);
      clip-path: polygon(0 50%,100% 75%,100% 25%);
    }

    &--top::before {
      content: '';
      top: 100%;
      left: 50%;
      transform: translate(-50%, 0%);
      width: 12px;
      height: 4px;
      background-color: #0097a9;
      position: absolute;
      clip-path: polygon(50% 100%,75% 0,25% 0);
    }

    &--top::after {
      content: '';
      top: 97%;
      left: 50%;
      transform: translate(-50%, -4%);
      width: 8px;
      height: 3px;
      background-color: #ffffff;
      position: absolute;
      clip-path: polygon(50% 100%,75% 0,25% 0);
    }

    &--bottom::before {
      content: '';
      bottom: 100%;
      left: 50%;
      transform: translate(-50%, 0%);
      width: 12px;
      height: 4px;
      background-color: #0097a9;
      position: absolute;
      clip-path: polygon(50% 0,75% 100%,25% 100%);
    }

    &--bottom::after {
      content: '';
      bottom: 95%;
      left: 50%;
      transform: translate(-50%, -4%);
      width: 8px;
      height: 3px;
      background-color: #ffffff;
      position: absolute;
      clip-path: polygon(50% 0,75% 100%,25% 100%);
    }
  }

  &__annotation-indicator--left {
    transform: rotate(-90deg);
  }

  &__annotation-indicator--right {
    transform: rotate(90deg);
  }

  &__annotation-indicator--bottom {
    transform: rotate(180deg);
  }

  &--left-statement-cursor {
    cursor: alias;
  }

  &--right-statement-selection-cursor {
    cursor: alias;
  }

  &--right-statement-cursor {
    cursor: not-allowed;
  }
}
