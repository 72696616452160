.virtualized-list {
  @include scroll-bar();
  height: 100%;
  overflow-y: auto;

  &__inner {
    min-height: 100%;
    position: relative;
    width: 100%;
  }

  &__in-view {
    position: absolute;
    width: 100%;
  }
}