.blackline_view_changes{
    &--added{
        background-color: $blackline-added-color;
        opacity: 0.5;
    }
    &--changed{
        background-color: $blackline-changed-color;
        opacity: 0.5;
    }
    &--deleted{
        background-color: $blackline-deleted-color;
        &::before{
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            width: 100%;
            height: 0.5px;
            background-color: #da291c;
            transform: translate(-50%)
        }
    }
    &--added-border{
        outline: 2px solid $blackline-added-color;
    }
    &--changed-border{
        outline: 2px solid $blackline-changed-color;
    }
    &--deleted-border{
        &::before{
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            width: 100%;
            height: 0.5px;
            background-color: #da291c;
            transform: translate(-50%)
        }
    }

    &--hover{
        background-color: $hover-grey-new;
        opacity: 0.6;
        border-bottom: 1px solid #000;
        cursor: pointer;
    }
    &--selected {
        &--blackline-view {
          background-color: #FFCD0073;
          opacity: 1;
        }
      }
}