.internal-reference-panel-suggested-references {
  min-height: $min-suggested-references-height;
  border-top: $suggested-reference-border;
  border-bottom: $suggested-reference-border;
  padding: $gutter-md;

  &__directions-text {
    font-size: $font-size-sm;
  }

  &__title {
    margin: $gutter-md 0;
    font-weight: $font-w-semi-bold;
    font-size: $font-size-sm;
  }

  &__empty-text {
    color: $grey-4;
    font-size: $font-size-sm;
    padding: 16px;
  }

  .expandable-row {
    border: $border-gray;
  }
  .expandable-row__body {
    border-top: $border-gray;
    padding: 0;
    max-height: $max-suggested-references-height;
    overflow-y: auto;
  }
}

.internal-reference-suggestion-element-entry {
  padding: $gutter-xs $gutter-sm;
  display: flex;
  justify-content: space-between;
  &--selected {
    background-color: $grey-2;
  }
  &:hover {
    background-color: $hover-grey;
    cursor: pointer;
  }

  &__text-container {
    width: 80%;
  }

  &__value-container {
    font-weight: $font-w-semi-bold;
    color: $blue-1;
    font-size: $font-size-md;
    width: max-content;
    &--flagged {
      color: $orange;
    }
  }

  &__section-name {
    @include overflow-ellipsis;
    display: block;
    font-size: $font-size-sm;
    color: $grey-4;
  }

  &__element-label {
    @include overflow-ellipsis;
    display: block;
    font-size: $font-size-sm;
    &--flagged {
      color: $orange;
    }
  }

  &__button-container {
    display: flex;
    align-items: center;
    max-width: 25%;
  }

  &__add-link {
    margin-left: auto;
    cursor: pointer;
    @include remove-default-button-styling;

    svg {
      .plus-circle,
      .plus-plus {
        fill: $primary-blue;
      }
    }

    &:hover svg {
      .plus-circle-fill {
        fill: $primary-blue;
      }
      .plus-circle,
      .plus-plus {
        fill: $white;
      }
    }

    &:active svg {
      background-color: $active-blue;
      .plus-circle {
        fill: $white;
      }
    }
  }

  &__remove-suggestion {
    padding: $gutter-xxs;
    margin-top: $gutter-xs;
    cursor: pointer;
    color: $red;
    font-weight: bold;
  }
}
