.statement-summary-elements-display {
  display: flex;
  justify-content: center;
  height: calc($gutter-ml + 1px);
  border: $border-dark-grey;
  border-radius: $border-radius-default;
  cursor: pointer;

  &--icon {
    display: flex;
    align-items: center;

    &--disabled {
      background-color: $disabled-button-background-grey;

      svg path {
        fill: $disabled-text;
      }

      &:hover {
        cursor: not-allowed;
      }
    }
  }
}