.tickmark-list {
  &__entry {
    padding: $gutter-md;
    border-bottom: $border-gray-light;
    margin: 0;
    &__elements {
      margin-top: $gutter-sm;
      font-size: $font-size-md;
    }

    &__edit {
      margin-right: $gutter-xs;
    }

    &__edit,
    &__delete {
      @include icon-button-with-one-path;
    }
  }
}
