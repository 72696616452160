.monitoring {
  &__header {
    font-weight: 600;
    padding: 15px;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    border: 1px solid #c4c4c4;
    border-radius: 2px 2px 0 0;
    margin-bottom: 10px;
  }

  &__drop-down {
    display: flex;
    align-items: center;
  }

  &__drop-down-label {
    margin-right: 10px;
  }

  &__added-countries {
    margin-top: 10px;

    .data-grid__body > :first-child {
      display: flex;
      padding: 8px 16px;
      align-items: center;
      position: sticky;
      top: 16px;
      background-color: #ffffff;
      z-index: 25;
    }
  }
}
