.comfort-letter-entry {
    margin-bottom: $gutter-sm;
    position: relative;
  
    &__top {
      display: flex;
      justify-content: space-between;
    }
  
    &__info {
      display: flex;
      flex-direction: column;
    }
    &__custom-label {
      font-weight: $font-w-semi-bold;
      margin-bottom: $gutter-xxs;
    }
    &__custom-label,
    &__body {
      display: block;
      font-size: $font-size-md;
    }
  
    &__body {
      word-break: break-word;
      hyphens: auto;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        &--show-less{
          overflow: visible;
          -webkit-line-clamp: unset;
        }
      ul,
      ol {
        list-style-position: inside;
        margin-left: $gutter-xxs;
      }
      ul {
        list-style-type: disc;
      }
    }
    .button {
      width: fit-content;
    }
    &__actions {
      display: flex;
    }
    
    &__top-right {
      position: absolute;
      top: $gutter-md;
      right: $gutter-md;
    }
  }
  