.internal-reference-panel-listing {
  padding: $gutter-md 0;

  &__title-container {
    margin: 0 $gutter-md $gutter-md;
    margin-bottom: $gutter-md;
  }

  &__title {
    font-weight: $font-w-semi-bold;
    font-size: $font-size-md;
    &--flagged {
      color: $orange;
    }
  }

  &__subtitle-container {
    margin-top: $gutter-xs;
  }

  &__subtitle {
    font-size: $font-size-xs;
  }

  &__empty-title {
    margin: 0 $gutter-md $gutter-md;
    margin-bottom: $gutter-md;
    font-size: $font-size-md;
  }

  &__copy-all-annotation-class{
    margin: $gutter-md $gutter-md 0 0;
  }
}
