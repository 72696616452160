.statement-info {
  display: flex;
  flex-wrap: nowrap;
  &__step {
    width: $progressBarStepWidth;
  }

  &__card {
    width: $progressBarCardWidth;
  }

  &__title {
    font-size: $font-size-xl;
  }

  &__banner {
    margin-top: $gutter-ml;
  }

  &__statement-name.form-item,
  &__input-row {
    margin-top: $gutter-md;
    & .menu__optionlabel {
      color: $grey-4;
    }
    .search__container {
      .search__bar {
        .search {
          padding: 0.3rem;
          .search__input {
            white-space: nowrap;
            text-overflow: ellipsis;
          }
        }
        .search__search-button {
          padding: 0.41rem;
        }
      }
    }
  }

  &__input-row {
    display: flex;
    align-items: baseline;
    & .form-item {
      margin-right: 2%;
    }
    & .form-item:last-child {
      margin-right: 0;
    }
  }

  &__checkbox {
    margin-top: $gutter-md;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
  }

  &__banner {
    margin-top: $gutter-xs;
  }

  &__information-icon {
    margin-left: $gutter-xs;
  }

  &__legalhold-info {
    font-size: $font-size-md;
    color: $grey-6;
    margin-left: 2.2rem;
  }
}
