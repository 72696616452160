.tieout-element,
.tieout-element-left {
  @include hyperlink-button-for-element;
  @include comfort-assign-element();

  font-size: inherit;
  font-weight: inherit;
  text-align: unset; // this style is necessary to preserve original text-alignment for manually created elements
  white-space: pre-wrap;

  &--cursor-disabled {
    &:hover {
      cursor: not-allowed !important;
    }
  }

  &--cursor-alias {
    &:hover {
      cursor: alias !important;
    }
  }

  &--disabled {
    opacity: 0.6;

    &:hover {
      cursor: not-allowed !important;
    }
  }

  &__hover-effect {
    background-color: $hover-grey-legacy !important;
  }

  &--selected {
    &--default {
      background-color: $active-grey-new;

      & .content-search-highlight {
        background-color: $yellow;
      }
    }

    &--hovered-from-formula {
      background-color: $yellow-2;
    }

    &--opened {
      background-color: $active-grey-new;
    }

    &--selected-for-copy-formula {
      background-color: $lightskyblue;
    }

    &--formula-paste-success {
      background-color: $lightgreen;
    }

    &--element-map-success {
      background-color: $side-by-side-map-success;
      display: inline-block;
      position: relative;
      padding: 5px;
      border-radius: 5px;
      text-indent: 0%;
    }

    &--formula-paste-failure {
      background-color: $orange;
    }

    &--element-map-failure {
      background-color: $side-by-side-map-failure;
      display: inline-block;
      position: relative;
      padding: 5px;
      border-radius: 5px;
      text-indent: 0%;
    }

    &--post-batch {
      background-color: $green-6;
    }

    &--selected-formula-row {
      background-color: $yellow-3;
    }

    &--panel-selected {
      background-color: $yellow-3;
    }
  }

  &--highlighted {
    background-color: $yellow-3;
    border: solid $yellow-3 0.5px;
    margin: -0.5px 0;
  }

  

  &--selectable-element-cursor {
    &:hover {
      cursor: alias;
    }
  }

  &--verified {
    background-color: $element-status-verified;
    border-bottom: $border-dashed-blue;
  }

  &--reviewed {
    background-color: $element-status-reviewed;
    border-bottom: $border-solid-green;
  }

  &--unverified {
    background-color: $element-status-unverified;
    border-bottom: $border-dotted-yellow;
  }

  &--flag {
    position: absolute;
    margin-top: -4px;
    margin-left: -2px;
  }

  &[disabled] {
    cursor: not-allowed;
  }

  &__annotation-indicator {

    &:hover {
      cursor: pointer;
    }

    &--hide {
      visibility: hidden;
    }

    &--left {
      transform: rotate(-90deg);
    }

    &--right {
      transform: rotate(90deg);
    }

    &--bottom {
      transform: rotate(180deg);
    }
  }

  &__callout-container {
    background-color: $white;
    padding: 0;
    border-radius: 1px;
    border: 1px solid #0097a9;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    min-width: 10px;
    display: flex;
    align-items: center;
    position: absolute;
    width: fit-content;
    justify-content: center;
    z-index: 52;

    &:hover {
      background-color: $white;
      cursor: pointer;
    }

    &--right::before {
      content: '';
      top: 0;
      right: 100%;
      left: 0px;
      width: 5px;
      height: 100%;
      background-color: #0097a9;
      position: absolute;
      transform: translateX(-100%);
      clip-path: polygon(0 50%, 100% 75%, 100% 25%);
    }

    &--right::after {
      content: '';
      top: 1px;
      right: 98%;
      left: 1px;
      width: 4px;
      height: calc(100% - 2px);
      background-color: $white;
      position: absolute;
      transform: translateX(-100%);
      clip-path: polygon(0 50%, 100% 75%, 100% 25%);
    }

    &--left::before {
      content: '';
      top: 0;
      right: 0;
      left: 100%;
      width: 5px;
      height: 100%;
      background-color: #0097a9;
      position: absolute;
      clip-path: polygon(100% 50%, 0 25%, 0 75%);
    }

    &--left::after {
      content: '';
      top: 1px;
      right: -1px;
      left: 98%;
      width: 4px;
      height: calc(100% - 2px);
      background-color: $white;
      position: absolute;
      clip-path: polygon(100% 50%, 0 25%, 0 75%);
    }

    &--top::before {
      content: '';
      top: 100%;
      left: 50%;
      transform: translate(-50%, 0%);
      width: 12px;
      height: 4px;
      background-color: #0097a9;
      position: absolute;
      clip-path: polygon(50% 100%, 75% 0, 25% 0);
    }

    &--top::after {
      content: '';
      top: 97%;
      left: 50%;
      transform: translate(-50%, -4%);
      width: 8px;
      height: 3px;
      background-color: $white;
      position: absolute;
      clip-path: polygon(50% 100%, 75% 0, 25% 0);
    }

    &--bottom::before {
      content: '';
      bottom: 100%;
      left: 50%;
      transform: translate(-50%, 0%);
      width: 12px;
      height: 4px;
      background-color: #0097a9;
      position: absolute;
      clip-path: polygon(50% 0, 75% 100%, 25% 100%);
    }

    &--bottom::after {
      content: '';
      bottom: 95%;
      left: 50%;
      transform: translate(-50%, -4%);
      width: 8px;
      height: 3px;
      background-color: $white;
      position: absolute;
      clip-path: polygon(50% 0, 75% 100%, 25% 100%);
    }
  }

  &__callout-body {
    display: flex;
    flex-wrap: wrap;
    text-align: unset;
    white-space: pre-wrap;
    width: max-content;
    text-indent: initial;
  }

  & .element-annotation {
    font-weight: $font-w-semi-bold;
    font-size: $font-size-sm;
    padding: 0 $gutter-xxxs;
    color: $black;
    // prevent users from selecting (highlighting with cursor) annotations
    user-select: none;
    display: inline-block;
    width: max-content;
    text-align: justify;
    max-width: 120px;

    &-formula {
      color: #046a38;
    }

    &-formula {
      &--flagged {
        color: $orange;
      }
    }

    &-workpaper-reference {
      display: inline-block;
      max-width: $tieout-element-annotation-max-width;
      @include overflow-ellipsis();
    }

    &-comfort-letter-label {
      color: $red;
      text-indent: 0;
    }
  }

  & .element-annotation-workpaper-reference {
    text-indent: 0 !important;
    color: $red;
  }

  & .element-annotation-internal-reference-group {
    color: $blue-11;
    text-indent: initial;
  }

  & .element-annotation-internal-reference-group {
    &--flagged {
      color: $orange;
    }
  }

  & .element-annotation-tickmark {
    color: #62b5e5;
    text-indent: 0;
  }
}

table .tieout-element {
  white-space: nowrap;
}


.statement-content-panel--add-element-cursor {
  & .tieout-element {
    cursor: $add-element-cursor;
  }
}