.text-area-dynamic {
  border-radius: $border-radius-default;
  border: solid 1px $border-grey;
  background-color: $white;
  padding: $gutter-xs $gutter-xxs;
  width: 100%;
  font-size: $font-size-xs;
  box-sizing: border-box;
  resize: none;
  line-height: 14px;
  @include scroll-bar;

  &:focus,
  &:hover {
    border: $hover-focus-border;
  }
}