.formula-row-element-input {
  height: 100%;
  display: flex;
  min-width: $formula-value-display-min-width;

  &__value {
    padding: 8px;
    border: none;
    background: rgba(160, 220, 255, 0.25);
    border-radius: 2px;
    text-align: right;
    color: rgba(0, 0, 0, 0.87);
    width: 100%;
    margin-left: auto;
  }

  &__error {
    background-color: $white;
    border: 2px solid $error-red;
  }
  &__element-value {
    @include hyperlink-button-for-element;
    display: block;
    font-size: $font-size-ml;
    color: $primary-blue;
    font-weight: $font-w-semi-bold;
    text-align: right;
    text-overflow: ellipsis;
    width: 100%;
  }

}
