  .notes-panel-header {
    display: flex;
    flex-direction: column;

    &__header {
      font-size: $font-size-xl;
      font-weight: $font-w-semi-bold;
      margin-bottom: $gutter-sm;
    }

    &__note-summary-count {
      font-size: $font-size-md;
      font-weight: $font-w-semi-bold;
    }

    &__middle-container {
      margin-bottom: -$gutter-xxs;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &__filter-chip-container {
      display: flex;
    }

    &__advanced-filter-button {
      font-size: $font-size-md !important;
      padding: $gutter-xs;

      & svg {
        width: $statement-nav-filter-icon-size;
        height: $statement-nav-filter-icon-size;
      }
    }

    &__filter-chip-container {
      @include scroll-bar;
      overflow-y: auto;
      margin: 0 $gutter-xs;
      max-height: 150px;
      display: flex;
      flex-wrap: wrap;

      @include breakpoint(desktop) {
        margin: $gutter-xxs $gutter-md 0 0;
      }

      & .chip {
        display: inline-flex;
        font-size: $font-size-sm;
        margin-right: $gutter-xxs;
        margin-top: $gutter-xxs;

        @include breakpoint(desktop) {
          font-size: $font-size-md;
        }
      }
    }

    &__no-filter-results-container {
      margin-top: $gutter-xs;
      font-size: $font-size-md;
    }
    &__buttons-container {
      width: 100%;
      font-size: $font-size-md;
      padding: $gutter-md;
      color: $blue-1;
      box-shadow: 0 0 0 0;
      margin-bottom: $gutter-xxs;
      user-select: none;
      display: flex;
      justify-content: space-between;

      & svg {
        margin: $gutter-xxs;
      }
    }
    &__assigned-to-me {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: $gutter-xxs $gutter-xs $gutter-xxs $gutter-sm;
      border-radius: 1rem;
      border: 1px solid $blue-1;
      margin-right: $gutter-xxs;
      margin-top: $gutter-xxs;

      &--active {
        background-color: $blue-9;
        color: $white;
        padding-right: 0;
      }

      &:hover {
        background-color: $blue-9;
        color: $white;
      }
    }
    

    &__assigned-to-me-button {
      cursor: pointer;
      background-color: transparent;
      border: none;
      outline: 0;
      color: inherit;

      &[disabled] {
        cursor: not-allowed;
      }
    }

    &__assigned-to-me-close-button {
      & svg {
        width: 18px;
        height: 18px;
        & path {
          fill: $white;
        }
      }
    }

  }
