.chip {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: $blue-9;
  padding: $gutter-xxs $gutter-xs $gutter-xxs $gutter-sm;
  border-radius: 1rem;
  color: $white;
  font-weight: $font-w-semi-bold;
  font-size: $font-size-md;

  &__content {
    display: block;
  }

  &__remove {
    margin-left: auto;
    & svg {
      width: 18px;
      height: 18px;
      & path {
        fill: $white;
      }
    }
  }
}
