.statement-summary-notes {
  display: flex;
  border: $border-dark-grey;
  border-radius: $border-radius-default;
  align-items: center;
  margin-right: $gutter-xs;
  height: $gutter-ml-1;

  &__button {
    align-items: center;
    background-color: transparent;
    border: none;
    display: flex;
    flex-direction: row;
    padding: 0 $gutter-xs;
    outline: none;
    height: 100%;

    &:hover {
      cursor: pointer;
      background-color: $hover-grey;
    }

    &--disabled {
      cursor: not-allowed;
      background-color: $disabled-button-background-grey;

      svg path {
        stroke: $disabled-text;
      }

      .statement-summary-notes__label {
        background-color: $disabled-button-background-grey;
        color: $disabled-text;
      }

      &:hover {
        cursor: not-allowed;
        background-color: $disabled-button-background-grey;
      }
    }
  }

  &__icon {
    height: $statement-summary-icon-height;
    margin-right: $gutter-xxs;
    width: $statement-summary-icon-width;
  }

  &__label {
    font-size: $font-size-sm;
  }

  &__heading {
    font-size: $font-size-sm;
    background-color: $grey-1;
    padding: 0 $gutter-xs;
    height: 100%;
    display: flex;
    align-items: center;
  }
}
