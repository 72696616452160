.tickmark-panel {
  & .panelContent {
    overflow: auto;
  }

  &__title {
    font-size: $font-size-xl;
    font-weight: $font-w-semi-bold;
  }
  
  & .tickmark-list__entry-actions {
    display: flex;
    flex-direction: row;

    & button:not(:first-child) {
      margin-left: $gutter-xs;
    }
  }
  & .tickmark-list__entry {
    &__element-count-container {
      border: $border-gray-light;
      display: flex;
      align-items: center;
      flex-direction: column;
    }
    &__element-count-title {
      display: flex;
      align-self: flex-start;
    }
    &__element-count-elements {
      width: 100%;
    }
    &__element-count-elements {
      width: 100%;
    }
    &__element-count-elements_expanded {
        height: 50vh;
        width: 100%;
    }
    &__element {
      &--selected {
        background-color: $grey-2;
      }
    }

    &__button-container {
      display: flex;
      align-items: center;
    }

    &__delete-icon {
      @include icon-button-with-one-path;
    }

    &__edit-icon {
      @include icon-button-with-one-path;
      margin-right: $gutter-xs;
    }

    &__caret-icon {
      fill: black;
      margin-left: $gutter-md;

      &--expanded {
        transform: rotate(90deg);
      }
    }

    &__element-count {
      padding: $gutter-xs;
    }

    &__pagination-button-container {
      display: flex;
      padding-bottom: $gutter-sm;
    }

    &__pagination-button {
      width: 95px;
      height: 28px;
      border-radius: $border-radius-default;
      font-weight: $font-w-semi-bold;
      outline: none;
      cursor: pointer;
      border: none;

      &--next {
        background-color: transparent;
        color: $primary-blue;
        border: none;

        &:focus,
        &:hover {
          background-color: $hover-grey;
        }
        &:active {
          background-color: $active-grey;
        }
        &[disabled] {
          background-color: $disabled-button-background-grey;
          color: $disabled-buttton-text-grey;
          cursor: not-allowed;
        }
      }
    }
  }
}
