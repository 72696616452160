.progress-bar-step {
  padding: $gutter-md 0px 0px $default-gutter;
  display: flex;
  flex-direction: column;
  align-items: baseline;
  height: 100%;

  &__node-container {
    display: flex;
    flex-wrap: nowrap;
    padding: $gutter-md 0px $gutter-xl 0px;
    align-items: center;
  }

  &__node-border {
    width: $outer-node-size;
    height: $outer-node-size;
    border: solid 1px $primary-blue;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: $default-gutter;

    &--inactive {
      border: dashed 2px $progress-bar-step-dashed-grey;
    }

    &--valid {
      background-color: $progress-bar-step-background-green;
      border: none;
    }
  }

  &__node-inner {
    width: $inner-node-size;
    height: $inner-node-size;
    border-radius: 50%;
    background-color: $primary-blue;

    &--hide {
      display: none;
    }
  }

  &__pipe {
    background-color: $border-grey;
    height: 100%;
    width: 1px;
    margin-left: calc($outer-node-size / 2);
  }

  &__text {
    font-size: $font-size-ml;

    &--valid {
      color: $progress-bar-step-text-green;
    }
  }
}
