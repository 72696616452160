.statement-elements-search {
  height: 100%;
  overflow-y: hidden;
  display: flex;
  flex-direction: column;

  &__default-message {
    padding: $gutter-md;
  }

  &__default-message-header {
    font-weight: $font-w-semi-bold;
  }

  &__default-message-info {
    margin-top: $gutter-md;
    font-size: $font-size-sm;
  }

  &__default-message-filter-button {
    display: inline;
    padding: $gutter-xxxs;
    font-size: inherit;
  }

  &__no-results {
    font-size: $font-size-sm;
    margin: $gutter-md;
  }

  &--add-element-cursor {
    cursor: $add-element-cursor;
    .element-search-entry,
    .element-search-entry__element-value {
      &:hover {
        cursor: alias;
      }
    }
  }

  &--range-select {
    user-select: none; // prevents text from being highlighted when user drags their mouse
  }

  &__range-select-box {
    border: $dashed-border-black;
    position: absolute;
  }

  &__refresh-button {
    position: absolute;
    bottom: 0;
    right: 0;
    margin-right: $gutter-md;
    border: none;
    background-color: transparent;
    outline: none;
    :hover {
      cursor: pointer;
    }
  }

  &__refresh-button-tooltip {
    width: 325px;
  }

  &__dismiss-button {
    background-color: transparent;
    border: none;
    outline: none;
    color: $blue-1;
    font-size: $font-size-md;
    cursor: pointer;
  }
  &__select-all-section {
    font-family: $font-family-default;
    font-weight: $font-w-regular;
    font-size: $font-size-md;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    padding: $gutter-xs $gutter-md;
  }
  &__select-all-checkbox {
    color: $black-1;
  }
  &__selected-count {
    color: $grey-5;
    white-space: nowrap;
    margin-left: $gutter-xs;
  }
}
