.audit-checklist-panel {
  &__title{
      display: inline-block;
      font-weight: $font-w-semi-bold;
    }

  &__info_icon{
      display:inline;
      margin-inline: 4px auto;
    }

  &__button-container{
      display: flex;
      width: 100%;  
      margin-top: $gutter-md;
     &--finish {
        margin-left: $gutter-md;
    }
    &--edit {
      margin-left: $gutter-md;
  }
    &--progress {
      width: 100%;
      
  }
  }
  

  }


 