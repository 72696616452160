@mixin comfort-assign-element() { 
 .comfort-assign-element{
    border: 1px solid #DA291C;
    flex: none;
    order: 5;
    flex-grow: 0;
    width: fit-content;
    height: fit-content;
    display: inline-block;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px 4px;
    box-sizing: border-box;
    text-indent: 0px;
 }
}