.statement-wrap-up-stats {
  &__col {
    display: flex;
    flex-direction: column;
    margin-right: $gutter-ml;

    &--centered {
      align-items: center;
    }
  }
  &__label {
    display: block;
    font-weight: $font-w-semi-bold;
    font-size: $font-size-sm;
  }
  &__value {
    display: block;
    font-size: $font-size-sm;
  }
  &__value-container {
    display: flex;
    flex-wrap: nowrap;
  }
  &__not-verified {
    color: $error-red;
  }
}
