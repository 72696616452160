.revision-history-modal {
  &__list {
    &--revision-container {
      display: flex;
      flex-wrap: nowrap;
      align-content: center;
    }
    &--revision-icon {
      margin-left: $gutter-xxs;
      border-radius: 50%;
      cursor: pointer;

      &:hover,
      &:focus {
        background-color: $hover-blue;
        border: solid 1px $hover-blue;
        .icon-path {
          fill: $white;
          stroke: $white;
        }
      }
      &:active {
        background-color: $active-blue;
        border: solid 1px $active-blue;
        .icon-path {
          fill: $white;
          stroke: $white;
        }
      }
    }
  }

  &__revisions-list {
    &--link-icon {
      border-radius: 50%;
      cursor: pointer;

      &:hover,
      &:focus {
        background-color: $hover-blue;
        border: solid 1px $hover-blue;
        .icon-path {
          fill: $white;
          stroke: $white;
        }
      }
      &:active {
        background-color: $active-blue;
        border: solid 1px $active-blue;
        .icon-path {
          fill: $white;
          stroke: $white;
        }
      }
    }
    &--revision-container {
      display: flex;
      flex-wrap: nowrap;
      align-content: center;
    }
    &--revision-icon {
      margin-left: $gutter-xxs;
      border-radius: 50%;
      cursor: pointer;

      &:hover,
      &:focus {
        background-color: $blue-4;
        border: solid 1px $blue-4;
        .icon-path {
          fill: $white;
          stroke: $white;
        }
      }
      &:active {
        background-color: $blue-6;
        border: solid 1px $blue-6;
        .icon-path {
          fill: $white;
          stroke: $white;
        }
      }
    }

    .data-grid__cols-header-row {
      top: 0px; // fixes sticky header in modal
    }
  }

  &__revisions-list-item {
    &--deleted {
      color: $disabled-font-grey;
    }
  }

  &__revisions-list {
    &--link-icon {
      border-radius: 50%;
      cursor: pointer;

      &:hover,
      &:focus {
        background-color: $blue-4;
        border: solid 1px $blue-4;
        .icon-path {
          fill: $white;
          stroke: $white;
        }
      }
      &:active {
        background-color: $blue-6;
        border: solid 1px $blue-6;
        .icon-path {
          fill: $white;
          stroke: $white;
        }
      }
    }
  }
}
