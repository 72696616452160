.right-statement-panel {
  width: 50%;
  background-color: #f7f7f7;

  &__split {
    height: 100%;
  }

  &__statement-nav-button {
    @include remove-default-button-styling;
    width: $toolkit-width;
    cursor: pointer;
    display: flex;
    height: $gutter-xl;
    
    justify-content: center;
    align-items: center;
    margin-right: $gutter-xs;

     &:hover,
     &:active,
    &--selected {
      svg path {
        fill: $white;
      }
    }

    &:hover {
      background-color: $hover-blue;
    }

    &:active {
      background: $active-blue;
    }

    &--selected {
      background-color: $primary-blue;
    }
  }
  
  &__selecting-statement-nav-button {
    @include remove-default-button-styling;
    width: $toolkit-width;
    cursor: pointer;
    display: flex;
    height: $gutter-xl;
    
    justify-content: center;
    align-items: center;
    margin-right: $gutter-xs;

     &:hover,
     &:active,
    &--selected {
      svg path {
        fill: $white;
      }
    }

    &:hover {
      background-color: $hover-blue;
    }

    &:active {
      background: $active-blue;
    }

    &--selected {
      background-color: $primary-blue;
    }
  }

  &__statement-header-wrap{
    height: 48px;
    width: 100%;
    background-color: $white;
    box-shadow: 0 0 4px 0 $border-grey;
    border-bottom: $expandable-row-border;
    font-weight: $font-w-semi-bold;
    color: #212121;
    font-size: $font-size-lg;   
    display: flex;
    align-items: center;
  }

  &__statement-header{
    &__statement-nav-button{
      margin-left:calc($gutter-xxl/2);
    }
    &__title{
      @include overflow-ellipsis;
    }
    display: flex;
    width: 91%;
    align-items: center;
    &__swap{
      width: 87%;
    }
  }

  &__container{
    width: 100%;
    padding: 7px 0 0 7px;
     position: relative;
    &__swap {
      background-color:#f7f7f7;
      position: relative;  // position relative is necessary here, since we are using z-index for the div
      z-index: $z-index-overlay + 1; // so that statment nav for right statement does not slides into left statement
    }
  }

  &__data{
    display: flex;
    flex-direction: column;
    
    table {
      table-layout: auto !important;
      width: 100% !important;
    }

    hr{
      margin: revert;
    }
  }

  &__content-section-ocr{
    overflow: hidden !important;
  }
  
  &__content-section {
    background-color: $white;
    width: 100%;
    overflow-x: scroll;
    overflow-y: scroll;
    position: relative;
    @include scroll-bar();
  }

  &__right-page-data{
    display: flex;
    flex-wrap: nowrap;
  }

  &__shrink-rightPage-height{
    height: calc(100vh - 247px);
    min-height: calc(100vh - 247px);
  }

  &__grow-rightPage-height{
    height: calc(100vh - 200px);
    min-height: calc(100vh - 200px);
  }
  &__currentStatement{
    display: flex;
  }

  &__currentLabel{
    font-size: 14px;
    position: relative;
    margin: 0 0 0 8px;
    padding: 2px 6px;
    background-color: #c9eefd;
    color: $primary-blue;
    border-radius: 4px;
  }

  &__nav-icon{
    vertical-align: unset;
    path {
      fill: $primary-blue;
    } 
  }
}
