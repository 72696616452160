.blackline-version-panel {
    width: calc(100% - 25vw);
    display: flex;
    flex-direction: row;
    height: 100%;
        &__previous{
        width: 50%
        }
        &__current{
        width: 50%
        }
    
  }  