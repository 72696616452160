.footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: $footer-grey;
  padding: $footer-padding;
  display: flex;
  font-size: $gutter-xs;
  border-top: 1px solid $footer-border;
  z-index: $z-index-overlay;
  &__left {
    margin-right: $gutter-md;

    & > div > a {
      text-decoration: none;
      @include hyperlink-button;
    }
  }

  &__terms {
    margin-bottom: $gutter-xs;
  }

  &__right {
    width: 20%;
  }
}
