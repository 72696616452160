.notes-panel-edit-input-area {
  &__radio-buttons {
    display: flex;
    margin-bottom: $gutter-md;
  }
  &__button-container {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    margin-top: $gutter-md;
    &--cancel {
      margin-right: $gutter-md;
    }
  }

  & textarea {
    height: $gutter-xxxl + 25;
  }
}
