.admin-module-page {
    background-color: $page-background-grey;
    padding: $gutter-ml;
    padding-top: $gutter-ml;
    width: 100%;
    overflow-y: scroll;
    height: calc(100vh - $disclosure-analytics-header-height);
    @include scroll-bar();

    &__back-button {
        @include back-button();
        padding: 10px;
      }

      &__search {
        .tabs-group__tab--active {
            color: $green-1;
            padding-bottom: 9px;
            border-bottom: 3px solid $green-1;
        }
      }

      &__back-title {
        font-size: $font-size-lg;
        font-weight: $font-w-bold;
        color: $black;
      }

      &__added-users {
        margin-top: $gutter-md;
        .data-grid__scrollable-wrapper {
          overflow: visible;
          overflow-y: visible;
        }
      }

      &__right-admin-container {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: auto;
      }
    
      &__dropdown-labels{
        position: relative;
        margin-top: 2px;
        font-weight: 700;
        margin-right: 5px;
        margin-left: 5px;
      }
    
      &__dropdown{
        &__menu{
          width: auto;
        }
        .dropdown__header{
          padding: 4px 8px;
          border-left: none;
          border-top: none;
          border-bottom: none;
          &:focus,
          &:hover{
            border-right: solid 1px $border-grey;
          }
          .dropdown__title{
            margin-right: $gutter-xxs;
          }
        }
        
      }

}