.data-grid-filter {
    &__filter-icon-container{
        padding: 5px 0 0 5px;
        cursor: pointer;
        position: relative;
    }

    &__filter-icon{
        padding: 5px;
    }

    &__filter-icon-active{
        background-color: $border-grey;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        outline: none;
        svg path {
            fill: $project-card-client-name-grey;
          }
    }

    &__filter-active{
        background-color: $orange;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        position: absolute;
        left: 21px;
        top: 7px;
    }

    &__filter-popup{
        width: 300px;
        position: absolute;
        top: 43px;
        height: 150px;
        z-index: 1;
        border-radius: 5px;
        background: #ffffff;
        box-shadow: $box-shadow-4;
        padding: 10px 20px;
        &.statement-name-filter {
            left: 100px;
        }
    }
    &__header {
        display: flex;
        justify-content: space-between;
        height: 40px;
        align-items: center;
        cursor: pointer;
    }

    &__header-title {
        font-size: 16px;
        font-weight: normal;
    }

    &__search{
        height: 40px;
        .search__close{
            display: none;
        }
    }

    &__filter-btn{
        height: 50px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        .button__primary{
            color: #ffffff;
        }
        .button__tertiary{
            color: #00a3e0;
        }
        
    }

    &__reset-button{
        padding: 8px 12px;
        margin-right: 20px;
        &[disabled] {
            color: $disabled-buttton-text-grey;
            background-color: $white;
          }
    }

    &__apply-button{
        padding: 8px 12px;

        &[disabled] {
            color: $disabled-buttton-text-grey;
            background-color: $disabled-button-background-grey;
          }
    }
}