.statement-content-search {
  &__entry {
    @include remove-default-button-styling;
    width: 100%;
    text-align: left;
    display: block;
    font-size: $font-size-sm;
    margin-bottom: $gutter-sm;
    padding: $gutter-xxs $gutter-md;
    & b {
      // the Backend marks the found string with wrapped <b> tags
      font-weight: $font-w-regular;
      background-color: $yellow-2;
    }

    &:hover {
      background-color: $hover-grey;
      text-decoration: underline;
      cursor: pointer;
    }
    &:focus {
      background-color: $active-grey;
    }
    &:active,
    &--active {
      background-color: $active-grey;
      & b {
        background-color: $yellow;
      }
    }
  }
  &__cta,
  &__no-results {
    margin: $gutter-md;
    font-size: $font-size-sm;
  }
  &__entry-ocr {
    @include remove-default-button-styling;
    width: 100%;
    text-align: left;
    display: block;
    font-size: $font-size-sm;
    margin-bottom: $gutter-sm;
    pointer-events: none;
    background-color: $yellow-4;

    &:hover {
      background-color: $hover-grey-new;
      text-decoration: underline;
      cursor: pointer;
      color: transparent;
    }
    &:focus {
      background-color: $active-grey-new;
    }
    &:active,
    &--active {
      background-color: $yellow-5;
    }
  }
}
