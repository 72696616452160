.report-preview-page {
    width: 100%;
    height: calc(100vh - #{$disclosure-analytics-header-height});
    min-width: auto;
    display: block;
  
    &__header{
      height: $gutter-xl;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: $white;
      font-size: $font-size-sm;
      box-shadow: 0 0 4px 0 $border-grey;
      border-bottom: $expandable-row-border;
    }
  
    &__header-container{
      display: flex;
      height: 100%;
      align-items: center;
    }
  
    &__header-back-button {
      @include back-button();
      display: grid;
      grid-template-columns: $global-header-back-button-spacing 1fr;
      margin-right: -$gutter-sm;
    }
  
    &__header-title {
      font-weight: $font-w-semi-bold;
      font-size: $font-size-default;
      color: #212121;
      max-width: 150px;
      margin-right: $gutter-xs;
      @include overflow-ellipsis;
    }

    &__swap-icon{
      position: absolute;
      left: calc(50% - 30px);
      top: 10px;
      z-index: 56;
      width: $gutter-xxl;
      height: 40px;
      background-color: #ffffff;
      cursor: pointer;
      border: $expandable-row-border;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &__body-section{ 
      display: flex;
      flex-direction: row;
      width: 100%;
      height: calc(100% - 48px);
      position: relative;
      padding: 10px 10px 0 10px;

      &--reverse{
        flex-direction: row-reverse;
      }

      &--left{
        width: 50%
      }
      &--right{
        width: 50% ;
        position : relative;
        z-index: 55;
      }
    }
  }  