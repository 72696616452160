.element-panel-header {
  display: flex;
  flex-direction: column;
  width: 100%;
  &__status {
    display: flex;
    font-size: $font-size-ml;
    font-weight: $font-w-semi-bold;
    padding-bottom: $gutter-sm;
  }
  &__element-display-block {
    display: flex;
  }
  &__information-icon-scaled-amount {
    margin-left: $gutter-xxs;
  }
  &__section-name {
    @include overflow-ellipsis;
    display: block;
    font-size: $font-size-md;
    color: $grey-5;
    padding-bottom: $gutter-xxs;
  }
  &__element-label {
    @include overflow-ellipsis;
    display: block;
    font-size: $font-size-md;
    padding-bottom: $gutter-xxs;
  }

  &__element-label-tooltip {
    width: $side-panel-tooltip-width;
  }

  &__scaled-amount-tooltip {
    &.tooltip {
      &.place-top {
        &::before {
          height: 7px;
        }
      }
    }
  }

  &__element-value {
    @include overflow-ellipsis;
    font-size: $font-size-ml;
    font-weight: $font-w-semi-bold;
    text-align: left;
    padding: 0 0 0 0;
    cursor: pointer;
    color: $primary-blue;
  }

  &__exclude-element-button {
    font-size: $font-size-md;
    margin-left: -$gutter-md;
    margin-bottom: -$gutter-sm;
    @include hyperlink-button;
    color: $primary-blue;
    &:hover {
      background-color: transparent;
    }
    & svg {
      width: $gutter-md;
    }
  }
}
