.manage-country-manager-access-support-id-block{
    &__input-support{
       .form-item:first-child{
        margin-bottom: 20px;
       } 
    }

    &__chip-container{
        .chip{
            display: inline-flex;
            margin: 10px 5px 0 0;
        }
    }
    
}