.project-details {
  background-color: $white;

  &__header {
    padding: $default-gutter;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    border: $data-grid-border;
    border-radius: $border-radius-default $border-radius-default 0 0;
  }
  &__shield{
    margin-right: $gutter-xxs;
  }
  &__rocket{
    margin-right: $gutter-xxs;
  }
  &__project-name {
    font-size: $font-size-xxl;
    padding-left: $gutter-md;
    word-break: break-all; // long single word project names would overflow their container and should wrap
  }

  &__hold {
    color: $orange;
    font-size: $font-size-xxl;
    margin-left: $gutter-xs;
  }

  &__edit-button {
    margin-left: auto;
    border-radius: 50%;
    background-color: $white;
    border: solid 1px $primary-blue;
    cursor: pointer;

    &:focus,
    &:hover {
      background-color: $hover-blue;
      border: solid 1px $hover-blue;
      .edit-icon {
        fill: $white;
      }
    }

    &:active {
      background-color: $active-blue;
      border: solid 1px $active-blue;
      .edit-icon {
        fill: $white;
      }
    }

    &--disabled {
      cursor: not-allowed;
      background-color: transparent;
      border: solid 1px $grey-3;
      .edit-icon {
        fill: $grey-3;
      }

      &:focus,
      &:hover {
        background-color: transparent;
        border: solid 1px $grey-3;
        .edit-icon {
          fill: $grey-3;
        }
      }
    }
  }

  &__info-button {
    padding: $gutter-xxs;
  }

  &__information {
    padding: $default-gutter;
    border: $data-grid-border;
    border-top: none;
    border-radius: 0 0 $border-radius-default $border-radius-default;
    font-size: $font-size-md;
  }

  &__row {
    display: flex;
    flex-wrap: nowrap;
    margin-bottom: $default-gutter;
  }

  &__col {
    width: 20%;
    padding-right: $gutter-md;
  }

  &__label {
    color: $label-grey;
    margin-bottom: $gutter-xs;
  }

  &__value {
    overflow-wrap: break-word;
  }
  &__coi-label{
    width: 11rem;
  }
}
