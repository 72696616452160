.statement-recent-updates {
    &__modal {
      & .modal__header,.modal__body {
        margin-bottom: $gutter-md;
      }
      
    }
    font-size: $font-size-sm;

    &__recent-updates-message li {
        margin-left: 20px;
        margin-bottom: 5px;
    }

    &__video{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        video {
            max-width: 100%;
            max-height: 100%;
        }
    }
}
