// prevents scrolling of all elements behind the overlay
// class is applied on mount of a modal and removed on unmount
body.modal-open {
  overflow: hidden;
}
.modal-backdrop {
  z-index: $z-index-modal;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba($black, 0.75);
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.modal {
  background: $white;
  border: 1px solid $modal-border-grey;
  box-shadow: 0 1px 4px 0px (0, 0, 0, 0.12);
  border-radius: $border-radius-default;
  min-width: $modal-min-width;
  max-width: $modal-max-width;
  margin: $modal-margin;
  padding: $modal-padding;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  &__title {
    font-weight: $font-w-semi-bold;
  }

  &__close {
    @include close-button();
    margin-left: auto;
    margin-bottom: $gutter-md;
    margin-right: -$gutter-md;
    margin-top: -$gutter-md;
  }

  &__header {
    display: flex;
    margin-bottom: $gutter-ml;
  }

  &__header-without-title {
    display: flex;
  }

  &__step-title {
    color: rgba(0, 0, 0, 0.54);
    margin-bottom: $gutter-xs;
    font-size: $font-size-md;
  }

  &__body {
    margin-bottom: $gutter-ml;
  }

  &__text {
    margin-bottom: $gutter-md;
    font-size: $font-size-md;
  }

  &__footer {
    margin: 0 -1 * $gutter-ml;
    padding-top: $gutter-ml;
    border-top: 1px solid $border-grey;
    display: flex;
    justify-content: flex-end;

    .disabled-button-tootlip-wrapper{
      margin-left: $gutter-md;

      &:last-of-type {
        margin-right: $gutter-ml;
      }
    }
  }

}