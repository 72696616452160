.home-page {
  display: flex;
  flex-direction: column;
  height: calc(100vh - #{$disclosure-analytics-header-height});

  &__container {
    width: 100%;
    height: 100%;
  }

  &__project-list {
    position: fixed;
    width: $project-list-width-percentage;
    background-color: $white;
    height: calc(
      100vh - #{$disclosure-analytics-header-height + $footer-height + $footer-padding}
    );
    top: $disclosure-analytics-header-height;
    left: 0;
    overflow: hidden;
    z-index: $z-index-home-page-project-list;
  }

  &__right {
    margin-left: $project-list-width-percentage;
    width: 80%;
    background-color: $page-background-grey;
    min-width: $project-details-min-width;
    padding: $gutter-ml;
    position: relative;
    min-height: 100%;
  }
  &__project-details,
  &__statements {
    width: 100%;
  }

  &__statements {
    margin-top: $gutter-md;
    margin-bottom: $gutter-xxxl + $gutter-xxxl;
  }

  &__warning-banner {
    align-items: center;
    background-color: $warning-banner-background-color;
    border-bottom: $warning-banner-border-color 2px solid;
    display: flex;
    font-size: $font-size-sm;
    height: $warning-banner-height;
    justify-content: center;
    left: 0;
    position: fixed;
    right: 0;
    top: $disclosure-analytics-header-height;
    z-index: $z-index-sticky-table-header;

    &-icon {
      margin-right: $gutter-xs;
    }
  }

  &--show-browser-warning {
    padding-top: $warning-banner-height;

    & .home-page__project-list {
      top: $warning-banner-height;
    }
  }
}
