.element-attributes {
  border: $data-grid-border;
  border-radius: $border-radius-default;

  &__body {
    background: $grey-1;
  }

  &__row {
    display: flex;
    padding: $gutter-xs;
    align-items: center;
    &:last-child {
      width: 100%;
      padding-right: 0;
    }
    .menu {
      overflow: auto;
      min-height: 91px;
    }
    .menu__no-results > div {
      white-space: normal;
      overflow: auto;
    }
  }

  &__col {
    width: 100%;
    margin: 0 $gutter-xxs 0 $gutter-xxs;
  }
}
