.comfort-letter-block {
    padding: $gutter-md;
    position: relative;
  
    &__create-button {
      margin-bottom: $gutter-md;
      font-size: $font-size-md;
      padding-left: 0px;
  
      & svg {
        width: $gutter-md;
        height: $gutter-md;
      }
    }
}