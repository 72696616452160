.amount-attribute-details {
  display: flex;
  color: $grey-4;
  margin-top: $gutter-xxs;

  &__item {
    display: inline-block;
    padding-right: $gutter-xxs;
    margin-right: $gutter-xxs;
    font-size: $font-size-xs;
    line-height: 1;
    flex-shrink: 0;
    &:not(:last-of-type) {
      border-right: 1px solid black;
    }
    &:last-of-type {
      margin-right: 0;
    }
  }
  &__entity {
    flex-shrink: 1;
    @include overflow-ellipsis;
  }   
  &__item-flagged {
    color: $orange;
  }
}
