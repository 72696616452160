.user-grid {
  display: flex;
  flex-wrap: nowrap;
  width: 100%;

  &__label {
    margin-bottom: $gutter-xxs;
    height: 17px;
    font-weight: $font-w-semi-bold;
  }

  &__col {
    width: 100%;
  }

  &__cell {
    width: 80%;
    &--striked{
      text-decoration: line-through;
    }
    
  }
}
