.date-range {
  &__close-button {
    @include close-button();
    vertical-align: top;
  }

  &__calendar {
    vertical-align: top;
  }

  &--invalid {
    .DateRangePickerInput__withBorder {
      border: solid 2px $error-red;
      border-radius: $border-radius-default;
      background-color: $white;
      color: $default-input-text-grey;
      margin: -1px;
      .calendar-icon-svg {
        fill: $error-red;
      }
    }
  }

  &[disabled] {
    .DateRangePickerInput__withBorder {
      border: dashed 1px $disabled-grey;
      color: $disabled-text;
      border-radius: $border-radius-default;
      margin: -1px;
      .calendar-icon-svg {
        fill: $disabled-grey;
      }
    }
  }
}

.DateRangePickerInput__withBorder {
  border-radius: $border-radius-default;
  border: solid 1px $border-grey;
  background-color: $white;
  color: $default-input-text-grey;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;

  &:enabled {
    color: $black;
  }

  &:focus,
  &:hover {
    border: $hover-focus-border;
    margin: -1px;
    .calendar-icon-svg {
      fill: $hover-blue;
    }
  }
}

.DateInput {
  width: 95px;
}

.DateInput_input {
  font-size: $font-size-md;
  padding: $gutter-xs $gutter-xxs;
  border-bottom: none;
}

.DateInput_input__focused {
  border-bottom: none;
}

.DateInput_input__disabled {
  background: $white;
  font-style: normal;
  color: $disabled-text;
}

.DateRangePickerInput_clearDates {
  padding: 0px 4px 0px 4px;
}

.DateRangePickerInput__showClearDates {
  padding-right: 35px;
}

.DateRangePickerInput_arrow_svg {
  margin-right: $gutter-xs;
  vertical-align: middle;
}

.CalendarDay__selected {
  border-radius: 50%;
  background-color: $active-blue;
  border: none;

  &:hover {
    border: solid 1px $hover-blue;
    background-color: $hover-blue;
  }
}

.CalendarDay__selected_span {
  background: $calendar-day-span-blue;
  border: none;
  color: $black;

  &:hover {
    background: $hover-blue;
    border: none;
    color: $white;
  }
}

.CalendarDay__default {
  border: none;
}

.DayPicker__withBorder {
  box-shadow: 0 1px 4px 0 $border-grey;
  border-radius: $border-radius-default;
  border: solid 1px $border-grey;
}
