.data-grid__header {
  height: $gutter-xxl;
  display: flex;
  align-items: center;
  border-radius: $border-radius-default;
  border-left: $data-grid-border;
  border-top: $data-grid-border;
  border-right: $data-grid-border;
  background-color: $white;

  &__title {
    margin-right: auto;
    font-size: $font-size-ml;
    color: $secondary-label-grey;
  }

  &__filter {
    @include clickable-icon-circle();
    margin-right: $default-gutter;
  }

  &__right-button-container {
    margin-left: auto;
    margin-right: $gutter-md;
  }
}
