.text-area {
  border-radius: $border-radius-default;
  border: solid 1px $border-grey;
  background-color: $white;
  padding: $gutter-md;
  color: $default-input-text-grey;
  width: 100%;
  font-size: $font-size-md;

  &:enabled {
    color: $black;
  }

  &__invalid {
    border: $invalid-border;
    border-radius: $border-radius-default;
    background-color: $white;
    @include adjust-padding-for-thicker-border-hover($gutter-md);
    color: $default-input-text-grey;
    width: 100%;
  }

  &:focus,
  &:hover {
    border: $hover-focus-border;
    @include adjust-padding-for-thicker-border-hover($gutter-md);
  }

  &[disabled] {
    border: $disabled-border;
    color: $disabled-text;
    cursor: not-allowed;

    &:hover {
      padding: $gutter-md;
    }
  }
}
