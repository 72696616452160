.formula-panel-header {
  margin-right: auto;
  margin-left: $gutter-ml;
  display: flex;
  &__title {
    font-weight: $font-w-semi-bold;
  }
  &__information {
    margin-left: $gutter-sm;
    display: flex;
    align-items: center;
  }
  &__tooltip {
    width: 450px;
  }
}
