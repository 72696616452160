.global-header {
  width: 100%;
  display: flex;
  background-color: $white;
  font-size: $font-size-sm;
  box-shadow: 0 0 4px 0 $border-grey;
  flex: 0 1 $global-header-height;
  border-bottom: $expandable-row-border;
  min-height: $gutter-xl;
  max-height: $gutter-xl;

  &__container {
    display: flex;
    height: 100%;
    align-items: center;

    &--left {
      flex: 1;
    }

    &--center {
      align-items: center;
      display: flex;
      justify-content: center;
    }

    &--right {
      align-items: center;
      display: flex;
      flex: 1;
      justify-content: flex-end;
    }
  }

  &__banner {
    position: absolute;
    top: $disclosure-analytics-header-height;
    z-index: 100;
    padding: 5px;
    height: $gutter-xl;
    .banner__header {
      align-items: center;
      .banner__close {
        width: 40px;
      }
    }
  }

  &__back-button {
    @include back-button();
    display: grid;
    grid-template-columns: $global-header-back-button-spacing 1fr;
    margin-right: -$gutter-sm;
  }

  &__content-view-mode-button {
    margin-left: auto;
    border-radius: 50%;
    background-color: $white;
    cursor: pointer;
    fill: $primary-blue;

    &:focus,
    &:hover {
      background-color: $hover-blue;
      fill: $white;
    }
    height: 28px;
    width: 28px;
    margin-right: $gutter-xs;
  }

  &__statement-name {
    font-weight: $font-w-regular;
    max-width: 7rem;
    @include overflow-ellipsis;
  }

  &__revision-number {
    font-weight: $font-w-regular;
    margin-left: $gutter-md;
  }
  &__refresh-icon{
    margin-left: $gutter-md;
    &--hide {
      display: none;
    }
  }

  & a {
    text-decoration: none;
  }

  &__tab {
    padding: $gutter-md;
    margin: 0px $gutter-md 0px $gutter-md;
    height: 100%;
    text-decoration: none;
    color: $black;
    font-weight: $font-w-bold;

    &:hover {
      background-color: $hover-grey;
    }

    &:active {
      background-color: $active-grey;
    }

    &--active {
      color: $primary-blue;
      border-bottom: 2px solid $primary-blue;
      font-weight: $font-w-bolder;

      &:hover {
        color: $hover-blue;
        border-bottom: 2px solid $hover-blue;
      }
    }
  }
}

.blackline-control {
  align-items: center;
  display: flex;
  padding: 0 $gutter-xs;

  & span {
    font-weight: bold;
    margin-right: $gutter-xxs;
  }
}
