.report-preview-page-left-panel {
  &__statement-nav-button {
    @include remove-default-button-styling;
    width: $toolkit-width;
    cursor: pointer;
    display: flex;

    justify-content: center;
    align-items: center;
    margin-right: $gutter-xs;
    height: inherit;

    &:hover,
    &:active,
    &--selected {
      svg path {
        fill: $white;
      }
    }

    &:hover {
      background-color: $hover-blue;
    }

    &:active {
      background: $active-blue;
    }

    &--selected {
      background-color: $primary-blue;
    }
  }

  &__statement-header {
    height: 40px;
    border: 1px solid $active-grey;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px 0 0px;

    &__reverse {
      margin: 0px 0px 0px 30px;
    }

    &--title {
      font-weight: 600;
      font-size: 14px;
      width: 300px;
    }

    &--title-reverse {
      padding-left: 30px;
    }
  }

  &__left-preview-html-wrapper {
    position: relative;
    height: calc(100% - 40px);
    left: 0px;
  }

  &__left-preview-html {
    @include scroll-bar;
    height: 100%;
    overflow: auto;
    position: relative;
  }

  &__left-preview-ocr {
    height: calc(100% - 40px);
    position: relative;
  }

  &__statement-header-controls {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 30px;

    &--reverse {
      padding-right: 0;
    }
  }

  &__status-and-flag-toggle,
  &__element-id-toggle {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .form-item__container {
      .form-item__label {
        padding-bottom: 0;
      }
    }
  }

  &__status-and-flag-toggle {
    margin-right: 20px;

    .form-item__container {
      width: 90px;
    }
  }

  .statement-content-panel-ocr {
    .statement-content-panel-ocr__document {
      .statement-content-panel-ocr__page-container {
        padding: 0 10px 10px 10px;

        .statement-content-panel-ocr__page {
          .statement-content-panel-ocr__callout-container {
            border-radius: 3px 2px 2px 3px;

            &--left {
              border: 1px solid $border-grey;
              background-color: $grey-2;
              padding: 0;

              &::before {
                background-color: $border-grey;
                width: 4px;
              }

              &::after {
                background-color: $grey-2;
                width: 3px;
              }
            }
          }
        }
      }
    }
  }
}